import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import NonTermFooter from "../../../../../Component/Amaze/NonTerm/NonTermFooter/NonTermFooter";
import "../../../../../SCSS/Amaze/CommonSCSS/ProposalForms.scss";
import { useAppSelector } from "../../../../../Store/hooks";
import { isEmpty } from "../../../../../SupportingFiles/HelpingFunction";
import { TNonTermProposerData } from "../../../../../types/TNonTermSlice";
import { EVDateInput, EVTextField } from "@evervent_pvt_ltd/ui-kit";

const NonTermProposal = ({
  proposerFields,
  fieldsUpdateState,
  validateForm,
  loader,
}: {
  proposerFields: TNonTermProposerData;
  fieldsUpdateState: Function;
  validateForm: Function;
  loader: boolean;
}) => {
  const { ADD_FORM, QUOTE_LOADER, UPDATE_RESPONSE } = useAppSelector(
    (state) => state.NonTerm
  );
  return (
    <Box>
      <h5 className="sectionTitle">Proposer Details</h5>
      <Grid container spacing={3}>
        <Grid size={12}>
          <h6>Proposer / Insured Details</h6>
        </Grid>
        <Grid size={6}>
          <EVTextField
            className="inputField"
            title={"Full Name"}
            validation_type="NAME"
            value={proposerFields.full_name.value}
            attrName={["full_name"]}
            value_update={fieldsUpdateState}
            warn_status={proposerFields?.full_name?.warning}
            error_message={
              isEmpty(proposerFields.full_name.value)
                ? "Enter Full Name"
                : "Enter Valid Full Name"
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className="my-4">
        <Grid size={4}>
          <EVDateInput
            className="inputField"
            title={"DOB"}
            value={
              UPDATE_RESPONSE?.dob ? UPDATE_RESPONSE?.dob : ADD_FORM.age.value
            }
            attrName={["dob"]}
            disabled={true}
            value_update={fieldsUpdateState}
            error_message="Enter DOB"
            warn_status={ADD_FORM.age.warning}
          />
        </Grid>
        <Grid size={4}>
          <EVTextField
            disabled
            className="inputField"
            title={"Mobile"}
            value={ADD_FORM.mobile.value}
            attrName={["mobile"]}
            value_update={fieldsUpdateState}
            warn_status={ADD_FORM.mobile.warning}
            max_length={10}
            validation_type="NUMBER"
            error_message={!ADD_FORM.mobile.value ? "Enter mobile number" : ""}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid size={6}>
          <EVTextField
            className="inputField"
            title={"Email"}
            value={proposerFields.email.value}
            attrName={["email"]}
            value_update={fieldsUpdateState}
            warn_status={proposerFields?.email?.warning}
            error_message={
              isEmpty(proposerFields.email.value)
                ? "Enter Email"
                : "Enter Valid Email"
            }
          />
        </Grid>
      </Grid>
      <NonTermFooter loader={QUOTE_LOADER} forward={validateForm} />
    </Box>
  );
};

export default NonTermProposal;
