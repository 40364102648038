import {
  EVButton,
  EVRadioText,
  EVSelectDropdown,
  EVTextField,
} from "@evervent_pvt_ltd/ui-kit";
import { Box, Button, Slider } from "@mui/material";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid2";
import { useEffect, useState } from "react";
import "../../../../../SCSS/ModalPopup.scss";
import { COLORS } from "../../../../../SupportingFiles/colors";
import { isEmpty } from "../../../../../SupportingFiles/HelpingFunction";
import GenderRadio from "../../../../../Component/Global/GenderRadio/GenderRadio";
import BorderRadioButton from "../../../../../Component/InputFields/BorderRadioButton/BorderRadioButton";

const InvestmentReturnCalulator = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Function;
}) => {
  const [quoteFields, setQuoteFields] = useState<{
    investmentDuration: { value: any; warn_status: boolean };
    interestRate: { value: any; warn_status: boolean };
  }>({
    investmentDuration: { value: "", warn_status: false },
    interestRate: { value: "", warn_status: false },
  });
  const [calculatedAmount, setCalculatedAmount] = useState<any>(0);
  const [paymentMode, setPaymentMode] = useState("Monthly");
  const handlepayementMode = (attrName: string, value: string) => {
    setCalculatedAmount(0);
    setPaymentMode(value);
  };

  const handleFieldsUpdate = (attrName: string, value: string) => {
    setQuoteFields((prev) => ({ ...prev, [attrName]: { value: value } }));
  };

  const investmentDuration_data: { label: string; value: string }[] = [];
  for (let i = 1; i <= 20; i++) {
    investmentDuration_data.push({
      label: `${i} Year${i > 1 ? "s" : ""}`,
      value: `${i} Year${i > 1 ? "s" : ""}`,
    });
  }
  const [validateInvestmentAmount, setValidateInvestmentAmount] =
    useState(false);
  const [sliderValue, setSliderValue] = useState(0);
  const [inputValue, setInputValue] = useState<any>(0);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === "number") {
      setSliderValue(newValue);
      setInputValue(newValue.toString());
    }
  };
  const handleInputChange = (event: any) => {
    const newValue = event.target.value;
    setInputValue(newValue);

    // Validate input value (optional)
    if (!isNaN(newValue) && newValue !== "") {
      setSliderValue(parseInt(newValue));
    }
  };
  useEffect(() => {
    if (inputValue === 0) {
      setValidateInvestmentAmount(false);
    } else if (inputValue < 500) {
      setValidateInvestmentAmount(true);
    } else if (inputValue > 5000000) {
      setValidateInvestmentAmount(true);
    } else {
      setValidateInvestmentAmount(false);
    }
  }, [inputValue]);

  const calculateInvestmentReturn = () => {
    const { interestRate, investmentDuration } = quoteFields;
    setQuoteFields((prevState) => ({
      ...prevState,
      interestRate: {
        ...prevState.interestRate,
        warn_status: isEmpty(interestRate.value),
      },
      investmentDuration: {
        ...prevState.investmentDuration,
        warn_status: isEmpty(investmentDuration.value),
      },
    }));
    if (inputValue === 0) {
      setValidateInvestmentAmount(true);
    } else if (
      !validateInvestmentAmount &&
      !interestRate.warn_status &&
      !investmentDuration.warn_status
    ) {
      const interest = interestRate.value / 100;
      let base = 0;
      let term = 0;
      let interestRateValue = 0;
      let durationOfInvestment = 0;

      if (investmentDuration.value !== undefined) {
        durationOfInvestment = parseInt(
          investmentDuration.value.toString().replace(" Years", "")
        );
      }

      let si = 0;

      if (paymentMode === "Yearly") {
        term = durationOfInvestment * 1;
        base = 1 + interest;
        interestRateValue = interest;
      } else {
        term = durationOfInvestment * 12;
        base = 1 + interest / 12;
        interestRateValue = interest / 12;
      }

      si =
        inputValue *
        ((Math.pow(base, term) - 1) / interestRateValue) *
        (1 + interestRateValue);

      // Update the calculated amount state
      setCalculatedAmount(si.toFixed(2));
    }
  };

  const resetErrorMessage = () => {
    setValidateInvestmentAmount(false);
    setSliderValue(0);
    setInputValue(0);
    setCalculatedAmount(0);
    setQuoteFields((prevState) => ({
      ...prevState,
      interestRate: {
        value: "",
        warn_status: false,
      },
      investmentDuration: {
        value: "",
        warn_status: false,
      },
    }));
  };

  return (
    <Modal open={open}>
      <Box className="modalWrapper">
        <Box
          sx={{
            transform: "translate(0,0)",
            transition: "trapnsform 0.3s",
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box className="modalContent xsWidth">
            <Grid className="topbar" container alignItems="center">
              <Grid size={12} textAlign={"right"}>
                <Button
                  disableRipple
                  className="closePopup"
                  onClick={() => {
                    setOpen(false);
                    resetErrorMessage();
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              columnSpacing={2}
              rowSpacing={3}
              alignContent="flex-start"
            >
              <Grid size={12}>
                <h3>Investment Return Calculator</h3>
              </Grid>
              <Grid size={12}>
                <Box
                  sx={{
                    backgroundColor: COLORS.lightbg,
                    padding: "12px 16px",
                    borderRadius: "16px",
                    marginTop: "12px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: "24px",
                      alignItems: "center",
                      marginBottom: "16px",
                      position: "relative",
                    }}
                  >
                    <p
                      style={{
                        color: COLORS.lightgrey,
                        width: "100%",
                        minWidth: "130px",
                      }}
                    >
                      Want to invest (₹)
                    </p>{" "}
                    <input
                      placeholder="0"
                      value={inputValue}
                      style={{
                        borderRadius: "8px",
                        border: "1px solid",
                        borderColor: COLORS.lightgrey,
                        backgroundColor: "transparent",
                        height: "32px",
                        padding: "4px 8px",
                        textAlign: "right",
                        fontSize: "16px",
                        fontWeight: "500",
                        width: "100%",
                      }}
                      onChange={handleInputChange}
                      maxLength={7}
                    />
                    {validateInvestmentAmount ? (
                      <span
                        style={{
                          color: "#EA4559",
                          fontSize: "11px",
                          position: "absolute",
                          right: "0px",
                          bottom: "-16px",
                        }}
                      >
                        Enter ₹ 500 - ₹ 50 lakh
                      </span>
                    ) : null}
                  </Box>

                  <Slider
                    aria-label="Default"
                    valueLabelDisplay="auto"
                    value={sliderValue}
                    onChange={handleSliderChange}
                    min={0}
                    step={1}
                    max={5000000}
                  />
                </Box>
              </Grid>
              <Grid size={12} className="radioBtns">
                <h5 style={{ marginBottom: "8px" }}>Payment Mode</h5>

                <BorderRadioButton
                  count="AUTO"
                  attrName="fuel_type"
                  onChange={handlepayementMode}
                  value={paymentMode}
                  options={[
                    {
                      label: "Monthly",
                      value: "Monthly",
                    },
                    {
                      label: "Yearly",
                      value: "Yearly",
                    },
                  ]}
                />
              </Grid>
              <Grid size={12}>
                <EVSelectDropdown
                  id="investmentDuration"
                  title="Duration of Investment"
                  value={quoteFields.investmentDuration.value}
                  value_update={handleFieldsUpdate}
                  attrName={["investmentDuration"]}
                  data={investmentDuration_data}
                  warn_status={quoteFields.investmentDuration.warn_status}
                  error_message="Select Investment Duration"
                />
              </Grid>
              <Grid size={12}>
                <EVTextField
                  id="interestRate"
                  title="Applied interest rate (%)"
                  value={quoteFields.interestRate.value}
                  value_update={handleFieldsUpdate}
                  attrName={["interestRate"]}
                  warn_status={quoteFields.interestRate.warn_status}
                  error_message="Enter interest Rate"
                  validation_type="NUMBER"
                  max_length={3}
                />
              </Grid>
              <Grid size={12} textAlign={"center"}>
                {calculatedAmount === 0 || isNaN(calculatedAmount) ? null : (
                  <p
                    style={{
                      border: `1px solid ${COLORS.primary}`,
                      fontWeight: 700,
                      padding: "12px 24px",
                      borderRadius: "100px",
                      display: "inline-block",
                    }}
                  >
                    You will get ₹ {calculatedAmount}
                  </p>
                )}
              </Grid>
              <Grid size={12} textAlign={"center"}>
                <EVButton
                  id="Calculate"
                  size="large"
                  className="primaryBtns mb-4"
                  text="Calculate"
                  loader={false}
                  onClick={() => calculateInvestmentReturn()}
                  fullWidth={true}
                />

                <p
                  style={{
                    textAlign: "center",
                    fontSize: "12px",
                    lineHeight: "14px",
                  }}
                >
                  The calculator is designed solely for illustrative or
                  indicative purposes. It does not take into account any taxes,
                  fees, expenses, or other charges that may apply.
                </p>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default InvestmentReturnCalulator;
