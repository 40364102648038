import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useAppSelector } from "../../../../Store/hooks";
import {
  formatAmountToCurrency,
  formatNumberToLakhOrCrores,
  isEmpty,
} from "../../../../SupportingFiles/HelpingFunction";
import "./NonTermLeftSidebar.scss";
const NonTermLeftSidebar = () => {
  const {
    SELECTED_QUOTE_DATA,
    QUOTE_FIELDS_DATA,
    RIDERS_BENEFITS,
    QUOTE_LOADER,
    ADD_FORM,
    ADD_FORM_RESPONSE,
  } = useAppSelector((state) => state.NonTerm);
  console.log("QUOTE_LOADER", QUOTE_LOADER);
  console.log("RIDERS_BENEFITS", RIDERS_BENEFITS);

  return (
    <Grid size="grow" className="proposalLeftSidebar">
      <Box className={"termIcon"}></Box>
      <img
        className="insurerLogo"
        src={SELECTED_QUOTE_DATA.companyDetails.logo}
      />
      <h6 className="mb-6">
        {SELECTED_QUOTE_DATA.productDetails.product_name}
      </h6>
      {SELECTED_QUOTE_DATA?.companyDetails?.company_code === "L004" ? (
        <h5>
          {formatAmountToCurrency(
            RIDERS_BENEFITS?.riders_benefits_response?.InvestmentAmount
          )}
        </h5>
      ) : (
        <h5>
          {SELECTED_QUOTE_DATA?.companyDetails.company_code === "L007" ? (
            isEmpty(
              RIDERS_BENEFITS?.riders_benefits_response?.premiumWithGST
            ) || QUOTE_LOADER ? (
              <img src="/images/loader.svg" height="10px" alt="Brand Logo" />
            ) : (
              <h5 className="">
                {formatAmountToCurrency(
                  RIDERS_BENEFITS?.riders_benefits_response?.premiumWithGST
                )}
              </h5>
            )
          ) : ADD_FORM.investmentMode.value == 1 &&
            Number(ADD_FORM.investAmount.value) >= 100000 &&
            Number(ADD_FORM.investAmount.value) <= 3000000 &&
            SELECTED_QUOTE_DATA.productDetails?.id !== 2839 &&
            SELECTED_QUOTE_DATA.productDetails?.id !== 2836 ? (
            <>
              {" "}
              {isEmpty(
                RIDERS_BENEFITS?.riders_benefits_response?.premiumWithGST
              ) || QUOTE_LOADER ? (
                <img src="/images/loader.svg" height="10px" alt="Brand Logo" />
              ) : (
                <h5 className="">
                  {formatAmountToCurrency(
                    RIDERS_BENEFITS?.riders_benefits_response?.premiumWithGST
                  )}
                </h5>
              )}{" "}
            </>
          ) : isEmpty(
              RIDERS_BENEFITS?.riders_benefits_response?.premiumWithGST
            ) || QUOTE_LOADER ? (
            <img src="/images/loader.svg" height="10px" alt="Brand Logo" />
          ) : (
            <h5 className="">
              {formatAmountToCurrency(
                RIDERS_BENEFITS?.riders_benefits_response?.premiumWithGST
              )}
            </h5>
          )}
        </h5>
      )}
      <p className="mb-6">{"Investment Amount (inclusive gst)"}</p>

      <h5>{SELECTED_QUOTE_DATA?.premiumDetails?.term} Years</h5>
      <p className="mb-6">Policy Term</p>
      <h5>{SELECTED_QUOTE_DATA?.premiumDetails.pay_term} Years</h5>
      <p className="mb-6">Premium Paying Term</p>
      <h5>
        {SELECTED_QUOTE_DATA?.companyDetails.company_code === "L007" ? (
          isEmpty(RIDERS_BENEFITS?.riders_benefits_response?.risk_cover_cal) ||
          QUOTE_LOADER ? (
            <img src="/images/loader.svg" height="10px" alt="Brand Logo" />
          ) : (
            <h5 className="">
              {formatNumberToLakhOrCrores(
                Number(
                  RIDERS_BENEFITS?.riders_benefits_response?.risk_cover_cal
                )
              )}{" "}
            </h5>
          )
        ) : ADD_FORM.investmentMode.value == 1 &&
          Number(ADD_FORM.investAmount.value) >= 100000 &&
          Number(ADD_FORM.investAmount.value) <= 3000000 &&
          SELECTED_QUOTE_DATA.productDetails?.id !== 2839 &&
          SELECTED_QUOTE_DATA.productDetails?.id !== 2836 ? (
          <>
            {isEmpty(
              RIDERS_BENEFITS?.riders_benefits_response?.risk_cover_cal
            ) || QUOTE_LOADER ? (
              <img src="/images/loader.svg" height="10px" alt="Brand Logo" />
            ) : (
              <h5 className="">
                {formatNumberToLakhOrCrores(
                  Number(
                    RIDERS_BENEFITS?.riders_benefits_response?.risk_cover_cal
                  )
                )}{" "}
              </h5>
            )}
          </>
        ) : isEmpty(
            RIDERS_BENEFITS?.riders_benefits_response?.risk_cover_cal
          ) || QUOTE_LOADER ? (
          <img src="/images/loader.svg" height="10px" alt="Brand Logo" />
        ) : (
          <h5 className="">
            {formatNumberToLakhOrCrores(
              Number(RIDERS_BENEFITS?.riders_benefits_response?.risk_cover_cal)
            )}{" "}
          </h5>
        )}
      </h5>
      <p className="mb-6">Risk Cover</p>
      <h5>
        {SELECTED_QUOTE_DATA?.companyDetails.company_code === "L007" ? (
          isEmpty(
            RIDERS_BENEFITS?.riders_benefits_response?.maturity_benefit
          ) || QUOTE_LOADER ? (
            <img src="/images/loader.svg" height="10px" alt="Brand Logo" />
          ) : (
            <h5 className="">
              {formatNumberToLakhOrCrores(
                RIDERS_BENEFITS?.riders_benefits_response?.maturity_benefit
              )}{" "}
            </h5>
          )
        ) : ADD_FORM.investmentMode.value == 1 &&
          Number(ADD_FORM.investAmount.value) >= 100000 &&
          Number(ADD_FORM.investAmount.value) <= 3000000 &&
          SELECTED_QUOTE_DATA.productDetails?.id !== 2839 &&
          SELECTED_QUOTE_DATA.productDetails?.id !== 2836 ? (
          <>
            {" "}
            {isEmpty(
              RIDERS_BENEFITS?.riders_benefits_response?.maturity_benefit
            ) || QUOTE_LOADER ? (
              <img src="/images/loader.svg" height="10px" alt="Brand Logo" />
            ) : (
              <h5 className="">
                {formatNumberToLakhOrCrores(
                  RIDERS_BENEFITS?.riders_benefits_response?.maturity_benefit
                )}{" "}
              </h5>
            )}
          </>
        ) : isEmpty(
            RIDERS_BENEFITS?.riders_benefits_response?.maturity_benefit
          ) || QUOTE_LOADER ? (
          <img src="/images/loader.svg" height="10px" alt="Brand Logo" />
        ) : (
          <h5 className="">
            {formatNumberToLakhOrCrores(
              RIDERS_BENEFITS?.riders_benefits_response?.maturity_benefit
            )}{" "}
          </h5>
        )}
      </h5>
      <p className="mb-6">Maturity Benifits</p>
      <h5>
        {ADD_FORM.investmentMode.value == 12
          ? "Monthly"
          : ADD_FORM.investmentMode.value == 2
          ? "Half-Yearly"
          : ADD_FORM.investmentMode.value == 4
          ? "Quarterly"
          : ADD_FORM.investmentMode.value == 1
          ? "Yearly"
          : "Single Pay"}
      </h5>
      <p className="mb-6">Payment Mode</p>
    </Grid>
  );
};

export default NonTermLeftSidebar;
