import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2"; // Grid version 2
import { useState } from "react";
import "react-multi-carousel/lib/styles.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import JDSButtons from "../../../Component/InputFields/JDSButtons/JDSButtons";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import { COLORS } from "../../../SupportingFiles/colors";
import HelmetComponent from "../../../SupportingFiles/HelmetComponent";

function MCareer() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const careerSlider = [
    { imgUrl: "./images/culture-1.jpg" },
    { imgUrl: "./images/culture-2.jpg" },
    { imgUrl: "./images/culture-3.jpg" },
    { imgUrl: "./images/culture-4.jpg" },
  ];
  const [trendingProductssettings] = useState({
    className: "center",
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    arrows: false,
    dots: false,
  });
  return (
    <Box paddingTop={"64px"} className="mhomeWrapper">
      <HelmetComponent
        title="Jio Insurance Broking Ltd"
        description="Get your car, bike, health, or Life insurance online. Comprehensive coverage across India. Book now and get the right quotes! JioInsure - Jio General Insurance"
      />
      <MNavBar />
      {/* Hero Banner */}
      <Box className="heroBanner">
        <img
          src="../images/career-banner.webp"
          width="100%"
          className="bgimg"
        />
        <Box className="innerWrapper">
          <Grid container spacing={2} alignItems="center">
            <Grid
              size={{ xs: 12, md: 7 }}
              display="flex"
              gap="16px"
              flexDirection="column"
              sx={{ color: COLORS.white }}
              className="contentBox"
            >
              <h2>Give wings to your career at Jio Insurance Broking</h2>
              <h6>
                Mail your resume to apply for one of the best work
                opportunities.
              </h6>
              <JDSButtons
                text={"Apply now"}
                variant={"text"}
                className={"primaryBtns"}
                fullWidth={false}
                onClick={() => {
                  window.location.href = "mailto:human.resource@jioinsure.in";
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Hero Banner End */}
      <Box padding="8px 12px 24px">
        <Grid container spacing={3}>
          <Grid size={12}>
            <h2>Life at Jio</h2>
            <p style={{ color: COLORS.lightgrey, marginTop: "16px" }}>
              Jio Insure aims to create a sustainable, nimble, and
              forward-looking 'talent-intensive' organisation fostering long
              term value creation for all stakeholders and an 'enriching place
              to work' globally.
            </p>
          </Grid>
        </Grid>
      </Box>
      <MFooter />
    </Box>
  );
}

export default MCareer;
