import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Modal from "@mui/material/Modal";
import { useSearchParams } from "react-router-dom";
import JDSButtons from "../../../../Component/InputFields/JDSButtons/JDSButtons";
import "../../../../SCSS/ProductForms.scss";
import { useAppSelector } from "../../../../Store/hooks";
import { formatLicensePlate } from "../../../../SupportingFiles/HelpingFunction";
import { COLORS } from "../../../../SupportingFiles/colors";
import {
  pushMotorDataLayer,
  sendEventToNativeApp,
} from "../../../../utils/analytics";

function CarPrefilledData({
  open,
  setOpen,
  continuePrefilledCar,
  denyPrefilledCar,
}: {
  open: boolean;
  setOpen: Function;
  continuePrefilledCar: Function;
  denyPrefilledCar: Function;
}) {
  const { ADD_FORM, REDIRECTED } = useAppSelector((state) => state.Car);
  const [searchParams, setSearchParams] = useSearchParams();
  const utm_source = searchParams.get("utm_source");

  return (
    <Modal open={open}>
      <Box className="modalWrapper">
        <Box className="modalContent xsWidth">
          <Grid className="topbar" container alignItems="center">
            <Grid size={12} textAlign={"right"}>
              <Button
                disableRipple
                className="closePopup"
                onClick={() => {
                  pushMotorDataLayer(
                    "jioinsure_flow",
                    "car details",
                    "skip",
                    "car",
                    utm_source === "myjio"
                      ? "MyJio app"
                      : REDIRECTED
                      ? "JFS app"
                      : "Insure website"
                  );
                  const eventProps = {
                    action: "car details",
                    click: "skip",
                    type: "car",
                    EntrySource: REDIRECTED ? "JFS app" : "Insure website",
                  };
                  sendEventToNativeApp("jioinsure_flow", eventProps);
                  setOpen(false);
                }}
              />
            </Grid>
          </Grid>

          <Grid container rowSpacing={3}>
            <Grid size={12}>
              <h2 style={{ fontSize: "27px" }}>We have found your car</h2>
            </Grid>
            <Grid size={8} alignSelf="center">
              <h5 style={{ fontSize: "21px" }}>Your car details</h5>
            </Grid>
            <Grid size={4} textAlign="right">
              <JDSButtons
                className="secondaryBtns small"
                text="Edit"
                variant="outlined"
                fullWidth={false}
                // loader="true"
                onClick={() => {
                  pushMotorDataLayer(
                    "jioinsure_flow",
                    "car details",
                    "edit",
                    "car",
                    utm_source === "myjio"
                      ? "MyJio app"
                      : REDIRECTED
                      ? "JFS app"
                      : "Insure website"
                  );
                  const eventProps = {
                    action: "car details",
                    click: "edit",
                    type: "car",
                    EntrySource: REDIRECTED ? "JFS app" : "Insure website",
                  };
                  sendEventToNativeApp("jioinsure_flow", eventProps);
                  denyPrefilledCar();
                }}
              />
            </Grid>
            <Grid size={12}>
              <Box
                sx={{
                  borderRadius: "16px",
                  backgroundColor: COLORS.lightMariGold20,
                  padding: "16px",
                  display: "flex",
                  gap: "16px",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <p style={{ textTransform: "uppercase", fontWeight: "bold" }}>
                    {formatLicensePlate(ADD_FORM.reg_no.value)}
                  </p>
                  <p>{`${ADD_FORM.make.value} ${ADD_FORM.model.value}, ${ADD_FORM.fuel_type.value}, ${ADD_FORM.variant_cc.value}`}</p>
                  <p>{`Reg. Date: ${ADD_FORM.reg_date.value}`}</p>
                </Box>
              </Box>
            </Grid>
            <Grid size={12}>
              <JDSButtons
                className="primaryBtns large"
                text="Continue"
                variant="contained"
                onClick={() => {
                  pushMotorDataLayer(
                    "jioinsure_flow",
                    "car details",
                    "proceed",
                    "car",
                    utm_source === "myjio"
                      ? "MyJio app"
                      : REDIRECTED
                      ? "JFS app"
                      : "Insure website"
                  );
                  const eventProps = {
                    action: "car details",
                    click: "proceed",
                    type: "car",
                    EntrySource: REDIRECTED ? "JFS app" : "Insure website",
                  };
                  sendEventToNativeApp("jioinsure_flow", eventProps);
                  continuePrefilledCar();
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
}

export default CarPrefilledData;
