import * as React from "react";
import { Box, Link } from "@mui/material";
// import Grid from '@mui/material/Grid2';
import Grid from "@mui/material/Grid2";
import "./NonTermNavbar.scss";
import { useNavigate } from "react-router";

const NonTermNavbar: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Box className="proposalNavbar">
      <Grid container columnSpacing={3} width="100%">
        <Grid size={12}>
          <Link
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/");
            }}
          >
            <img
              src="/images/jio_insurance_logo.svg"
              height="16px"
              alt="Brand Logo"
            />
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NonTermNavbar;
