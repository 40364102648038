import React, { ReactNode, useEffect, useState } from "react";
import { BrowserRouter, useLocation } from "react-router-dom";
import CommonRoutes from "./Router/Routes/CommonRoutes/CommonRoutes";
import NonTermRoutes from "./Router/Routes/NonTerm/NonTermRoutes";
import { logPageView } from "./utils/analytics";
import { Box } from "@mui/material";
import JDSButtons from "./Component/InputFields/JDSButtons/JDSButtons";
import ScheduleCallPopup from "./Component/ScheduleCallPopup/ScheduleCallPopup";

function App() {
  // useEffect(() => {
  //   const handleBeforeInstallPrompt = (event: Event) => {
  //     // Prevent the default install prompt
  //     event.preventDefault();
  //     console.log("Install prompt disabled");
  //   };

  //   // Listen for the beforeinstallprompt event
  //   window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

  //   // Cleanup the event listener
  //   return () => {
  //     window.removeEventListener(
  //       "beforeinstallprompt",
  //       handleBeforeInstallPrompt
  //     );
  //   };
  // }, []);

  const [openSchedulePopup, setOpenSchedulePopup] = useState(false);
  return (
    <BrowserRouter>
      <GAListener>
        <CommonRoutes />
        <NonTermRoutes />
        {/* <Box
          sx={{
            position: "fixed",
            bottom: "24px",
            right: "24px",
            zIndex: "46",
          }}
        >
          <JDSButtons
            text={
              <>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mr-1"
                >
                  <path
                    d="M20 10H19V9C19 7.14348 18.2625 5.36301 16.9497 4.05025C15.637 2.7375 13.8565 2 12 2C10.1435 2 8.36301 2.7375 7.05025 4.05025C5.7375 5.36301 5 7.14348 5 9V10H4C3.46957 10 2.96086 10.2107 2.58579 10.5858C2.21071 10.9609 2 11.4696 2 12V16C2 16.5304 2.21071 17.0391 2.58579 17.4142C2.96086 17.7893 3.46957 18 4 18H5C5.53043 18 6.03914 17.7893 6.41421 17.4142C6.78929 17.0391 7 16.5304 7 16V9C7 7.67392 7.52678 6.40215 8.46447 5.46447C9.40215 4.52678 10.6739 4 12 4C13.3261 4 14.5979 4.52678 15.5355 5.46447C16.4732 6.40215 17 7.67392 17 9V19C17 19.2652 16.8946 19.5196 16.7071 19.7071C16.5196 19.8946 16.2652 20 16 20H13C12.7348 20 12.4804 20.1054 12.2929 20.2929C12.1054 20.4804 12 20.7348 12 21C12 21.2652 12.1054 21.5196 12.2929 21.7071C12.4804 21.8946 12.7348 22 13 22H16C16.7956 22 17.5587 21.6839 18.1213 21.1213C18.6839 20.5587 19 19.7956 19 19V18H20C20.5304 18 21.0391 17.7893 21.4142 17.4142C21.7893 17.0391 22 16.5304 22 16V12C22 11.4696 21.7893 10.9609 21.4142 10.5858C21.0391 10.2107 20.5304 10 20 10Z"
                    fill=""
                  />
                </svg>
                Speak to an advisor
              </>
            }
            variant={"text"}
            className={"secondaryBtns schedulPopup-btn"}
            onClick={() => setOpenSchedulePopup(true)}
            fullWidth={true}
          />
          <ScheduleCallPopup
            open={openSchedulePopup}
            setOpen={setOpenSchedulePopup}
          />
        </Box> */}
      </GAListener>
    </BrowserRouter>
  );
}

interface GAListenerProps {
  children: ReactNode;
}

const GAListener: React.FC<GAListenerProps> = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    logPageView();
  }, [location]);

  return <>{children}</>;
};

export default App;
