import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import NonTermRidersPage from "../../../../Page/Desktop/NonTerm/NonTermProposal/NonTermRidersPage";
import MNonTermRidersBenefits from "../../../../Page/Mobile/NonTerm/MNonTermProposal/MNonTermRidersBenefits";
import { NON_TERM_ROUTES } from "../../../../Router/Path/NonTermRoute";
import { NON_TERM_SERVICES } from "../../../../Services/NonTerm/NonTermFormService";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { NonTermSlice } from "../../../../Store/Slice_Reducer/NonTerm/NonTermSlice";
import { formatNumberToLakhOrCrores } from "../../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { TDropdown } from "../../../../types/Common/TDropdown";
import { TRidersBenefitsResponse } from "../../../../types/NonTerm/TNonTermRidersBenifits";

const NonTermRidersContainer = () => {
  const dispatch = useAppDispatch();
  const {
    RIDERS_BENEFITS,
    SELECTED_QUOTE_DATA,
    PROPOSER_DATA,
    ADD_FORM,
    ADD_FORM_RESPONSE,
  } = useAppSelector((state) => state.NonTerm);
  const { NonTerm } = useAppSelector((state) => state);
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [tf002Data, setTf002Data] = useState<TDropdown[]>([]);
  const [tf003Data, setTf003Data] = useState<TDropdown[]>([]);
  const [tf004Data, setTf004Data] = useState<TDropdown[]>([]);
  const [loaderTf002, setLoaderTf002] = useState<boolean>(false);
  const [loaderTf003, setLoaderTf003] = useState<boolean>(false);
  const [loaderTf004, setLoaderTf004] = useState<boolean>(false);

  useEffect(() => {
    const start_tf002 = 1000000;
    const end_tf002 = 30000000;

    let data_tf002: TDropdown[] = [];
    for (let i = start_tf002; i <= end_tf002; i += 1000000) {
      data_tf002.push({
        value: `${i}`,
        label: formatNumberToLakhOrCrores(i),
      });
    }

    setTf002Data(data_tf002);

    const start_tf003 = 1000000;
    const end_tf003 = 30000000;

    let data_tf003: TDropdown[] = [];
    for (let i = start_tf003; i <= end_tf003; i += 1000000) {
      data_tf003.push({
        value: `${i}`,
        label: `${formatNumberToLakhOrCrores(i)}`,
      });
    }

    setTf003Data(data_tf003);

    const start_tf004 = 1000000;
    const end_tf004 = 30000000;

    let data_tf004: TDropdown[] = [];
    for (let i = start_tf004; i <= end_tf004; i += 100000) {
      data_tf004.push({
        value: `${i}`,
        label: formatNumberToLakhOrCrores(i),
      });
    }

    setTf004Data(data_tf004);
  }, [RIDERS_BENEFITS]);

  useEffect(() => {
    setLoaderTf002(true);
    setLoaderTf003(true);
    setLoaderTf004(true);
    GET_RIDERS_PREMIUM();
  }, [
    RIDERS_BENEFITS.local_value.tf002Status,
    RIDERS_BENEFITS.local_value.tf002Value,
    RIDERS_BENEFITS.local_value.tf003Status,
    RIDERS_BENEFITS.local_value.tf003Value,
    RIDERS_BENEFITS.local_value.tf004Status,
    RIDERS_BENEFITS.local_value.tf004Value,
  ]);

  const GET_RIDERS_PREMIUM = () => {
    // debugger;
    dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));

    // const onSuccess = (data: any) => {
    //   console.log("data", data);

    //   setLoaderTf004(false);
    //   setLoaderTf002(false);
    //   setLoaderTf003(false);
    //   const results = data.response;
    //   const error = data.error;
    //   const message = data.message;
    //   const response: TRidersBenefitsResponse = results;
    //   dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
    //   if (!error) {
    //     dispatch(NonTermSlice.actions.SET_RIDERS_BENEFITS_DATA(response));
    //   } else {
    //     toast.error("There are some technical error. Please contact to admin.");
    //   }
    // };

    const onSuccess = (data: any) => {
      console.log("data", data);

      dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      setLoaderTf002(false);
      setLoaderTf003(false);
      setLoaderTf004(false);
      const error = data.error;
      const message = data.message;
      const response: TRidersBenefitsResponse = data.response;
      if (!error) {
        dispatch(NonTermSlice.actions.SET_RIDERS_BENEFITS_DATA(response));
      } else {
        dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
        toast.error("There are some technical error. Please contact to admin.");
        console.log({ error: error, message: message });
      }
    };

    const onError = (err: any) => {
      setLoaderTf004(false);
      setLoaderTf002(false);
      setLoaderTf003(false);
      toast.error("There are some technical error. Please contact to admin.");
      dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
    };

    const data: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_ids: [SELECTED_QUOTE_DATA.productDetails.id],
      accidentalDeathSelected: RIDERS_BENEFITS.local_value.tf003Status
        ? "Yes"
        : "No",
      accidentalDisSelected: RIDERS_BENEFITS.local_value.tf002Status
        ? "Yes"
        : "No",
      wop: RIDERS_BENEFITS.local_value.tf004Status ? "Yes" : "No",
      // product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
      // company_code: SELECTED_QUOTE_DATA.companyDetails.company_code,
      // term: `${SELECTED_QUOTE_DATA.premiumDetails.term}`,
      // pay_term: `${SELECTED_QUOTE_DATA.premiumDetails.pay_term}`,
      // frequency: `${ADD_FORM.investmentMode.value}`,
      // gender: ADD_FORM.gender.value,
      // dob: ADD_FORM.age.value,
      // pincode: ADD_FORM.pincode.value,
      // sum_assured: ADD_FORM.investAmount.value,
      // mobile: ADD_FORM.mobile.value,
      // income_period: QUOTE_FIELDS_DATA.incomePeriod,
    };

    NON_TERM_SERVICES.NON_TERM_UPDATE_RIDERS_STATUS(onSuccess, onError, data);
  };

  const UPDATE_PROPOSER_DETAILS = () => {
    let params = {
      quote_no: btoa(ADD_FORM_RESPONSE.quote_no),
      section: "add_ons_details",
      details: {
        proposer_name: PROPOSER_DATA?.full_name?.value,
        proposer_mobile: ADD_FORM.mobile.value,
        proposer_email: PROPOSER_DATA?.email?.value,
        proposer_gender: ADD_FORM?.gender?.value,
        proposer_dob: PROPOSER_DATA?.dob?.value,
        address_pincode: ADD_FORM?.pincode?.value,
        premium: ADD_FORM?.investAmount?.value,
        insured_name_annual_income: "",
        insured_dob: "",
        insured_email: "",
        insured_emergency_no: "",
        insured_gender: "",
        insured_mobile: "",
        insured_name: "",
        insured_occupation: "",
        insured_qualification: "",
        insured_pincode: "",
      },
      buy_online_code: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
    };

    const onSuccess = (res: any) => {
      const error = res?.error;
      if (!error) {
        navigate(NON_TERM_ROUTES.BASIC_DETAILS);
      } else {
        toast.error("Something went wrong");
      }
    };
    const onError = () => {};
    NON_TERM_SERVICES.UPDATE_PROPOSER_DETAILS(onSuccess, onError, params);
  };

  return (
    <>
      {isMobile ? (
        <MNonTermRidersBenefits
          forward={UPDATE_PROPOSER_DETAILS}
          tf002Data={tf002Data}
          tf003Data={tf003Data}
          tf004Data={tf004Data}
          loaderTf002={loaderTf002}
          loaderTf003={loaderTf003}
          loaderTf004={loaderTf004} 
        />
      ) : (
        <NonTermRidersPage
          forward={UPDATE_PROPOSER_DETAILS}
          tf002Data={tf002Data}
          tf003Data={tf003Data}
          tf004Data={tf004Data}
          loaderTf002={loaderTf002}
          loaderTf003={loaderTf003}
          loaderTf004={loaderTf004}
        />
      )}
    </>
  );
};

export default NonTermRidersContainer;
