import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NonTermQuote from "../../../Page/Desktop/NonTerm/NonTermQuote/NonTermQuote";
import MNonTermQuote from "../../../Page/Mobile/NonTerm/MNonTermQuote/MNonTermQuote";
import { NON_TERM_ROUTES } from "../../../Router/Path/NonTermRoute";
import { NON_TERM_SERVICES } from "../../../Services/NonTerm/NonTermFormService";
import { NonTermSlice } from "../../../Store/Slice_Reducer/NonTerm/NonTermSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import {
  calculateAge,
  isEmpty,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { TNonTermProduct } from "../../../types/NonTerm/TNonTermCardProduct";
import { TNonTermSingleQuoteDetail } from "../../../types/NonTerm/TNonTermSingleQuoteDetail";
import { toast } from "react-toastify";
import { TRidersBenefitsResponse } from "../../../types/NonTerm/TNonTermRidersBenifits";
import { ENonTermCompanyCode } from "../../../Enum/ENonTermComapnyCode";
import { TDropdown } from "../../../types/Common/TDropdown";

const NonTermQuoteContainer = () => {
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const { ADD_FORM, ADD_FORM_RESPONSE, QUOTE_FIELDS_DATA, QUOTE_LOADER } =
    useAppSelector((state) => state.NonTerm);
  const navigate = useNavigate();
  const [quoteLoader, setQuoteLoader] = useState(false);
  const { NonTerm } = useAppSelector((state) => state);
  const [payUptoOption, setPayUptoOption] = useState([]);
  const [validateInvestmentAmount, setValidateInvestmentAmount] =
    useState(false);

  const [minInvestmentAmount, setMinInvestmentAmount] = useState(500);
  const [maxInvestmentAmount, setMaxInvestmentAmount] = useState(500000);
  let all_quotes_data: TNonTermProduct[] = [];
  const [quotationResponse, setQuotationResponse] = useState<TNonTermProduct[]>(
    []
  );

  const validateApplyInvestmentAmount = () => {
    let params = {
      quote_no: `${ADD_FORM_RESPONSE?.quote_no}`,
      policy_term: `${QUOTE_FIELDS_DATA?.policyTerm}`,
      policy_paying_term: `${QUOTE_FIELDS_DATA?.payUpto}`,
      payment_mode: `${ADD_FORM.investmentMode.value}`,
      sum_assured: Number(ADD_FORM.investAmount.value),
      dob: `${ADD_FORM?.age.value}`,
      gender: `${ADD_FORM?.gender.value}`,
      pincode: `${ADD_FORM_RESPONSE?.pincode}`,
    };
    Update_details(params);
  };

  const applyInvestmentAmount = () => {
    if (
      ADD_FORM.investAmount.value === 0 ||
      ADD_FORM.investAmount.value < minInvestmentAmount ||
      ADD_FORM.investAmount.value > maxInvestmentAmount
    ) {
      setValidateInvestmentAmount(true);
    } else {
      setValidateInvestmentAmount(false);
      validateApplyInvestmentAmount();
    }
  };

  useEffect(() => {
    if (ADD_FORM.investmentMode.value == 12) {
      setMinInvestmentAmount(500);
      setMaxInvestmentAmount(500000);
    } else if (ADD_FORM.investmentMode.value == 4) {
      setMinInvestmentAmount(1500);
      setMaxInvestmentAmount(1500000);
    } else if (ADD_FORM.investmentMode.value == 2) {
      setMinInvestmentAmount(2500);
      setMaxInvestmentAmount(2500000);
    } else {
      setMinInvestmentAmount(5000);
      setMaxInvestmentAmount(5000000);
    }
  }, [ADD_FORM.investmentMode.value]);

  const GET_PRODUCT_LIST = (param?: any) => {
    let params = {
      product_type_id: "28",
      age: isEmpty(param?.dob) ? ADD_FORM.age.value : param?.dob,
      sum_assured:
        param?.sum_assured === undefined
          ? Number(ADD_FORM.investAmount.value)
          : Number(param?.sum_assured),
      annual_income: Number(ADD_FORM.annualIncome.value),
      frequency:
        param?.payment_mode === undefined
          ? ADD_FORM.investmentMode.value
          : param.payment_mode,
      pincode: ADD_FORM.pincode.value,
      city: "changdigarh",
    };

    const onSuccess = (res: any) => {
      let responseArr: any[] = res.response || [];
      let local_data: any[] = [];
      try {
        for (const data of responseArr) {
          const productDetails = data.productDetails || {};
          const companyDetails = data.companyDetails || {};
          const { product_code, id } = productDetails;
          const { logo } = companyDetails;

          if (!isEmpty(id) && !isEmpty(product_code) && !isEmpty(logo)) {
            local_data.push({
              company_code: "",
              logo,
              id,
              product_code,
              buy_online_code: "",
              loader: true,
            });
          } else {
            console.log("error get product list", id, product_code, logo);
          }
          all_quotes_data = local_data;
        }

        for (const data of all_quotes_data) {
          GET_SINGLE_QUOTE(data.id);
        }
      } catch (e) {
        console.log("Error occurred while processing data: ", e);
      }
    };

    const onError = (err: any) => {
      console.log("err", err);
      dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
    };

    // if (
    //   !pendingRequests.has(`post:${TERM_URLS.TERM_PRODUCT_LARAVEL}`) &&
    //   !pendingRequests.has(`post:${TERM_URLS.TERM_QUOTES_LARAVEL}`)
    // ) {
    dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
    NON_TERM_SERVICES.GET_PRODUCT_LIST(onSuccess, onError, params);
    // }
  };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "incomePeriod") {
      dispatch(
        NonTermSlice.actions.UPDATE_QUOTE_FIELD_DATA1({ attrName, value })
      );

      let params = {
        quote_no: `${ADD_FORM_RESPONSE?.quote_no}`,
        policy_term: `${QUOTE_FIELDS_DATA?.policyTerm}`,
        policy_paying_term: `${QUOTE_FIELDS_DATA?.payUpto}`,
        payment_mode: `${ADD_FORM.investmentMode.value}`,
        sum_assured: `${ADD_FORM.investAmount.value}`,
        dob: `${ADD_FORM?.age.value}`,
        gender: `${ADD_FORM?.gender.value}`,
        pincode: `${ADD_FORM_RESPONSE?.pincode}`,
        income_period: value,
      };
      Update_details(params);
    } else if (attrName === "investmentMode") {
      dispatch(
        NonTermSlice.actions.NON_TERM_UPDATE_ADD_FORM_DATA({
          ...ADD_FORM,
          [attrName]: { value: value, warning: false },
        })
      );
      dispatch(
        NonTermSlice.actions.UPDATE_QUOTE_FIELD_DATA1({ attrName, value })
      );
      let minAmount = 0;
      let maxAmount = 0;
      let hasError = false;
      if (value == 12) {
        minAmount = 500;
        maxAmount = 500000;
      } else if (value == 4) {
        minAmount = 1500;
        maxAmount = 1500000;
      } else if (value == 2) {
        minAmount = 2500;
        maxAmount = 2500000;
      } else {
        minAmount = 5000;
        maxAmount = 5000000;
      }

      if (ADD_FORM.investAmount.value === 0) {
        hasError = true;
      } else if (ADD_FORM.investAmount.value < minAmount) {
        hasError = true;
      } else if (ADD_FORM.investAmount.value > maxAmount) {
        hasError = true;
      } else {
        hasError = false;
      }

      let params = {
        quote_no: `${ADD_FORM_RESPONSE?.quote_no}`,
        policy_term: `${QUOTE_FIELDS_DATA?.policyTerm}`,
        policy_paying_term:
          value === "5" ? "1" : `${QUOTE_FIELDS_DATA?.payUpto}`,
        payment_mode: value,
        sum_assured: `${ADD_FORM.investAmount.value}`,
        dob: `${ADD_FORM?.age.value}`,
        gender: `${ADD_FORM?.gender.value}`,
        pincode: `${ADD_FORM_RESPONSE?.pincode}`,
      };

      setMinInvestmentAmount(minAmount);
      setMaxInvestmentAmount(maxAmount);
      setValidateInvestmentAmount(hasError);

      if (!hasError) {
        Update_details(params);
      }
    } else if (attrName === "payUpto") {
      dispatch(
        NonTermSlice.actions.UPDATE_QUOTE_FIELD_DATA1({ attrName, value })
      );
      let params = {
        quote_no: `${ADD_FORM_RESPONSE?.quote_no}`,
        policy_term: `${QUOTE_FIELDS_DATA?.policyTerm}`,
        policy_paying_term: value,
        payment_mode: `${ADD_FORM.investmentMode.value}`,
        sum_assured: `${ADD_FORM.investAmount.value}`,
        dob: `${ADD_FORM?.age.value}`,
        gender: `${ADD_FORM?.gender.value}`,
        pincode: `${ADD_FORM_RESPONSE?.pincode}`,
      };
      Update_details(params);
    } else if (attrName === "policyTerm") {
      dispatch(
        NonTermSlice.actions.UPDATE_QUOTE_FIELD_DATA1({ attrName, value })
      );
      if (value < 10) {
        dispatch(
          NonTermSlice.actions.UPDATE_QUOTE_FIELD_DATA1({
            attrName: "payUpto",
            value: "1",
          })
        );

        setPayUptoOption(generatePayUptoData(1, value));

        let params = {
          quote_no: `${ADD_FORM_RESPONSE?.quote_no}`,
          policy_term: value,
          policy_paying_term: "1",
          payment_mode: `${ADD_FORM.investmentMode.value}`,
          sum_assured: `${ADD_FORM.investAmount.value}`,
          dob: `${ADD_FORM?.age.value}`,
          gender: `${ADD_FORM?.gender.value}`,
          pincode: `${ADD_FORM_RESPONSE?.pincode}`,
        };
        Update_details(params);
      } else if (value < QUOTE_FIELDS_DATA.payUpto) {
        dispatch(
          NonTermSlice.actions.UPDATE_QUOTE_FIELD_DATA1({
            attrName: "payUpto",
            value: "10",
          })
        );

        setPayUptoOption(generatePayUptoData(10, value));

        let params = {
          quote_no: `${ADD_FORM_RESPONSE?.quote_no}`,
          policy_term: value,
          policy_paying_term: "10",
          payment_mode: `${ADD_FORM.investmentMode.value}`,
          sum_assured: `${ADD_FORM.investAmount.value}`,
          dob: `${ADD_FORM?.age.value}`,
          gender: `${ADD_FORM?.gender.value}`,
          pincode: `${ADD_FORM_RESPONSE?.pincode}`,
        };
        Update_details(params);
      } else {
        setPayUptoOption(
          generatePayUptoData(Number(QUOTE_FIELDS_DATA.payUpto), value)
        );
        let params = {
          quote_no: `${ADD_FORM_RESPONSE?.quote_no}`,
          policy_term: value,
          policy_paying_term: `${QUOTE_FIELDS_DATA?.payUpto}`,
          payment_mode: `${ADD_FORM.investmentMode.value}`,
          sum_assured: `${ADD_FORM.investAmount.value}`,
          dob: `${ADD_FORM?.age.value}`,
          gender: `${ADD_FORM?.gender.value}`,
          pincode: `${ADD_FORM_RESPONSE?.pincode}`,
        };
        Update_details(params);
      }
    } else if (attrName === "investAmount") {
      dispatch(
        NonTermSlice.actions.NON_TERM_UPDATE_ADD_FORM_DATA({
          ...ADD_FORM,
          [attrName]: { value: value, warning: false },
        })
      );
    }
  };

  const Update_details = (params: any) => {
    const onSuccess = (res: any) => {
      if (!res?.error) {
        GET_PRODUCT_LIST();
        // setloader(true);
      } else {
        dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    NON_TERM_SERVICES.updateNonTermDetails(onSuccess, onError, params);
  };

  useEffect(() => {
    if (!isEmpty(ADD_FORM_RESPONSE.quote_no)) {
      GET_PRODUCT_LIST();
    }
  }, [ADD_FORM_RESPONSE]);

  const generatePayUptoData = (selectedPayUpto: number, policyTerm: number) => {
    const payUptoData: any = [];
    for (let i = 1; i <= policyTerm; i++) {
      payUptoData.push({
        label: `${i} Year${i > 1 ? "s" : ""}`,
        value: `${i}`,
      });
    }
    return payUptoData;
  };

  useEffect(() => {
    const age = calculateAge(NonTerm?.ADD_FORM?.age?.value);

    const POLICY_TERM: TDropdown[] = [];
    for (let i = 1; i <= 85 - Number(age); i++) {
      const option = {
        label: `${i} Years`,
        value: `${i}`,
      };
      POLICY_TERM.push(option);
    }

    dispatch(
      NonTermSlice.actions.BULK_UPDATE_DATA({
        ...NonTerm,
        DROPDOWN_DATA: { ...NonTerm.DROPDOWN_DATA, POLICY_TERM: POLICY_TERM },
      })
    );
  }, [NonTerm?.ADD_FORM?.age]);

  useEffect(() => {
    if (QUOTE_FIELDS_DATA.payUpto !== undefined) {
      const payUptoData: any = [];
      for (let i = 1; i <= Number(QUOTE_FIELDS_DATA.policyTerm); i++) {
        payUptoData.push({
          label: `${i} Year${i > 1 ? "s" : ""}`,
          value: `${i}`,
        });
      }
      setPayUptoOption(payUptoData);
    }
    if (QUOTE_FIELDS_DATA.policyTerm < QUOTE_FIELDS_DATA.payUpto) {
      dispatch(
        NonTermSlice.actions.UPDATE_QUOTE_FIELD_DATA1({
          attrName: "payUpto",
          value: "10",
        })
      );
    }
  }, [QUOTE_FIELDS_DATA.policyTerm]);

  const BUY_ACTION = (quote_data: any) => {
    let params = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      insurance_type: ADD_FORM_RESPONSE.insurance_type,
      product_id: quote_data?.productDetails?.id,
      product_code: quote_data?.productDetails?.product_code,
      company_code: quote_data?.companyDetails?.company_code,
      sum_assured: ADD_FORM.investAmount.value,
      premium: quote_data.premiumDetails?.premium,
    };
    const onSuccess = () => {
      dispatch(
        NonTermSlice.actions.BULK_UPDATE_DATA({
          ...NonTerm,
          SELECTED_QUOTE_DATA: quote_data,
        })
      );
      const companyCode = quote_data.companyDetails.company_code;
      const productID = quote_data?.productDetails?.id;
      if (companyCode === "L031") {
        window.location.href = `${quote_data?.premiumDetails?.redirectionUrl}`;
      } else if (
        ADD_FORM.investmentMode.value === "1" &&
        (productID === 2837 ||
          productID === 2838 ||
          productID === 2840 ||
          productID === 2841 ||
          productID === 2842) &&
        Number(ADD_FORM.investAmount.value) >= 100000 &&
        Number(ADD_FORM.investAmount.value) <= 3000000
      ) {
        navigate(NON_TERM_ROUTES.RIDERS_BENEFITS);
      } else if (
        productID === 922 ||
        productID === 923 ||
        productID === 924 ||
        productID === 925
      ) {
        navigate(NON_TERM_ROUTES.BASIC_DETAILS);
      }
      //  else if (productID === 2836 || productID === 2839) {
      //   console.log("hello prabh");
      //   navigate(NON_TERM_ROUTES.BASIC_DETAILS);
      // }
      else if (productID === ENonTermCompanyCode.TATA) {
        navigate(NON_TERM_ROUTES.BASIC_DETAILS);
      } else {
        GET_RIDERS_PREMIUM(productID);
      }
    };
    const onError = () => {};
    NON_TERM_SERVICES.buyAction(onSuccess, onError, params);
  };

  const GET_RIDERS_PREMIUM = (productID: number) => {
    const onSuccess = (data: any) => {
      dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      const error = data.error;
      const message = data.message;
      const response: TRidersBenefitsResponse = data.response;
      if (!error) {
        dispatch(NonTermSlice.actions.SET_RIDERS_BENEFITS_DATA(response));
        navigate(NON_TERM_ROUTES.BASIC_DETAILS);
      } else {
        toast.error("There are some technical error. Please contact to admin.");
        console.log({ error: error, message: message });
      }
    };

    const onError = (err: any) => {
      toast.error("There are some technical error. Please contact to admin.");
    };

    const data: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_ids: [productID],
      accidentalDeathSelected: "No",
      accidentalDisSelected: "No",
      wop: "No",
    };

    NON_TERM_SERVICES.NON_TERM_UPDATE_RIDERS_STATUS(onSuccess, onError, data);
  };

  const sort_quotation = (data: any[]) => {
    const quote_data: any[] = [...data];
    setQuotationResponse(quote_data);
  };

  let isProcessing = false;

  const setQuoteDataInArray = async (
    product_id: number,
    quoteResponses: TNonTermSingleQuoteDetail
  ) => {
    console.log("Called for", product_id, quoteResponses);
    if (isProcessing) {
      console.log(
        `Skipped updating for product_id ${product_id} as it's already processing.`
      );
      return;
    }

    isProcessing = true;
    let quotesData = [...all_quotes_data];

    console.log("Initial quotesData:", quotesData);

    try {
      const updatedQuotesData = quotesData.reduce<
        (typeof all_quotes_data)[number][]
      >((acc, quote) => {
        console.log("Processing quote:", quote);

        if (quote.id === product_id) {
          if (
            quoteResponses &&
            !isEmpty(quoteResponses?.premiumDetails?.premium)
          ) {
            // Check if the quote is already in the accumulator
            if (!acc.some((q) => q.id === quoteResponses.productDetails.id)) {
              acc.push({
                buy_online_code: quoteResponses.productDetails.buy_online_code,
                company_code: quoteResponses.companyDetails.company_code,
                loader: false,
                id: quoteResponses.productDetails.id,
                logo: quoteResponses.companyDetails.logo,
                product_code: quoteResponses.productDetails.product_code,
                quotationDetail: quoteResponses,
              });
              console.log(`Added new quote for product_id ${product_id}`);
            } else {
              console.log(
                `Quote for product_id ${product_id} already exists, skipping.`
              );
            }
          } else {
            console.log(
              `Removing quote for product_id ${product_id} due to empty premium.`
            );
          }
        } else {
          acc.push(quote);
        }
        return acc;
      }, []);

      if (updatedQuotesData.length === 0) {
        console.warn(
          `No valid quotes found after processing product_id ${product_id}.`
        );
      }

      quotesData = updatedQuotesData;
    } catch (e) {
      console.log("setQuoteDataInArray error:", e);
    } finally {
      isProcessing = false; // Reset the lock
    }

    console.log("Updated quotesData:", quotesData);

    const ALL_LOADERS_ARE_FALSE = quotesData.every(
      (item) => item.loader === false
    );

    if (ALL_LOADERS_ARE_FALSE) {
      setQuotationResponse(quotesData);
      dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
    }

    all_quotes_data = quotesData;
  };

  const GET_SINGLE_QUOTE = async (product_id: number) => {
    const onSuccess = async (res: any) => {
      const response: TNonTermSingleQuoteDetail[] = res.quoteLists;
      const error = response[0]?.premiumDetails?.error;
      const message = `${response[0]?.premiumDetails?.message}`;

      if (response) {
        await setQuoteDataInArray(product_id, response[0]); // Await the asynchronous function
      }
    };

    const onError = (err: any) => {
      console.error("Error fetching quote:", err); // Add error handling
    };

    let param = {
      product_ids: [product_id],
      quote_no: ADD_FORM_RESPONSE.quote_no,
    };
    dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));

    // Call the service to get the quote
    NON_TERM_SERVICES.GET_SINGLE_NON_TERM_QUOTE(onSuccess, onError, param);
  };

  useEffect(() => {
    dispatch(
      NonTermSlice.actions.BULK_UPDATE_DATA({
        ...NonTerm,

        SELECTED_QUOTE_DATA: {
          ...NonTermSlice.getInitialState().SELECTED_QUOTE_DATA,
        },
      })
    );
  }, []);

  return (
    <>
      {QUOTE_LOADER && <div className="quoteoverlay" />}
      {isMobile ? (
        <MNonTermQuote
          updateMasterState={updateMasterState}
          // quoteListData={quoteListData}
          quoteListData={quotationResponse}
          applyInvestmentAmount={applyInvestmentAmount}
          BUY_ACTION={BUY_ACTION}
          payUptoOption={payUptoOption}
          minInvestmentAmount={minInvestmentAmount}
          maxInvestmentAmount={maxInvestmentAmount}
          validateInvestmentAmount={validateInvestmentAmount}
          setQuoteLoader={setQuoteLoader}
          GET_PRODUCT_LIST={GET_PRODUCT_LIST}
        />
      ) : (
        <NonTermQuote
          updateMasterState={updateMasterState}
          // quoteListData={quoteListData}
          quoteListData={quotationResponse}
          applyInvestmentAmount={applyInvestmentAmount}
          BUY_ACTION={BUY_ACTION}
          payUptoOption={payUptoOption}
          minInvestmentAmount={minInvestmentAmount}
          maxInvestmentAmount={maxInvestmentAmount}
          validateInvestmentAmount={validateInvestmentAmount}
          setQuoteLoader={setQuoteLoader}
          GET_PRODUCT_LIST={GET_PRODUCT_LIST}
        />
      )}
    </>
  );
};

export default NonTermQuoteContainer;
