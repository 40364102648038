import {
  EVButton,
  EVSelectDropdown,
  EVTextField,
} from "@evervent_pvt_ltd/ui-kit";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useEffect, useState } from "react";
import Loader from "../../../../Component/Global/Loader/Loader";
import MQuoteNavbar from "../../../../Component/Mobile/Common/MQuoteNavbar/MQuoteNavbar";
import MNonTermQuoteCard from "../../../../Component/Mobile/NonTerm/MNonTermQuoteCard/MNonTermQuoteCard";
import { useAppSelector } from "../../../../Store/hooks";
import { COLORS } from "../../../../SupportingFiles/colors";
import {
  calculateAge,
  formatNumberToLakhOrCrores,
  formatToCurrency,
} from "../../../../SupportingFiles/HelpingFunction";
import "./../../../../SCSS/MQuotePage.scss";
import MInvestmentReturnCalculator from "./MInvestmentReturnCalculator/MInvestmentReturnCalculator";
import MNonTermModifyDetails from "./MNonTermModifyDetails/MNonTermModifyDetails";

function MNonTermQuote({
  updateMasterState,
  quoteListData,
  applyInvestmentAmount,
  BUY_ACTION,
  payUptoOption,
  minInvestmentAmount,
  maxInvestmentAmount,
  validateInvestmentAmount,
  setQuoteLoader,
  GET_PRODUCT_LIST,
}: {
  quoteListData: any;
  updateMasterState: Function;
  applyInvestmentAmount: Function;
  BUY_ACTION: Function;
  payUptoOption: any;
  minInvestmentAmount: number;
  maxInvestmentAmount: number;
  validateInvestmentAmount: boolean;
  setQuoteLoader: Function;
  GET_PRODUCT_LIST: Function;
}) {
  const {
    ADD_FORM,
    ADD_FORM_RESPONSE,
    DROPDOWN_DATA,
    QUOTE_LOADER,
    QUOTE_FIELDS_DATA,
    UPDATE_RESPONSE,
  } = useAppSelector((state) => state.NonTerm);
  // Modify member Detail Popup
  const [isModifyDetailModalOpen, setModifyDetailModalOpen] = useState(false);
  const [isInvestReturnCalcOpen, setIsInvestReturnCalcOpen] = useState(false);
  const [openRidersPopup, setOpenRidersPopup] = useState(false);
  const [quoteListRiders, setQuoteListRiders] = useState<string[]>([]);

  useEffect(() => {
    if (QUOTE_LOADER) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [QUOTE_LOADER]);

  console.log(quoteListData, "quoteListData69");

  return (
    <Box className="quoteWrapperMobile">
      <MNonTermModifyDetails
        open={isModifyDetailModalOpen}
        setOpen={setModifyDetailModalOpen}
        setQuoteLoader={setQuoteLoader}
      />
      <MInvestmentReturnCalculator
        open={isInvestReturnCalcOpen}
        setOpen={setIsInvestReturnCalcOpen}
      />
      {/* <MNonTermRiders
        open={openRidersPopup}
        setOpen={setOpenRidersPopup}
        quoteListData={quoteListData}
        setQuoteListRiders={setQuoteListRiders}
      /> */}

      <MQuoteNavbar />
      {QUOTE_LOADER ? (
        <Box
          sx={{
            backgroundColor: "rgba(20,20,20, 0.9)",
            height: "100%",
            width: "100%",
            position: "fixed",
            top: "0px",
            zIndex: "991",
            flexDirection: "column",
            gap: "16px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader size="large" />
            <h5
              style={{
                color: COLORS.white,
                textAlign: "center",
                marginTop: "4px",
              }}
            >
              Fetching best <br /> Insurance Policies for you...
            </h5>
          </Box>
        </Box>
      ) : null}

      <Box padding={"16px"}>
        <Grid container columnSpacing={2} alignItems={"center"}>
          <Grid size={7}>
            <h6 style={{ lineHeight: "24px", fontWeight: 700 }}>
              Member covered
            </h6>
            <p
              style={{
                color: "#595959",
                fontSize: "14px",
              }}
            >
              {UPDATE_RESPONSE.annual_income === ""
                ? formatToCurrency(ADD_FORM.annualIncome.value)
                : formatToCurrency(UPDATE_RESPONSE.annual_income)}
              ,{" "}
              {UPDATE_RESPONSE?.gender === ""
                ? ADD_FORM.gender.value === "M"
                  ? "Male"
                  : "Female"
                : UPDATE_RESPONSE?.gender === "M"
                ? "Male"
                : "Female"}
              ,{" "}
              {UPDATE_RESPONSE.age === 0
                ? `${calculateAge(ADD_FORM.age.value)} yrs`
                : `${UPDATE_RESPONSE.age} yrs`}
            </p>
          </Grid>
          <Grid size={5} textAlign={"right"}>
            <EVButton
              id="edit"
              size="medium"
              className="tertiaryBtn"
              text="Edit Details"
              fullWidth={false}
              onClick={() => setModifyDetailModalOpen(true)}
            />
          </Grid>
          <Grid size={12}>
            <hr
              style={{
                border: "none",
                borderTop: "1px solid rgba(0, 0, 0, 0.1)",
                marginTop: "12px",
                marginBottom: "24px",
              }}
            />
          </Grid>
          <Grid size={7}>
            <Box style={{ position: "relative" }}>
              <EVTextField
                id="want_to_invest"
                title="Want to invest"
                value={ADD_FORM.investAmount.value}
                value_update={updateMasterState}
                attrName={"investAmount"}
                warn_status={false}
                error_message="Enter amount to invest"
                max_length={7}
              />

              <Box
                sx={{
                  position: "absolute",
                  top: "0px",
                  right: "0px",
                }}
              >
                <EVButton
                  id="apply"
                  size="small"
                  className="secondaryBtn"
                  text="Apply"
                  fullWidth={false}
                  onClick={() => applyInvestmentAmount()}
                />
              </Box>
              {validateInvestmentAmount ? (
                <span
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    color: "#EA4559",
                    fontSize: "11px",
                    marginTop: "3px",
                  }}
                >
                  Enter {minInvestmentAmount} -{" "}
                  {formatNumberToLakhOrCrores(maxInvestmentAmount)}
                </span>
              ) : null}
            </Box>
          </Grid>
          <Grid size={5}>
            <EVSelectDropdown
              id="investment_mode"
              title="Investment Mode"
              value={ADD_FORM.investmentMode.value}
              value_update={updateMasterState}
              attrName={"investmentMode"}
              data={DROPDOWN_DATA.investmentMode}
              warn_status={false}
              error_message="Select Investment Mode"
            />
          </Grid>
        </Grid>
      </Box>
      <Box padding={"16px"} sx={{ background: "rgba(254, 247, 233, 1)" }}>
        <Grid
          container
          className="row"
          columnSpacing={2}
          rowSpacing={3}
          alignItems={"center"}
        >
          {ADD_FORM.investmentMode.value === "5" ? null : (
            <Grid size={6}>
              <EVSelectDropdown
                id="pay_upto"
                title="Pay upto"
                value={QUOTE_FIELDS_DATA.payUpto}
                value_update={updateMasterState}
                attrName={"payUpto"}
                data={payUptoOption}
                warn_status={false}
                error_message="Select Pay Upto duration"
              />
            </Grid>
          )}
          <Grid size={6}>
            <EVSelectDropdown
              id="policy_term"
              title="Policy Term"
              value={QUOTE_FIELDS_DATA.policyTerm}
              value_update={updateMasterState}
              attrName={"policyTerm"}
              data={DROPDOWN_DATA.POLICY_TERM}
              warn_status={false}
              error_message="Select Policy Term"
            />
          </Grid>
          <Grid size={6}>
            <EVSelectDropdown
              id="income_period"
              title="Income Period"
              value={QUOTE_FIELDS_DATA.incomePeriod}
              value_update={updateMasterState}
              attrName={"incomePeriod"}
              data={DROPDOWN_DATA.INCOME_PERIOD}
              warn_status={false}
              error_message="Select Income Period"
            />
          </Grid>
          <Grid size={ADD_FORM.investmentMode.value === "5" ? 12 : 6}>
            <EVSelectDropdown
              id="retun_type"
              title="Return Type"
              value={QUOTE_FIELDS_DATA.returnType}
              value_update={updateMasterState}
              attrName={"returnType"}
              data={DROPDOWN_DATA.RETURN_TYPE}
              warn_status={false}
              error_message="Select Return Type"
            />
          </Grid>
        </Grid>
      </Box>

      {/* quotes section */}
      <Box padding={"24px 16px 80px"} width="100%">
        <Box marginBottom={"24px"}>
          <Grid container size={12} columnSpacing={2}>
            <Grid size="grow">
              <h5 style={{ fontWeight: "bold" }}>
                {quoteListData?.length > 0
                  ? (quoteListData?.length === 1 ? "0" : "") +
                    `${quoteListData?.length} Plans Found`
                  : "No Plan Found"}
              </h5>
            </Grid>
            <Grid size="grow" textAlign={"right"}>
              <p>
                <small style={{ color: "#595959" }}>
                  Order ID: {ADD_FORM_RESPONSE?.quote_no}
                </small>
              </p>
            </Grid>
          </Grid>
        </Box>

        {/* Quotes */}

        <Grid container size={12} columnSpacing={2}>
          <Grid size={12}>
            {!QUOTE_LOADER ? (
              quoteListData && quoteListData.length > 0 ? (
                quoteListData.map((data: any, index: number) => (
                  <MNonTermQuoteCard
                    quote_data={data}
                    index={index}
                    BUY_ACTION={BUY_ACTION}
                  />
                ))
              ) : (
                <Box className="no-quote-box" sx={{ textAlign: "center" }}>
                  <img src="../images/no_quotes_icon.svg" alt="" />
                  <h5 className="my-3">No Matches: Keep Exploring!</h5>
                  <p>Sorry, no results this time. Let's try something else.</p>
                </Box>
              )
            ) : null}
          </Grid>
        </Grid>
      </Box>

      {/* black addonstrip */}
      <Box className="addonStrip">
        <Link
          className="singleAddonLink"
          onClick={() => setIsInvestReturnCalcOpen(true)}
        >
          <img src="./images/calc_icon_mobile.svg" alt="plan-icon" />
          Return Calculator
        </Link>

        {/* <Box className="stripBox" style={{ flex: 1 }}>
          <Link className="addonLink" onClick={() => setOpenRidersPopup(true)}>
            <img src="./images/ic_filter.svg" alt="plan-icon" />
            Riders
          </Link>
        </Box> */}
      </Box>
    </Box>
  );
}

export default MNonTermQuote;
