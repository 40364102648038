import { Box, Checkbox, FormControlLabel } from "@mui/material";
import Grid from "@mui/material/Grid2";
import NonTermFooter from "../../../../Component/Amaze/NonTerm/NonTermFooter/NonTermFooter";
import { useAppSelector } from "../../../../Store/hooks";
import { isEmpty } from "../../../../SupportingFiles/HelpingFunction";
import { TNonTermProposerData } from "../../../../types/TNonTermSlice";
import "./../../../../SCSS/Amaze/CommonSCSS/ProposalForms.scss";
import {
  EVDateInput,
  EVSelectDropdown,
  EVTextField,
} from "@evervent_pvt_ltd/ui-kit";

const NonTermProposal = ({
  fieldsUpdateState,
  validateForm,
  loader,
  proposerFields,
}: {
  proposerFields: TNonTermProposerData;
  fieldsUpdateState: Function;
  validateForm: Function;
  loader: boolean;
}) => {
  const { DROPDOWN_DATA, ADD_FORM, QUOTE_LOADER, UPDATE_RESPONSE } =
    useAppSelector((state) => state.NonTerm);

  return (
    <Box>
      <h5 className="sectionTitle">Proposer Details</h5>
      <Grid container spacing={3}>
        <Grid size={12}>
          <h6>Proposer / Insured Details</h6>
        </Grid>
        <Grid size={6}>
          <EVTextField
            id="full_name"
            title={"Full Name"}
            max_length={75}
            validation_type="NAME"
            value={proposerFields.full_name.value}
            attrName={["full_name"]}
            value_update={fieldsUpdateState}
            warn_status={proposerFields?.full_name?.warning}
            error_message={
              isEmpty(proposerFields.full_name.value)
                ? "Enter Full Name"
                : "Enter Valid Full Name"
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className="my-6">
        <Grid size={4}>
          <EVDateInput
            id="dob"
            title={"DOB"}
            value={
              UPDATE_RESPONSE?.dob ? UPDATE_RESPONSE?.dob : ADD_FORM.age.value
            }
            attrName={["dob"]}
            disabled={true}
            value_update={fieldsUpdateState}
            error_message="Enter DOB"
            warn_status={ADD_FORM.age.warning}
          />
        </Grid>
        <Grid size={4}>
          <EVSelectDropdown
            id="gender"
            title="Gender"
            value={ADD_FORM.gender.value}
            attrName={["gender"]}
            value_update={fieldsUpdateState}
            data={[
              { label: "Male", value: "M" },
              { label: "Female", value: "F" },
            ]}
            disabled={true}
            warn_status={ADD_FORM?.gender?.warning}
            error_message="Select Gender"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid size={4}>
          <EVTextField
            id="mobile"
            disabled
            title={"Mobile"}
            value={ADD_FORM.mobile.value}
            attrName={["mobile"]}
            value_update={fieldsUpdateState}
            warn_status={ADD_FORM.mobile.warning}
            max_length={10}
            validation_type="NUMBER"
            error_message={!ADD_FORM.mobile.value ? "Enter mobile number" : ""}
          />
        </Grid>
        <Grid size={6}>
          <EVTextField
            id="email"
            title={"Email"}
            value={proposerFields.email.value}
            attrName={["email"]}
            value_update={fieldsUpdateState}
            warn_status={proposerFields?.email?.warning}
            error_message={
              isEmpty(proposerFields.email.value)
                ? "Enter Email"
                : "Enter Valid Email"
            }
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} className="my-4">
        <Grid size={4}>
          <EVTextField
            id="pincode"
            title={"Pincode"}
            value={ADD_FORM.pincode.value}
            attrName={["pincode"]}
            value_update={fieldsUpdateState}
            warn_status={ADD_FORM.pincode.warning}
            validation_type="NUMBER"
            max_length={6}
            disabled={true}
            error_message={!ADD_FORM.pincode.warning ? "Enter Pincode" : ""}
          />
        </Grid>
        <Grid size={6}>
          <EVTextField
            id="emergency_number"
            title={"Emergency Phone"}
            value={proposerFields.emergency_number.value}
            attrName={["emergency_number"]}
            value_update={fieldsUpdateState}
            warn_status={proposerFields?.emergency_number?.warning}
            max_length={10}
            validation_type="NUMBER"
            error_message={
              isEmpty(proposerFields.emergency_number.value)
                ? "Enter Emergency Phone Number "
                : "Enter Valid Emergency Phone Number"
            }
          />
        </Grid>
        <Grid size={4}>
          <EVSelectDropdown
            id="occupation"
            title="Occupation"
            value={proposerFields?.occupation?.value}
            attrName={["occupation"]}
            value_update={fieldsUpdateState}
            data={DROPDOWN_DATA.OCCUPATION_MAX_LIFE_DATA}
            warn_status={proposerFields?.occupation?.warning}
            error_message="Select Proposer Occupation"
          />
        </Grid>
        <Grid size={4}>
          <EVSelectDropdown
            id="qualification"
            title="Qualification"
            value={proposerFields?.qualification?.value}
            attrName={["qualification"]}
            value_update={fieldsUpdateState}
            data={DROPDOWN_DATA.QUALIFICATION_MAX_LIFE_DATA}
            warn_status={proposerFields?.qualification?.warning}
            error_message="Select Proposer Qualification"
          />
        </Grid>
        <Grid size={12}>
          <FormControlLabel
            className="proposalCheckBoxWrapper"
            control={
              <Checkbox
                value={proposerFields.PCB}
                defaultChecked={proposerFields.PCB}
                onClick={() => fieldsUpdateState(["PCB"], !proposerFields.PCB)}
              />
            }
            label="Opt for PCB?"
          />
        </Grid>
        <Grid size={12}>
          <FormControlLabel
            className="proposalCheckBoxWrapper"
            control={
              <Checkbox
                value={proposerFields.payer_selected}
                defaultChecked={proposerFields.payer_selected}
                onClick={() =>
                  fieldsUpdateState(
                    ["payer_selected"],
                    !proposerFields.payer_selected
                  )
                }
              />
            }
            label="Are you paying for someone else?"
          />
        </Grid>
        {proposerFields.payer_selected ? (
          <Grid size={4}>
            <EVSelectDropdown
              id="relationship"
              title="Relationship"
              value={proposerFields?.payer_relationship?.value}
              attrName={["payer_relationship"]}
              value_update={fieldsUpdateState}
              data={[
                { value: "01", label: "Spouse" },
                { value: "02", label: "Parents" },
              ]}
              warn_status={proposerFields?.payer_relationship?.warning}
              error_message="Select Relationship"
            />
          </Grid>
        ) : null}
      </Grid>
      <NonTermFooter loader={QUOTE_LOADER} forward={validateForm} />
    </Box>
  );
};

export default NonTermProposal;
