import React, { useEffect, useState } from "react";
import { blog_data } from "../../Component/Desktop/Common/BlogArray/BLogArray";
import Home from "../../Page/Desktop/Home/Home";
import MHome from "../../Page/Mobile/MHome/MHome";
import { CAR_ADD_FORM_SERVICES } from "../../Services/Car/CarAddFormServices";
import { TW_ADD_FORM_SERVICES } from "../../Services/TW/TWAddFormServices";
import { useAppDispatch, useAppSelector } from "../../Store/hooks";
import { CarSlice } from "../../Store/Slice_Reducer/Car/CarSlice";
import { CKYCSlice } from "../../Store/Slice_Reducer/CKYC/CKYCSlice";
import { HealthSlice } from "../../Store/Slice_Reducer/Health/HealthSlice";
import { HomeSlice } from "../../Store/Slice_Reducer/Home/HomeSlice";
import { NonTermSlice } from "../../Store/Slice_Reducer/NonTerm/NonTermSlice";
import { TermSlice } from "../../Store/Slice_Reducer/Term/TermSlice";
import { TWSlice } from "../../Store/Slice_Reducer/TwoWheeler/TWSlice";
import {
  FORMAT_DD_MM_YYYY,
  isEmpty,
  validateEmail,
  validateMobileNumber,
  validatePincode,
  validateRegno,
} from "../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import { TNonTermAddForm } from "../../types/NonTerm/TNonTermAddForm";
import { TCarAddForm } from "../../types/TCarSlice";
import { TTermAddForm } from "../../types/Term/TTermAddForm";
import { TTWForm } from "../../types/TTWSlice";
import { pushDataLayer, sendEventToNativeApp } from "../../utils/analytics";
import { toast } from "react-toastify";

function HomeContainer() {
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const { Car, TW, Term, Health, NonTerm } = useAppSelector((state) => state);
  const [loader, setLoader] = useState<boolean>(false);
  const [openCarForm, setOpenCarForm] = useState(false);
  const [openTermForm, setOpenTermForm] = useState(false);
  const [openTWForm, setOpenTWForm] = useState(false);
  const [openCarPrefilledData, setOpenCarPrefilledData] = useState(false);
  const [openTWPrefilledData, setOpenTWPrefilledData] = useState(false);
  const [openHealthForm, setOpenHealthForm] = useState(false);
  const [openNonTermForm, setOpenNonTermForm] = useState(false);
  const [openContactusPopup, setOpenContactusPopup] = useState(false);
  const [pageStatus, setPageStatus] = useState<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8>(
    1
  );

  const [activeformTabs, setActiveFormTabs] = React.useState<
    "CAR" | "TW" | "HEALTH" | "TERM" | "INVESTMENT"
  >("CAR");
  const handleFormTabsChange = (
    newValue: "CAR" | "TW" | "HEALTH" | "TERM" | "INVESTMENT"
  ) => {
    setActiveFormTabs(newValue);
  };

  type BlogCard = {
    id: string;
    img_url: string;
    blogtitle: string;
    blog_desc: string;
  };
  const [blogCards, setBlogCards] = useState<BlogCard[]>([]);
  useEffect(() => {
    setBlogCards(blog_data);
  }, []);

  const blogCardsArray = blogCards.slice(0, 3);

  useEffect(() => {
    dispatch(HomeSlice.actions.setPageStatus(activeformTabs));
    dispatch(CarSlice.actions.SET_CAR_SLICE(CarSlice.getInitialState()));
    dispatch(TWSlice.actions.SET_TW_SLICE(TWSlice.getInitialState()));
    dispatch(TermSlice.actions.BULK_UPDATE(TermSlice.getInitialState()));
    dispatch(
      NonTermSlice.actions.BULK_UPDATE_DATA(NonTermSlice.getInitialState())
    );
    dispatch(
      HealthSlice.actions.SET_HEALTH_SLICE(HealthSlice.getInitialState())
    );
    dispatch(CKYCSlice.actions.BULK_UPDATE(CKYCSlice.getInitialState()));
    dispatch(
      NonTermSlice.actions.BULK_UPDATE_DATA(NonTermSlice.getInitialState())
    );
  }, [activeformTabs, window.location.pathname]);

  const GET_VEHICLE_INFO_CAR = () => {
    const onSuccess = (res: any) => {
      setLoader(false);
      const results = res?.data;
      const response = results?.response;
      const error = res?.data?.error;

      if (error) {
        console.log(res?.data?.message, "dbnbfgngn");
        dispatch(
          CarSlice.actions.UPDATE_FORM_DATA([
            {
              key: "reg_no",
              value: { value: Car.ADD_FORM.reg_no.value, warning: true },
            },
          ])
        );
      } else {
        const make = response?.make;
        const model = response?.model;
        const make_model = response?.make_model;
        const variant_cc = `${response?.variant_cc}`;
        const fuel_type = response?.fuel_type;
        const registration_date = response?.registration_date;
        const policy_expiry_date = response?.policy_expiry_date;

        if (
          !isEmpty(make) &&
          !isEmpty(make_model) &&
          !isEmpty(variant_cc) &&
          !isEmpty(fuel_type)
        ) {
          dispatch(
            CarSlice.actions.UPDATE_FORM_DATA([
              { key: "make", value: { value: make, warning: false } },
              { key: "model", value: { value: model, warning: false } },
              {
                key: "make_model",
                value: { value: make_model, warning: false },
              },
              {
                key: "variant_cc",
                value: { value: variant_cc, warning: false },
              },
              { key: "fuel_type", value: { value: fuel_type, warning: false } },
              {
                key: "reg_date",
                value: {
                  value: FORMAT_DD_MM_YYYY(registration_date),
                  warning: false,
                },
              },
            ])
          );
          setOpenCarPrefilledData(true);
        } else {
          denyPrefilledCar();
        }
      }
    };
    const onError = () => {
      setLoader(false);
    };

    setLoader(true);

    pushDataLayer(
      "jioinsure_home_cards",
      "car insurance",
      "Get free quotes",
      "Insure website"
    );

    const eventProps = {
      action: "car insurance",
      click: "Get free quotes",
      EntrySource: "Insure website",
    };
    sendEventToNativeApp("jioinsure_home_cards", eventProps);

    CAR_ADD_FORM_SERVICES.GET_VEHICLE_NO_INFO(
      onSuccess,
      onError,
      Car.ADD_FORM.reg_no.value
    );
  };

  const validateCarRollover = () => {
    let data: TCarAddForm = { ...Car.ADD_FORM };
    data = {
      ...data,
      reg_no: {
        ...data.reg_no,
        warning: !validateRegno(`${data.reg_no.value}`),
      },
      mobile: {
        ...data.mobile,
        warning: !validateMobileNumber(data.mobile.value),
      },
      // email: {
      //   ...data.email,
      //   warning: !validateEmail(data.email.value),
      // },
    };

    if (!data.reg_no.warning && !data.mobile.warning) {
      GET_VEHICLE_INFO_CAR();
    }
    dispatch(
      CarSlice.actions.BULK_UPDATE({
        ...Car,
        ADD_FORM: {
          ...CarSlice.getInitialState().ADD_FORM,
          reg_no: data.reg_no,
          mobile: data.mobile,
          // email: data.email,
          business_type: "Rollover",
          term_cond: data.term_cond,
        },
      })
    );
  };

  const continuePrefilledCar = () => {
    setOpenCarPrefilledData(false);
    setOpenCarForm(true);
    setPageStatus(6);
  };

  const denyPrefilledCar = () => {
    dispatch(
      CarSlice.actions.UPDATE_FORM_DATA([
        { key: "make", value: { value: "", warning: false } },
        { key: "model", value: { value: "", warning: false } },
        { key: "fuel_type", value: { value: "", warning: false } },
        { key: "variant_cc", value: { value: "", warning: false } },
      ])
    );
    setOpenCarPrefilledData(false);
    setOpenCarForm(true);
    setPageStatus(2);
  };

  const GET_VEHICLE_INFO_TW = () => {
    const onSuccess = (res: any) => {
      setLoader(false);
      const results = res?.data;
      const response = results?.response;
      const error = res?.data?.error;

      if (error) {
        dispatch(
          TWSlice.actions.UPDATE_FORM_DATA([
            {
              key: "reg_no",
              value: { value: TW.ADD_FORM.reg_no.value, warning: true },
            },
          ])
        );
      } else {
        const make = response?.make;
        const model = response?.model;
        const make_model = response?.make_model;
        const variant_cc = `${response?.variant_cc}`;
        const fuel_type = response?.fuel_type;
        const registration_date = response?.registration_date;

        if (
          !isEmpty(make) &&
          !isEmpty(model) &&
          !isEmpty(variant_cc) &&
          !isEmpty(fuel_type)
        ) {
          dispatch(
            TWSlice.actions.BULK_UPDATE({
              ...TW,
              ADD_FORM: {
                ...TW.ADD_FORM,
                make: { value: make, warning: false },
                model: { value: model, warning: false },
                make_model: { value: make_model, warning: false },
                variant_cc: { value: variant_cc, warning: false },
                fuel_type: { value: fuel_type, warning: false },
                reg_date: {
                  value: FORMAT_DD_MM_YYYY(registration_date),
                  warning: false,
                },
              },
            })
          );

          setOpenTWPrefilledData(true);
        } else {
          denyPrefilledTW();
        }
      }
    };
    const onError = () => {
      setLoader(false);
    };

    setLoader(true);
    pushDataLayer(
      "jioinsure_home_cards",
      "two wheeler insurance",
      "Get Free Quotes",
      "Insure website"
    );
    const eventProps = {
      action: "two wheeler insurance",
      click: "Get free quotes",
      EntrySource: "Insure website",
    };
    sendEventToNativeApp("jioinsure_home_cards", eventProps);
    TW_ADD_FORM_SERVICES.GET_VEHICLE_NO_INFO(
      onSuccess,
      onError,
      TW.ADD_FORM.reg_no.value
    );
  };

  const validateTWRollover = () => {
    let data: TTWForm = { ...TW.ADD_FORM };
    data = {
      ...data,
      reg_no: {
        ...data.reg_no,
        warning: !validateRegno(`${data.reg_no.value}`),
      },
      mobile: {
        ...data.mobile,
        warning: !validateMobileNumber(data.mobile.value),
      },
      // email: {
      //   ...data.email,
      //   warning: !validateEmail(data.email.value),
      // },
    };

    if (!data.reg_no.warning && !data.mobile.warning) {
      GET_VEHICLE_INFO_TW();
    }
    dispatch(
      TWSlice.actions.BULK_UPDATE({
        ...TW,
        ADD_FORM: {
          ...TWSlice.getInitialState().ADD_FORM,
          reg_no: data.reg_no,
          mobile: data.mobile,
          // email: data.email,
          business_type: "Rollover",
          term_cond: data.term_cond,
        },
      })
    );
  };

  const continuePrefilledTW = () => {
    setOpenTWPrefilledData(false);
    setOpenTWForm(true);
    setPageStatus(6);
  };

  const denyPrefilledTW = () => {
    dispatch(
      TWSlice.actions.BULK_UPDATE({
        ...TW,
        ADD_FORM: {
          ...TW.ADD_FORM,
          make: { value: "", warning: false },
          model: { value: "", warning: false },
          fuel_type: { value: "", warning: false },
          variant_cc: { value: "", warning: false },
        },
      })
    );

    setOpenTWPrefilledData(false);
    setOpenTWForm(true);
    setPageStatus(2);
  };
  const validateHealth = () => {
    let data: any = { ...Health.ADD_FORM };
    data = {
      ...data,
      pincode: {
        ...data.pincode,
        warning: !validatePincode(`${data.pincode.value}`),
      },
      mobile: {
        ...data.mobile,
        warning: !validateMobileNumber(data.mobile.value),
      },
      // email: {
      //   ...data.email,
      //   warning: !validateEmail(data.email.value),
      // },
      gender: {
        ...data.gender,
        warning: isEmpty(data.gender.value),
      },
    };

    if (!data.mobile.warning && !data.pincode.warning) {
      // GET_HEALTH_INFO();
      dispatch(HealthSlice.actions.FORM_MEMBER_MODEL(true));
      setOpenHealthForm(true);

      pushDataLayer(
        "jioinsure_home_cards",
        "health insurance",
        "Get free quotes",
        "Insure website"
      );
      const eventProps = {
        action: "health insurance",
        click: "Get free quotes",
        EntrySource: "Insure website",
      };
      sendEventToNativeApp("jioinsure_home_cards", eventProps);
    }
    dispatch(
      HealthSlice.actions.BULK_UPLOAD({
        ...Health,
        ADD_FORM: {
          ...HealthSlice.getInitialState().ADD_FORM,
          mobile: data.mobile,
          // email: data.email,
          pincode: data.pincode,
          gender: data.gender,
          term_cond: data.term_cond,
        },
      })
    );
  };
  const validateTerm = () => {
    let data: TTermAddForm = { ...Term.ADD_FORM };
    data = {
      ...data,
      mobile: {
        ...data.mobile,
        warning: !validateMobileNumber(data.mobile.value),
      },
      age: {
        ...data.age,
        warning: isEmpty(data.age.value),
      },
    };

    dispatch(TermSlice.actions.UPDATE_ADD_FORM_DATA(data));

    if (!Term?.ADD_FORM?.term_cond?.value) {
      toast.error("Please accept terms and conditions");
      return;
    }

    if (!data.mobile.warning && !data.age.warning) {
      pushDataLayer(
        "jioinsure_home_cards",
        "life insurance",
        "Get free quotes",
        "Insure website"
      );
      const eventProps = {
        action: "life insurance",
        click: "Get free quotes",
        EntrySource: "Insure website",
      };
      sendEventToNativeApp("jioinsure_home_cards", eventProps);
      setOpenTermForm(true);
    }
  };
  const validateNonTerm = () => {
    let data: TNonTermAddForm = { ...NonTerm.ADD_FORM };
    console.log("data", data);
    data = {
      ...data,
      mobile: {
        ...data.mobile,
        warning: !validateMobileNumber(data.mobile.value),
      },
      // email: {
      //   ...data.email,
      //   warning: !validateEmail(data.email.value),
      // },
      age: {
        ...data.age,
        warning: isEmpty(data.age.value),
      },
    };
    dispatch(NonTermSlice.actions.NON_TERM_UPDATE_ADD_FORM_DATA(data));
    if (!data.mobile.warning && !data.age.warning) {
      setOpenNonTermForm(true);
    }
  };

  const headerSliderArray = [
    {
      slider_img: "./images/car-banner.webp",
      INS_Type_tag: "Car Insurance",
      tagline: "Comprehensive plans for 360° protection",
    },
    {
      slider_img: "./images/bike-banner.webp",
      INS_Type_tag: "Two-Wheeler Insurance",
      tagline: "Get your two-wheeler insurance in just few clicks",
    },
    {
      slider_img: "./images/health-banner.webp",
      INS_Type_tag: "Health Insurance",
      tagline: "Health cover to protect your wealth",
    },
    {
      slider_img: "./images/life-hero-banner.webp",
      INS_Type_tag: "Term Insurance",
      tagline: "Protect your present for a secured future",
    },
  ];

  const whySectionArray = [
    {
      iconUrl: "./images/ic_ticket_status.svg",
      heading: "Tailor made products ",
      desc: "Customisable coverages",
    },
    {
      iconUrl: "./images/ic_assistive_grid.svg",
      heading: "Bouquet of insurers",
      desc: "Variety of insurers to choose coverage",
    },
    {
      iconUrl: "./images/ic_team.svg",
      heading: "Specialised guidance ",
      desc: "Experts to offer pre & post sale assistance ",
    },
    {
      iconUrl: "./images/ic_tariff_checking.svg",
      heading: "Expedited claim processing ",
      desc: "Professional support for claim assistance ",
    },
    {
      iconUrl: "./images/ic_secured.svg",
      heading: "Dependable & Reliable ",
      desc: "Brand that you can trust ",
    },
  ];
  const mobileResponsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 300 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const MobilePartersLogos = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 300 },
      items: 4,
      slidesToSlide: 2, // optional, default to 1.
    },
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 464 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const partersLogos = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 464 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const testimonialsSlider = [
    {
      rating: 5,
      review:
        "“Extremely happy. Very satisfied with your express settlement of claims. My experience with you is unique as compared with others. I wish your company a very bright and prosperous future. Thank You!”",
      reviewer_name: "Amit Khandewal",
      location: "Delhi",
    },
    {
      rating: 4.5,
      review:
        " “Buying car insurance has never been so easy. It just took me 5 minutes to buy the policy. Very easy and quick. Will recommend it to everyone.”",
      reviewer_name: "Dr. Rupa Jha",
      location: "Mumbai",
    },
    {
      rating: 5,
      review: `“The multiple options from various Insurers under one roof was helpful and gave me the confidence that I am opting for the right product as required. Excellent support provided by your advisor.”`,
      reviewer_name: "Kunal Sharma",
      location: "Banglore",
    },
    {
      rating: 5,
      review: `“Renewing my Car insurance from Jio Insurance Broking was a great experience. Overall experience was really good and I was able to complete my transaction in just 5 minutes. I recommend everyone to buy from Jio Insurance Broking Limited.”`,
      reviewer_name: "Jamnadas Bhalani",
      location: "Delhi",
    },
    {
      rating: 4,
      review: `“Buying ICICI Lombard car insurance from Jio Insurance Broking is great. It just took me 5 minutes to buy a policy online. Very easy and user friendly. Will recommend it to everyone.”`,
      reviewer_name: "Vikas Priyadarshi",
      location: "Indore",
    },
    {
      rating: 4.5,
      review: `“The team handled my renewal query and process very smoothly. They also convinced me to go for a personal cover policy which I am happy to opt for. They are a good asset to the team.”`,
      reviewer_name: "Kunal Chavda",
      location: "Chandigarh",
    },
    {
      rating: 5,
      review: `“The online quote system was simple to use with clear options. The additional options are well laid out and explained on the website, so I knew exactly what I could buy and compare to what I want. Hence renewed my two-wheeler policy online without any trouble. Excellent website”`,
      reviewer_name: "Chirag Lukka",
      location: "Delhi",
    },
    {
      rating: 4.5,
      review: `“I renewed my health and motor policy on icicilombard.com and it was a seamless experience. On my previous policy, I had registered a claim last month and I was pleasantly surprised with the claim settlement process. It was extremely hassle free and quick.”`,
      reviewer_name: "Abhishek Gupta",
      location: "Mumbai",
    },
    {
      rating: 5,
      review: `“Extremely happy. Very satisfied with your express settlement of claims. My experience with you is unique as compared with others. I wish your company a very bright and prosperous future. THANKS ICICI LOMBARD”`,
      reviewer_name: "Leena Vaddella",
      location: "Delhi",
    },
  ];

  const partnersSlider = [
    {
      imgUrl:
        "../images/insurerlogo/ICICI-Lombard-General-Insurance-Co-Ltd.svg",
    },
    { imgUrl: "./images/insurerlogo/HDFC-ERGO-General-Insurance-Co-Ltd.svg" },
    {
      imgUrl: "./images/insurerlogo/Bajaj-Allianz-General-Insurance-Co-Ltd.svg",
    },
    {
      imgUrl: "./images/insurerlogo/Royal_Sundaram.svg",
    },
    {
      imgUrl:
        "./images/insurerlogo/Magma-HDI-General-Insurance-Company-Limited.svg",
    },
    {
      imgUrl: "./images/insurerlogo/Tata-AIG-General-Insurance-Co-Ltd.svg",
    },
    {
      imgUrl:
        "./images/insurerlogo/HDFC-ERGO-Health-Insurance-Company-Limited.svg",
    },
    {
      imgUrl:
        "./images/insurerlogo/Manipal-CignaTTK-Health-Insurance-Company-Limited.svg",
    },
    {
      imgUrl:
        "./images/insurerlogo/Religare-Health-Insurance-Company-Limited.svg",
    },
    {
      imgUrl:
        "./images/insurerlogo/Niva-Max-Bupa-Health-Insurance-Company-Ltd.svg",
    },
    {
      imgUrl: "./images/insurerlogo/aditya_birla_capital.svg",
    },
    {
      imgUrl:
        "./images/insurerlogo/Star-Health-and-Allied-Insurance-Company-Limited.svg",
    },
    {
      imgUrl:
        "./images/insurerlogo/Tata-AIA-Life-Insurance-Company-Limited.svg",
    },
    { imgUrl: "./images/insurerlogo/HDFC-Standard-Life-Insurance-Co-Ltd.svg" },
    {
      imgUrl: "./images/insurerlogo/ICICI-Prudential-Life-Insurance-Co-Ltd.svg",
    },
    {
      imgUrl: "./images/insurerlogo/Max-Life-Insurance-Co-Ltd.svg",
    },
  ];

  const gifaqs = [
    {
      title:
        "Do I need to have a valid insurance policy to get my new vehicle registered?",
      desc: "Yes, you must have a current auto insurance policy to register your vehicle. Even having third-party (TP) auto insurance will be beneficial at RTO.",
    },
    {
      title: "What are the different modes of payment accepted online?",
      desc: "You have different options for paying the premium: net banking, credit card, or debit card, and UPI/wallets",
    },
    {
      title:
        "Am I eligible for a discount if I am a member of Automobile Association of India?",
      desc: "Yes, the majority of insurance providers in India offer discounts to the members of the Automotive Research Association of India (ARAI).",
    },
    {
      title: "Can I get add-ons with my policy?",
      desc: "Supplements are not covered by comprehensive insurance. You must pay an additional price to purchase the add-on coveres in order to increase the coverage. Zero depreciation insurance, roadside assistance, engine protection, and return to invoice are some add-on coverage. ",
    },
    {
      title: "What is premium?",
      desc: "Amount of money to be paid to the insurer at predefined frequency for purchasing the insurance policy.",
    },
    {
      title: "In which cases is the inspection of a vehicle mandatory?",
      desc: "If you renew your car offline after the expiration date, an inspection of the vehicle is required. You must bring the required paperwork and your two-wheeler for examination to the insurer.",
    },
  ];
  const carfaqs = [
    {
      title: "Why do I need car insurance?",
      desc: "In India, having third-party car insurance is mandatory. Additionally, you need a protective shield for yourself and your loved ones so that any financial loss or damages caused by accidents are compensated. In such cases, the insurance provider will cover your damages",
    },
    {
      title: "What are the benefits of buying car insurance online?",
      desc: "One of the main benefits of purchasing auto insurance online is that there is no paperwork or proof required. Additionally, you have a variety of payment alternatives.",
    },
    {
      title: "How quickly can I buy car insurance policy online?",
      desc: "It takes only a few minutes to purchase a car insurance online. Simply enter the necessary information, get quotes as per your needs from multiple insurers and make the payment.A copy of your car insurance will be sent to your email address immediately.",
    },
    {
      title: "Are offline and online car insurance policies the same? ",
      desc: "Yes, both are same. One of the benefits of online insurance is that the copy of the policy is sent directly to your registered email address after the payment is successfully done.",
    },
    {
      title: "What happens to my car insurance if I change jobs or locations?",
      desc: "The premium may alter based on the city you are moving to, but the policy will essentially stay the same. This is due to the fact that insurance costs vary depending on where an automobile is registered. Typically, metro areas charge a bigger premium than the rest of the nation. Simply notify the insurer about the job/location change to have the information updated. In any case, it is suggested that you update your contact information, including your new phone number and address, which can also be done online.",
    },
    {
      title: "What happens to the 4-wheeler insurance policy if I sell my car?",
      desc: (
        <>
          On selling your car, you must change the insurance policy owner's name
          from the current owner to the new owner. Below mentioned documents are
          needed for the same: <br />• Sale deed
          <br /> • Forms 29 and 30
          <br /> • Seller's NOC
          <br /> • NCB Recovery Amount
          <br /> However, the No Claim Bonus accrued as a result of your
          insurance may be transferred into your name and can be applied to your
          new vehicle. Additionally, you have the choice to revoke the current
          policy as well.
        </>
      ),
    },
    {
      title:
        "How do I get a copy of my car insurance policy online? Will the printout of the soft copy serve as the original document?",
      desc: (
        <>
          Choose the option to download an electronic copy of your policy by
          visiting the insurance company's official website. Your registered
          mobile number will receive an OTP for verification when you enter your
          policy number. Your registered email address and the OTP must both be
          entered. Your mail ID will receive a PDF copy of your auto insurance
          policy. The policy may then be downloaded.
          <br /> IIn addition to the actual physical documents, the Ministry of
          Road Transport and Highways has announced that digital copies of
          documents like a driver's licence and those for a vehicle's
          registration, insurance, etc., that are kept in Digilocker or the
          mParivahan mobile app will now be accepted legally. Original documents
          or reproductions of the same are not acceptable.
        </>
      ),
    },
    {
      title:
        "Am I eligible for a car insurance discount if I install an anti-theft alarm and locking system?",
      desc: "Yes, you will receive a discount if you install an additional layer of security because it will reduce the danger of theft for the insurer who supports this.",
    },
    {
      title:
        "What are electrical and non-electrical accessories? How do you calculate their value?",
      desc: "People install both electrical and non-electrical gadgets in their cars. Typical electrical attachments include LCD TVs, fog lights, music systems, and so forth. The interior fittings, such as seat covers, wheel covers, and CNG kits, are non-electrical extras. After applying the depreciation rate, their value is determined based on its initial market value.",
    },
  ];
  const bikefaqs = [
    {
      title: "What is a Two-Wheeler comprehensive insurance policy?",
      desc: "Protection from damage to both your own and third-party vehicles is offered by a comprehensive plan. In addition to accidents, it also covers theft and damage to the vehicle caused by any type of natural disaster, such as floods or storms, as well as human-made events, such as rioting and vandalism. The law mandates the purchase of third-party insurance, although experts advise Two-Wheeler owners to choose a complete policy for greater coverage.",
    },
    {
      title: "What is the meaning of zero depreciation two-wheeler insurance?",
      desc: "Zero Depreciation Cover is an addition to your current policy. Over time, the Two-Wheeler loses some of its value. The depreciation rate is what causes the decreased market value. A brand-new car loses 5–10% of its value the instance it leaves the showroom because the next buyer will be purchasing a used car. Therefore, even if you choose comprehensive insurance coverage, the claim money you will receive if your two-wheeler is stolen or completely destroyed will be based on the depreciated value of the Two-Wheeler's components. For instance, you would receive the latter if the depreciated worth of your Rs. 90,000 Two-Wheeler is Rs. 60,000. However, you will receive Rs. 90,000 if you have Zero Depreciation Cover.",
    },
    {
      title:
        "How does the emergency assistance program work under two-wheeler insurance?",
      desc: "You receive round-the-clock help to minimize any technical or mechanical breakdown problems if you choose Emergency Assistance Cover. This extra advantage includes on-site basic repairs, flat tires, battery jump starts, tank filling, lost key help, duplicate key issues, and even towing fees up to 100 kilometers from your registered home. In some situations, if the policyholder needs somewhere to stay while the Two-Wheeler is being repaired, the insurer will additionally cover the costs of lodging.",
    },
    {
      title:
        "How do I get a copy of my two-wheeler insurance policy online? Will the printout of the soft copy serve as the original document?",
      desc: "Visit the insurance company's official website. Enter your policy number and any other necessary information after choosing the appropriate policy type from the list of insurance policies. Download and print the policy paper once you have it. Get in touch with your insurance company if you can't discover the policy number. The Digilocker or mParivahan mobile app-stored digital copies of documents, including a driver's licence and a car's registration, insurance, and more, are accepted legally, according to the Ministry of Road Transport and Highways. Original documents are no longer required, only photocopies.",
    },
    {
      title:
        "What are the documents required to buy two-wheeler insurance online?",
      desc: "Documents needed to purchase Two-Wheeler insurance online include proof of identity (driver's licence, passport, Aadhar card, PAN card, government-issued ID card), proof of address (bank or post office passbook, government-issued address proof), recent passport-size photo, a valid driver's licence, Two-Wheeler registration certificate, and information for net banking, credit cards, debit cards, and UPI/wallets.",
    },
    {
      title: "Which online two-wheeler insurance is best for two-wheelers?",
      desc: "The ideal insurance plan is the one that offers you the greatest advantages at the lowest cost. To choose which plan best meets your needs, you can compare options. Although you don't have to go to the insurer's office or buy a policy from a licensed insurance agent, purchasing an insurance policy online is quicker and easier. Due to the insurance company's ability to reduce agent commission costs and pass those savings along to you, the online approach will enable you to receive some reductions.",
    },
  ];
  const healthfaqs = [
    {
      title: "What is health insurance?",
      desc: "In simple terms, health insurance is a type of insurance that covers medical expenses that arise due to unexpected illness or accidents. These expenses could be related to hospitalisation costs, surgeries, day care procedures, cost of medicines,etc",
    },
    {
      title: "What types of health insurance products are available?",
      desc: (
        <>
          Below is the list of available health insurance products:
          <br /> • Individual Health Insurance <br />• Family Health Insurance
          <br /> • Top-Up Health Insurance
          <br /> • Senior Citizen Health Insurance
          <br /> • Critical Illness Insurance
          <br /> • Hospital Daily Cash
        </>
      ),
    },
    {
      title: "What all is covered in a health insurance policy?",
      desc: (
        <>
          A health insurance plan offers comprehensive medical coverage against:
          <br />• Hospitalisation charges like bed charges, nursing charges,
          operation theatre expenses, etc.
          <br /> • Pre-hospitalization charges like ambulance expenses,
          diagnostic tests, etc.
          <br /> • Post-hospitalization charges like medicines, consulting, etc.
        </>
      ),
    },
    {
      title:
        "What is pre and post hospitalisation benefit in a health insurance policy?",
      desc: "Whenever you plan to have a surgery/medical procedure that requires hospitalisation, there are certain pre-hospitalization expenses such as diagnosis cost, Doctor consultations, etc. Similarly, post-discharge, there could be similar expenses to monitor the health of the insured patient; such expenses are termed as pre and post hospitalisation expenses.",
    },
    {
      title:
        "How many family members can be covered in a health insurance policy?",
      desc: (
        <>
          Below family members can be covered depending on the insurance plan:
          <br />
          <br /> • Spouse
          <br /> • Dependent children
          <br /> • Dependent legitimate or legally adopted children
          <br /> • Parents/Parents-in-law
        </>
      ),
    },
    {
      title: "What is the recommended age to buy health insurance?",
      desc: "You should buy health insurance as soon as you start earning. Your age is one of the most important factors that determine your health insurance premium; lower the age, lower the premium. This allows you to accumulate the cumulative benefits (for every claim-free year) by the time you are older, as you can avail for a higher coverage.",
    },
    {
      title: "What are the benefits of buying health insurance at a young age?",
      desc: "You get higher coverage, i.e., the sum assured at a lower premium. Probability of having a pre-existing illness is low hence waiting periods to start coverage of pre-existing cover will also not impact you. Also, common illnesses such as flu and accidental injury can happen at any age so staying covered even when you are young is important.",
    },
    {
      title:
        "What do you mean by pre-existing diseases with regards to a health insurance policy?",
      desc: "Pre-existing disease is a medical condition that already exists when one is purchasing a health insurance policy. Medical conditions such as high blood pressure, diabetes, thyroid, asthma, depression, etc. are considered as pre-existing ailments.",
    },
    {
      title:
        "Will my pre-existing diseases be covered in a health insurance policy?",
      desc: "Every insurer excludes coverage for pre-existing illnesses for a certain tenure, which is generally 3 years from the start of the policy. While buying an insurance policy, check if the pre-existing condition is temporarily not covered (pre-existing disease for the first few years) or it is completely excluded from the scope of coverage.",
    },
    {
      title:
        "What do you mean by Network/Non-network hospital with regards to a health insurance policy?",
      desc: "Network hospitals, often referred to as hospitals with cashless claim option, are tied up with your insurance company via a TPA (third party administrator) wherein you can avail the cashless hospitalisation benefit. However, as you get admitted at a non-network hospital, you have to pay the bills first and later claim for reimbursement.",
    },
  ];
  const termfaqs = [
    {
      title: "Is there an age limit to buy term insurance policy?",
      desc: "Any person who is 18 years or more can purchase term insurance policy, which has to be as per the conditions of the Indian Contract Act 1872",
    },
    {
      title: "What is premium-paying term?",
      desc: "Premium paying term is the number of years for the insured/policyholder to pay the premium",
    },
    {
      title: "Why should I buy term insurance?",
      desc: "Term Insurance policies act as a safety net for the family. You can get a huge amount of life cover (i.e. the sum assured) at a comparatively low premium",
    },
    {
      title: "What are riders in term Insurance?",
      desc: "Riders are optional, extra terms that go into effect along with your basic policy, at an additional cost. Simply put, a rider provides additional coverage and added protection against risks.",
    },
    {
      title: "How much term Insurance coverage is recommended?",
      desc: "The term insurance coverage amount should be enough to support your family financially after you. It is recommended to have life cover of at least ten times of your annual income.",
    },
    {
      title: "Is term insurance mandatory?",
      desc: "No, term Insurance is not mandatory but when you have dependents like children, parents & spouse, then term insurance becomes an integral aspect of your life. It provides you and your family financial protection even after your death.",
    },
    {
      title: "What types of riders are available in term Insurance?",
      desc: (
        <>
          Accidental death benefit rider
          <br /> Critical illness rider
          <br /> Permanent disability rider
          <br /> Income benefit rider Waiver of premium rider
        </>
      ),
    },
  ];

  return isMobile ? (
    <MHome
      loader={loader}
      openCarForm={openCarForm}
      openCarPrefilledData={openCarPrefilledData}
      openHealthForm={openHealthForm}
      openNonTermForm={openNonTermForm}
      openTermForm={openTermForm}
      openTWForm={openTWForm}
      openTWPrefilledData={openTWPrefilledData}
      validateCarRollover={validateCarRollover}
      validateHealth={validateHealth}
      validateNonTerm={validateNonTerm}
      validateTerm={validateTerm}
      validateTWRollover={validateTWRollover}
      continuePrefilledCar={continuePrefilledCar}
      denyPrefilledCar={denyPrefilledCar}
      continuePrefilledTW={continuePrefilledTW}
      denyPrefilledTW={denyPrefilledTW}
      pageStatus={pageStatus}
      openContactusPopup={openContactusPopup}
      setOpenContactusPopup={setOpenContactusPopup}
      setOpenCarForm={setOpenCarForm}
      setOpenCarPrefilledData={setOpenCarPrefilledData}
      setOpenHealthForm={setOpenHealthForm}
      setOpenNonTermForm={setOpenNonTermForm}
      setOpenTermForm={setOpenTermForm}
      setOpenTWForm={setOpenTWForm}
      setOpenTWPrefilledData={setOpenTWPrefilledData}
      setPageStatus={setPageStatus}
      headerSliderArray={headerSliderArray}
      whySectionArray={whySectionArray}
      responsive={mobileResponsive}
      partersLogos={MobilePartersLogos}
      testimonialsSlider={testimonialsSlider}
      partnersSlider={partnersSlider}
      gifaqs={gifaqs}
      carfaqs={carfaqs}
      bikefaqs={bikefaqs}
      healthfaqs={healthfaqs}
      termfaqs={termfaqs}
      blogCardsArray={blogCardsArray}
    />
  ) : (
    <Home
      loader={loader}
      openCarForm={openCarForm}
      openCarPrefilledData={openCarPrefilledData}
      openHealthForm={openHealthForm}
      openNonTermForm={openNonTermForm}
      openTermForm={openTermForm}
      openTWForm={openTWForm}
      openTWPrefilledData={openTWPrefilledData}
      validateCarRollover={validateCarRollover}
      validateHealth={validateHealth}
      validateNonTerm={validateNonTerm}
      validateTerm={validateTerm}
      validateTWRollover={validateTWRollover}
      continuePrefilledCar={continuePrefilledCar}
      denyPrefilledCar={denyPrefilledCar}
      continuePrefilledTW={continuePrefilledTW}
      denyPrefilledTW={denyPrefilledTW}
      pageStatus={pageStatus}
      openContactusPopup={openContactusPopup}
      setOpenContactusPopup={setOpenContactusPopup}
      setOpenCarForm={setOpenCarForm}
      setOpenCarPrefilledData={setOpenCarPrefilledData}
      setOpenHealthForm={setOpenHealthForm}
      setOpenNonTermForm={setOpenNonTermForm}
      setOpenTermForm={setOpenTermForm}
      setOpenTWForm={setOpenTWForm}
      setOpenTWPrefilledData={setOpenTWPrefilledData}
      setPageStatus={setPageStatus}
      activeformTabs={activeformTabs}
      handleFormTabsChange={handleFormTabsChange}
      headerSliderArray={headerSliderArray}
      whySectionArray={whySectionArray}
      responsive={responsive}
      partersLogos={partersLogos}
      testimonialsSlider={testimonialsSlider}
      partnersSlider={partnersSlider}
      gifaqs={gifaqs}
      carfaqs={carfaqs}
      bikefaqs={bikefaqs}
      healthfaqs={healthfaqs}
      termfaqs={termfaqs}
      blogCardsArray={blogCardsArray}
    />
  );
}

export default HomeContainer;
