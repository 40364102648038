import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import AddonCard from "../../../Component/Desktop/Common/AddonCard/AddonCard";
import AddonPopup from "../../../Component/Desktop/Common/AddonPopup/AddonPopup";
import { blog_data } from "../../../Component/Desktop/Common/BlogArray/BLogArray";
import LandingFooter from "../../../Component/Desktop/Common/LandingFooter/LandingFooter";
import LandingNavBar from "../../../Component/Desktop/Common/NavBarLanding/LandingNavBar";
import TestimonialCard from "../../../Component/Desktop/Common/TestimonialCard/TestimonialCard";
import FAQ from "../../../Component/Global/FAQ/FAQ";
import CustomCheckbox from "../../../Component/InputFields/CustomCheckbox/CustomCheckbox";
import JDSButtons from "../../../Component/InputFields/JDSButtons/JDSButtons";
import TextInputField from "../../../Component/InputFields/TextInputField/TextInputField";
import LandingMFooter from "../../../Component/Mobile/Common/LandingMFooter/LandingMFooter";
import LandingMNavBar from "../../../Component/Mobile/Common/LandingMNavBar/LandingMNavBar";
import TWFormContainer from "../../../Container/TW/Form/TWFormContainer";
import { COMMON_ROUTES } from "../../../Router/Path/CommonRoutes";
import styles from "../../../SCSS/CMSPages.module.scss";
import { TW_ADD_FORM_SERVICES } from "../../../Services/TW/TWAddFormServices";
import { CarSlice } from "../../../Store/Slice_Reducer/Car/CarSlice";
import { HealthSlice } from "../../../Store/Slice_Reducer/Health/HealthSlice";
import { TermSlice } from "../../../Store/Slice_Reducer/Term/TermSlice";
import { TWSlice } from "../../../Store/Slice_Reducer/TwoWheeler/TWSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import HelmetComponent from "../../../SupportingFiles/HelmetComponent";
import {
  FORMAT_DD_MM_YYYY,
  FORMAT_YYYY_MM_DD,
  isEmpty,
  validateEmail,
  validateMobileNumber,
  validateRegno,
} from "../../../SupportingFiles/HelpingFunction";
import { verifyJwt } from "../../../SupportingFiles/JWTDecoder";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { changeHeaderFooterVisibility } from "../../../SupportingFiles/SendEventToApp";
import { COLORS } from "../../../SupportingFiles/colors";
import { TTWForm } from "../../../types/TTWSlice";
import { pushDataLayer, sendEventToNativeApp } from "../../../utils/analytics";
import MTWPreFilledData from "../../Mobile/TW/Form/MTWPrefilledDataPopup";
import TWPrefilledData from "../TW/Form/TWPrefilledDataPopup";
import "./../../../SCSS/ProductForms.scss";
import { COMMON_SERVICES } from "../../../Services/Common/CommonService";
import ExitIntentPopup from "../../../Component/Desktop/Common/ExitIntentPopup/ExitIntentPopup";

function LandingTWProductPage() {
  type BlogCard = {
    id: string;
    img_url: string;
    blogtitle: string;
    blog_desc: string;
  };
  const [blogCardsArray, setBlogCardsArray] = useState<BlogCard[]>([]);
  useEffect(() => {
    setBlogCardsArray(blog_data);
  }, []);

  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const defaultShow = 5;
  const [showAll, setShowAll] = useState(false);
  const dispatch = useAppDispatch();

  const [openAddonPopup, setOpenAddonPopup] = useState(false);
  const [dataAddonPopup, setDataAddonPopup] = useState();
  const whySectionArray = [
    {
      iconUrl: "../images/list-icon.svg",
      heading: "Choose from the best",
      desc: "Get options to select best motor insurance from the top insurance companies.",
    },
    {
      iconUrl: "../images/max-icon.svg",
      heading: "Maximum Insured Declared Value",
      desc: "Get options to select best motor insurance from the top insurance companies.",
    },
    {
      iconUrl: "../images/addon-icon.svg",
      heading: "Add-ons",
      desc: "Customise your motor insurance policy with a wide range of add-on covers.",
    },
    {
      iconUrl: "../images/instant-icon.svg",
      heading: "Instant Policy Issuance",
      desc: "Get your policy copy in just 4 simple steps.",
    },
  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 320 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const testimonialsSlider = [
    {
      rating: 5,
      review:
        "“Extremely happy. Very satisfied with your express settlement of claims. My experience with you is unique as compared with others. I wish your company a very bright and prosperous future. Thank You!”",
      reviewer_name: "Amit Khandewal",
      location: "Delhi",
    },
    {
      rating: 4.5,
      review:
        " “Buying car insurance has never been so easy. It just took me 5 minutes to buy the policy. Very easy and quick. Will recommend it to everyone.”",
      reviewer_name: "Dr. Rupa Jha",
      location: "Mumbai",
    },
    {
      rating: 5,
      review: `“The multiple options from various Insurers under one roof was helpful and gave me the confidence that I am opting for the right product as required. Excellent support provided by your advisor.”`,
      reviewer_name: "Kunal Sharma",
      location: "Banglore",
    },
    {
      rating: 5,
      review: `“Renewing my Car insurance from Jio Insurance Broking was a great experience. Overall experience was really good and I was able to complete my transaction in just 5 minutes. I recommend everyone to buy from Jio Insurance Broking Limited.”`,
      reviewer_name: "Jamnadas Bhalani",
      location: "Delhi",
    },
    {
      rating: 4,
      review: `“Buying ICICI Lombard car insurance from Jio Insurance Broking is great. It just took me 5 minutes to buy a policy online. Very easy and user friendly. Will recommend it to everyone.”`,
      reviewer_name: "Vikas Priyadarshi",
      location: "Indore",
    },
    {
      rating: 4.5,
      review: `“The team handled my renewal query and process very smoothly. They also convinced me to go for a personal cover policy which I am happy to opt for. They are a good asset to the team.”`,
      reviewer_name: "Kunal Chavda",
      location: "Chandigarh",
    },
    {
      rating: 5,
      review: `“The online quote system was simple to use with clear options. The additional options are well laid out and explained on the website, so I knew exactly what I could buy and compare to what I want. Hence renewed my two-wheeler policy online without any trouble. Excellent website”`,
      reviewer_name: "Chirag Lukka",
      location: "Delhi",
    },
    {
      rating: 4.5,
      review: `“I renewed my health and motor policy on icicilombard.com and it was a seamless experience. On my previous policy, I had registered a claim last month and I was pleasantly surprised with the claim settlement process. It was extremely hassle free and quick.”`,
      reviewer_name: "Abhishek Gupta",
      location: "Mumbai",
    },
    {
      rating: 5,
      review: `“Extremely happy. Very satisfied with your express settlement of claims. My experience with you is unique as compared with others. I wish your company a very bright and prosperous future. THANKS ICICI LOMBARD”`,
      reviewer_name: "Leena Vaddella",
      location: "Delhi",
    },
  ];
  const partersLogos = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 320 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const partnersSlider = [
    {
      imgUrl:
        "../images/insurerlogo/ICICI-Lombard-General-Insurance-Co-Ltd.svg",
    },
    { imgUrl: "../images/insurerlogo/HDFC-ERGO-General-Insurance-Co-Ltd.svg" },
    {
      imgUrl:
        "../images/insurerlogo/Bajaj-Allianz-General-Insurance-Co-Ltd.svg",
    },
    {
      imgUrl: "../images/insurerlogo/Royal_Sundaram.svg",
    },
    {
      imgUrl: "../images/insurerlogo/digit.svg",
    },
    {
      imgUrl: "../images/insurerlogo/Tata-AIG-General-Insurance-Co-Ltd.svg",
    },
    // {
    //   imgUrl:
    //     "../images/insurerlogo/HDFC-ERGO-Health-Insurance-Company-Limited.svg",
    // },
    // {
    //   imgUrl:
    //     "../images/insurerlogo/Manipal-CignaTTK-Health-Insurance-Company-Limited.svg",
    // },
    // {
    //   imgUrl:
    //     "../images/insurerlogo/Religare-Health-Insurance-Company-Limited.svg",
    // },
    // {
    //   imgUrl:
    //     "../images/insurerlogo/Niva-Max-Bupa-Health-Insurance-Company-Ltd.svg",
    // },
    // {
    //   imgUrl: "../images/insurerlogo/aditya_birla_capital.svg",
    // },
    // {
    //   imgUrl:
    //     "../images/insurerlogo/Star-Health-and-Allied-Insurance-Company-Limited.svg",
    // },
    // {
    //   imgUrl:
    //     "../images/insurerlogo/Tata-AIA-Life-Insurance-Company-Limited.svg",
    // },
    // { imgUrl: "../images/insurerlogo/HDFC-Standard-Life-Insurance-Co-Ltd.svg" },
    // {
    //   imgUrl:
    //     "../images/insurerlogo/ICICI-Prudential-Life-Insurance-Co-Ltd.svg",
    // },
    // {
    //   imgUrl: "../images/insurerlogo/Max-Life-Insurance-Co-Ltd.svg",
    // },
  ];
  const addonCards = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 320 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const addonCardsArray = [
    {
      img_url: "../images/zerodep-cover.webp",
      addontitle: "Zero depreciation",
      addon_desc:
        "Two-Wheelers lose value as they get older and are ridden. Plans for two-wheeler insurance do not cover this depreciation. When a claim is made, the insurance provider withholds the appropriate depreciation on the two-wheeler's various components before paying the claim. However, while having repairs done, you pay the actual cost of the parts. You receive a lesser claim amount and incur out-of-pocket costs when depreciation is subtracted. Therefore, a zero depreciation add-on is helpful. Depreciation on the two-wheeler's component parts is not taken into account when filing a claim under this add-on. In spite of any depreciation, the insurance company covers the real price of repairs. Your out-of-pocket costs are consequently decreased.",
    },
    {
      img_url: "../images/roadside-assistance.webp",
      addontitle: "Roadside Assistance",
      addon_desc:
        "A roadside help add-on comes in handy if your two-wheeler breaks down in the middle of the road and cannot be transported to the closest garage. If there is a breakdown, this add-on offers you support around-the-clock. The insurance provider only needs to be notified, and help is sent wherever you are. There is help available for flat tires, gasoline leaks, electrical problems, jump-starting the two-wheeler, etc.",
    },
    {
      img_url: "../images/returnto-invoice.webp",
      addontitle: "Add-on NCB protection",
      addon_desc:
        "If you don't file a claim under your two-wheeler insurance policy during the course of the policy year, you receive a no claim bonus. This bonus entitles you to a discount on the renewal premium.Additionally, the bonus grows with each succeeding year without a claim. The entire no-claim incentive, however, is forfeited in the event of a claim. This add-on guards against losing the no-claims bonus. Even after you file a claim with your two-wheeler insurance policy, it maintains the NCB.",
    },
    {
      img_url: "../images/bike-key-cover.webp",
      addontitle: "Lost key replacement",
      addon_desc:
        "This add-on would pay the price of getting a new key if you misplace the ones for your two-wheeler.",
    },
    // {
    //   img_url: "../images/returnto-invoice.webp",
    //   addontitle: "Additional medical costs",
    //   addon_desc:
    //     "Under this add-on, the medical expenses which you face if you are hospitalised following a two-wheeler accident are covered.",
    // },
    {
      img_url: "../images/bike-pa-cover.webp",
      addontitle: "Personal accident cover for the pillion rider",
      addon_desc:
        "Only the two-wheeler's owner or driver is required to have personal accident coverage under a two-wheeler insurance policy. The pillion rider does not have access to the cover. However, the pillion rider can also purchase a personal accident policy with this add-on.",
    },
    {
      img_url: "../images/consumable-cover.webp",
      addontitle: "Consumables cover",
      addon_desc:
        "The expenditures associated with consumables are not covered by the two-wheeler insurance coverage in the event that the two-wheeler needs to be repaired. These consumables, which the mechanic uses to fix the two-wheeler, include engine oil, nuts, bolts, screws, lubricants, etc. However, the consumables cover add-ons make sure that the two-wheeler insurance policy also covers the cost of consumables.",
    },
    {
      img_url: "../images/returnto-invoice.webp",
      addontitle: "Return to invoice cover",
      addon_desc:
        "The two-wheeler insurance policy pays the insured declared worth of the two-wheeler, which is the market value of the two-wheeler after depreciation, if the two-wheeler is stolen or beyond economical repair. However, if this add-on is chosen, the claim would be paid out at the two-wheeler's invoice value.",
    },
  ];

  const faqs = [
    {
      title: "What is a Two-Wheeler comprehensive insurance policy?",
      desc: "Protection from damage to both your own and third-party vehicles is offered by a comprehensive plan. In addition to accidents, it also covers theft and damage to the vehicle caused by any type of natural disaster, such as floods or storms, as well as human-made events, such as rioting and vandalism. The law mandates the purchase of third-party insurance, although experts advise Two-Wheeler owners to choose a complete policy for greater coverage.",
    },
    {
      title: "What is the meaning of zero depreciation two-wheeler insurance?",
      desc: "Zero Depreciation Cover is an addition to your current policy. Over time, the Two-Wheeler loses some of its value. The depreciation rate is what causes the decreased market value. A brand-new Two-Wheeler loses 5–10% of its value the instance it leaves the showroom because the next buyer will be purchasing a used Two-Wheeler. Therefore, even if you choose comprehensive insurance coverage, the claim money you will receive if your two-wheeler is stolen or completely destroyed will be based on the depreciated value of the Two-Wheeler's components. For instance, you would receive the latter if the depreciated worth of your Rs. 90,000 Two-Wheeler is Rs. 60,000. However, you will receive Rs. 90,000 if you have Zero Depreciation Cover.",
    },
    {
      title:
        "How does the emergency assistance program work under two-wheeler insurance?",
      desc: "You receive round-the-clock help to minimize any technical or mechanical breakdown problems if you choose Emergency Assistance Cover. This extra advantage includes on-site basic repairs, flat tires, battery jump starts, tank filling, lost key help, duplicate key issues, and even towing fees up to 100 kilometers from your registered home. In some situations, if the policyholder needs somewhere to stay while the Two-Wheeler is being repaired, the insurer will additionally cover the costs of lodging.",
    },
    {
      title:
        "How do I get a copy of my two-wheeler insurance policy online? Will the printout of the soft copy serve as the original document?",
      desc: "Visit the insurance company's official website. Enter your policy number and any other necessary information after choosing the appropriate policy type from the list of insurance policies. Download and print the policy paper once you have it. Get in touch with your insurance company if you can't discover the policy number. The Digilocker or mParivahan mobile app-stored digital copies of documents, including a driver's licence and a Two-Wheeler's registration, insurance, and more, are accepted legally, according to the Ministry of Road Transport and Highways. Original documents are no longer required, only photocopies.",
    },
    {
      title:
        "What are the documents required to buy two-wheeler insurance online?",
      desc: "Documents needed to purchase Two-Wheeler insurance online include proof of identity (driver's licence, passport, Aadhar card, PAN card, government-issued ID card), proof of address (bank or post office passbook, government-issued address proof), recent passport-size photo, a valid driver's licence, Two-Wheeler registration certificate, and information for net banking, credit cards, debit cards, and UPI/wallets.",
    },
    {
      title: "Which online two-wheeler insurance is best for two-wheelers?",
      desc: "The ideal insurance plan is the one that offers you the greatest advantages at the lowest cost. To choose which plan best meets your needs, you can compare options. Although you don't have to go to the insurer's office or buy a policy from a licensed insurance agent, purchasing an insurance policy online is quicker and easier. Due to the insurance company's ability to reduce agent commission costs and pass those savings along to you, the online approach will enable you to receive some reductions.",
    },
  ];

  const boxCardsArray = [
    {
      img_url: "../images/comprehensive-icon.svg",
      cardtitle: "Comprehensive",
      desc: "Comprehensive vehicle insurance covering theft, damage, third-party liabilities, and natural calamities. Includes Own Damage (OD) and Third-Party Liability (TP) for complete protection.",
    },
    {
      img_url: "../images/tp-icon.svg",
      cardtitle: "Third-party",
      desc: "Mandatory motor insurance covering third-party damages and legal liabilities from accidents involving the insured vehicle. Provides compensation for injuries, death, and property damage.",
    },
    {
      img_url: "../images/od-icon.svg",
      cardtitle: "Own Damage",
      desc: "Manage costly vehicle repairs with Standalone Own Damage (SOD) insurance, covering repairs and replacements due to accidents, theft, or fire.",
    },
  ];

  const { TW } = useAppSelector((state) => state);
  const [loader, setLoader] = useState<boolean>(false);
  const [openTWForm, setOpenTWForm] = useState(false);
  const [pageStatus, setPageStatus] = useState<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8>(
    1
  );
  const [openTWPrefilledData, setOpenTWPrefilledData] = useState(false);
  const continuePrefilledTW = () => {
    setOpenTWPrefilledData(false);
    setOpenTWForm(true);
    setPageStatus(6);
    pushDataLayer(
      "Jioinsure_flow ",
      "TW details",
      `Continue`,
      TW.REDIRECTED ? "JFS app" : "Insure website"
    );
    const eventProps = {
      action: "two wheeler details",
      click: "Continue",
      EntrySource: TW.REDIRECTED ? "JFS app" : "Insure website",
    };
    sendEventToNativeApp("Jioinsure_flow", eventProps);
  };
  const denyPrefilledTW = () => {
    dispatch(
      TWSlice.actions.BULK_UPDATE({
        ...TW,
        ADD_FORM: {
          ...TW.ADD_FORM,
          make: { value: "", warning: false },
          model: { value: "", warning: false },
          fuel_type: { value: "", warning: false },
          variant_cc: { value: "", warning: false },
        },
      })
    );

    setOpenTWPrefilledData(false);
    setOpenTWForm(true);
    setPageStatus(2);
    pushDataLayer(
      "Jioinsure_flow ",
      "TW details",
      `edit`,
      TW.REDIRECTED ? "JFS app" : "Insure website"
    );
    const eventProps = {
      action: "two wheeler details",
      click: "edit",
      EntrySource: TW.REDIRECTED ? "JFS app" : "Insure website",
    };
    sendEventToNativeApp("Jioinsure_flow", eventProps);
  };
  // const GET_VEHICLE_INFO_TW = () => {
  //   const onSuccess = (res: any) => {
  //     setLoader(false);

  //     const results = res?.data;
  //     const response = results?.response;
  //     const error = results?.error;

  //     const make = response?.make;
  //     const model = response?.model;
  //     const make_model = response?.make_model;
  //     const variant_cc = `${response?.variant_cc}`;
  //     const fuel_type = response?.fuel_type;
  //     const registration_date = response?.registration_date;
  //     const policy_expiry_date = response?.policy_expiry_date;

  //     if (
  //       !isEmpty(make) &&
  //       !isEmpty(make_model) &&
  //       !isEmpty(variant_cc) &&
  //       !isEmpty(fuel_type)
  //     ) {
  //       dispatch(
  //         TWSlice.actions.BULK_UPDATE({
  //           ...TW,
  //           ADD_FORM: {
  //             ...TW.ADD_FORM,
  //             make: { value: make, warning: false },
  //             model: { value: model, warning: false },
  //             make_model: { value: make_model, warning: false },
  //             variant_cc: { value: variant_cc, warning: false },
  //             fuel_type: { value: fuel_type, warning: false },
  //             reg_date: {
  //               value: FORMAT_DD_MM_YYYY(registration_date),
  //               warning: false,
  //             },
  //           },
  //         })
  //       );

  //       setOpenTWPrefilledData(true);
  //     } else {
  //       denyPrefilledTW();
  //     }
  //   };
  //   const onError = () => {
  //     setLoader(false);
  //   };

  //   setLoader(true);
  //   TW_ADD_FORM_SERVICES.GET_VEHICLE_NO_INFO(
  //     onError,
  //     onSuccess,
  //     TW.ADD_FORM.reg_no.value
  //   );
  // };

  const GET_VEHICLE_INFO_TW = () => {
    const onSuccess = (res: any) => {
      setLoader(false);
      const results = res?.data;
      const response = results?.response;
      const error = res?.data?.error;

      if (error) {
        dispatch(
          TWSlice.actions.UPDATE_FORM_DATA([
            {
              key: "reg_no",
              value: { value: TW.ADD_FORM.reg_no.value, warning: true },
            },
          ])
        );
      } else {
        const make = response?.make;
        const model = response?.model;
        const make_model = response?.make_model;
        const variant_cc = `${response?.variant_cc}`;
        const fuel_type = response?.fuel_type;
        const registration_date = response?.registration_date;

        if (
          !isEmpty(make) &&
          !isEmpty(model) &&
          !isEmpty(variant_cc) &&
          !isEmpty(fuel_type)
        ) {
          dispatch(
            TWSlice.actions.BULK_UPDATE({
              ...TW,
              ADD_FORM: {
                ...TW.ADD_FORM,
                make: { value: make, warning: false },
                model: { value: model, warning: false },
                make_model: { value: make_model, warning: false },
                variant_cc: { value: variant_cc, warning: false },
                fuel_type: { value: fuel_type, warning: false },
                reg_date: {
                  value: FORMAT_DD_MM_YYYY(registration_date),
                  warning: false,
                },
              },
            })
          );

          setOpenTWPrefilledData(true);
        } else {
          denyPrefilledTW();
        }
      }
    };
    const onError = () => {
      setLoader(false);
    };

    setLoader(true);
    pushDataLayer(
      "jioinsure_home_cards",
      "two wheeler insurance",
      "Get Free Quotes",
      "Insure website"
    );
    const eventProps = {
      action: "two wheeler insurance",
      click: "Get free quotes",
      EntrySource: "Insure website",
    };
    sendEventToNativeApp("jioinsure_home_cards", eventProps);
    TW_ADD_FORM_SERVICES.GET_VEHICLE_NO_INFO(
      onSuccess,
      onError,
      TW.ADD_FORM.reg_no.value
    );
  };

  const validateTWRollover = () => {
    let data: TTWForm = { ...TW.ADD_FORM };
    data = {
      ...data,
      reg_no: {
        ...data.reg_no,
        warning: !validateRegno(`${data.reg_no.value}`),
      },
      mobile: {
        ...data.mobile,
        warning: !validateMobileNumber(data.mobile.value),
      },
      // email: {
      //   ...data.email,
      //   warning: !validateEmail(data.email.value),
      // },
    };

    if (!data.reg_no.warning && !data.mobile.warning) {
      pushDataLayer(
        "jioinsure_products_internal",
        "Two wheeler Insurance",
        `Get free quotes`,
        `${redirected ? "JFS app" : "Insure website"}`
      );
      const eventProps = {
        action: "Two wheeler Insurance",
        click: "Get free quotes",
        EntrySource: TW.REDIRECTED ? "JFS app" : "Insure website",
      };
      sendEventToNativeApp("jioinsure_products_internal", eventProps);
      GET_VEHICLE_INFO_TW();
    }
    dispatch(
      TWSlice.actions.BULK_UPDATE({
        ...TW,
        ADD_FORM: {
          ...TWSlice.getInitialState().ADD_FORM,
          ...data,
          business_type: "Rollover",
          mobile: data.mobile,
          // email: data.email,
          reg_no: data.reg_no,
        },
      })
    );
  };

  const [redirected, setRedirected] = useState<boolean>(false);

  useEffect(() => {
    changeHeaderFooterVisibility(0, (response) => {
      console.log("Native app response:", response);
    });

    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const token = params.get("token");

    if (token) {
      verifyJwt(token).then((result) => {
        if (!isEmpty(result?.payload?.data)) {
          const mobile_no = result?.payload?.data;
          setRedirected(true);
          dispatch(CarSlice.actions.BULK_UPDATE(CarSlice.getInitialState()));
          dispatch(
            HealthSlice.actions.BULK_UPLOAD(HealthSlice.getInitialState())
          );
          dispatch(TermSlice.actions.BULK_UPDATE(TermSlice.getInitialState()));
          dispatch(
            TWSlice.actions.BULK_UPDATE({
              ...TWSlice.getInitialState(),
              ADD_FORM: {
                ...TWSlice.getInitialState().ADD_FORM,
                mobile: { value: mobile_no.slice(2), warning: false },
              },
              REDIRECTED: true,
            })
          );
        } else {
          setRedirected(false);
          dispatch(
            TWSlice.actions.BULK_UPDATE({ ...TWSlice.getInitialState() })
          );
        }
      });
    }
  }, []);

  const [openCancellationPopup, setOpenCancellationPopup] = useState(false);

  // open the ExitIntentPopup after every 2 min
  const handleClosePopup = () => {
    setOpenCancellationPopup(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setOpenCancellationPopup(true);
    }, 120000); // 2 minutes

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      // Open the ExitIntentPopup
      setOpenCancellationPopup(true);

      // Custom message for browsers to show (some browsers ignore this)
      event.preventDefault();
      event.returnValue = ""; // Required for some browsers to show the alert
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <>
      <HelmetComponent  name="robots" description="noindex" />
      <Box className={`${styles.cmsWrapper} ${styles.sectionSpacing}`}>
        <ExitIntentPopup
          open={openCancellationPopup}
          setOpen={handleClosePopup}
          type="TW"
        />
        {openTWForm ? (
          <TWFormContainer
            open={openTWForm}
            setOpen={setOpenTWForm}
            pageStatus={pageStatus}
            setPageStatus={setPageStatus}
          />
        ) : null}
        {isMobile ? (
          <MTWPreFilledData
            open={openTWPrefilledData}
            setOpen={setOpenTWPrefilledData}
            continuePrefilledTW={continuePrefilledTW}
            denyPrefilledTW={denyPrefilledTW}
          />
        ) : (
          <TWPrefilledData
            open={openTWPrefilledData}
            setOpen={setOpenTWPrefilledData}
            continuePrefilledTW={continuePrefilledTW}
            denyPrefilledTW={denyPrefilledTW}
          />
        )}

        <AddonPopup
          open={openAddonPopup}
          setOpen={setOpenAddonPopup}
          data={dataAddonPopup}
        />

        {isMobile ? <LandingMNavBar /> : <LandingNavBar />}

        <Box className="heroBanner">
          <img
            alt=""
            src="../images/bike-banner.webp"
            width="100%"
            className="bgimg"
          />
          <Box className="innerWrapper">
            <Grid container spacing={2} alignItems="center">
              <Grid
                size={{ xs: 12, md: 7 }}
                display="flex"
                gap="16px"
                flexDirection="column"
                sx={{ color: COLORS.white }}
                className="contentBox"
              >
                <h1>Two-Wheeler Insurance</h1>
                <h2>Buy two-wheeler insurance and save up to 80%*</h2>
                <Box>
                  <p>
                    <img src="../images/green-tick.svg" width="32px" />
                    Affordable plans
                  </p>
                  <p>
                    <img src="../images/green-tick.svg" width="32px" />
                    Cashless claims
                  </p>
                  <p>
                    <img src="../images/green-tick.svg" width="32px" />
                    Zero paperwork
                  </p>
                </Box>
              </Grid>
              <Grid size={{ xs: 12, md: 5 }}>
                <Box className="formBox">
                  <h3>Get your two-wheeler insurance in just a few clicks</h3>

                  <TextInputField
                    title="Enter two-wheeler registration number"
                    class_name=""
                    value={TW.ADD_FORM.reg_no.value}
                    attrName={"reg_no"}
                    value_update={(a: any, v: any) => {
                      dispatch(
                        TWSlice.actions.UPDATE_FORM_DATA([
                          {
                            key: "reg_no",
                            value: { value: v, warning: !validateRegno(v) },
                          },
                        ])
                      );
                    }}
                    warn_status={TW.ADD_FORM.reg_no.warning}
                    // error_message={
                    //   !isEmpty(TW.ADD_FORM.reg_no.value)
                    //     ? "Enter Valid Vehicle number"
                    //     : "Enter Vehicle number"
                    // }
                    error_message={
                      isEmpty(TW.ADD_FORM.reg_no.value)
                        ? "Enter Vehicle number"
                        : "Vehicle registration number is not valid"
                    }
                    placeholder={"Eg: MH00XX0000"}
                    validation_type="ALPHANUMERIC"
                    max_length={11}
                  />

                  <Box className="mobileNumber">
                    <Box
                      position="absolute"
                      left="12px"
                      bottom="13px"
                      zIndex="2"
                      fontSize="18px"
                      fontWeight="bold"
                      sx={{ color: COLORS.lightgrey }}
                    >
                      +91
                    </Box>
                    <TextInputField
                      disabled={redirected}
                      title="Enter your mobile number"
                      placeholder="XXXXXXXXXX"
                      validation_type="NUMBER"
                      value={TW.ADD_FORM.mobile.value}
                      attrName={"mobile"}
                      value_update={(a: any, v: any) => {
                        dispatch(
                          TWSlice.actions.UPDATE_FORM_DATA([
                            {
                              key: "mobile",
                              value: {
                                value: v,
                                warning: !validateMobileNumber(v),
                              },
                            },
                          ])
                        );
                      }}
                      warn_status={TW.ADD_FORM.mobile.warning}
                      error_message={
                        isEmpty(TW.ADD_FORM.mobile.value)
                          ? "Enter Mobile Number"
                          : "Enter valid mobile number"
                      }
                      max_length={10}
                    />
                  </Box>

                  {/* <Box>
                    <TextInputField
                      title="Enter your email"
                      placeholder="Eg: john.smith@email.com"
                      value={TW.ADD_FORM.email?.value}
                      attrName={"email"}
                      value_update={(a: any, v: any) => {
                        dispatch(
                          TWSlice.actions.UPDATE_FORM_DATA([
                            {
                              key: "email",
                              value: {
                                value: v,
                                warning: !validateEmail(v),
                              },
                            },
                          ])
                        );
                      }}
                      warn_status={TW.ADD_FORM.email?.warning}
                      error_message={
                        isEmpty(TW.ADD_FORM.email?.value)
                          ? "Enter E-mail"
                          : "Enter valid email"
                      }
                      max_length={50}
                    />
                  </Box> */}

                  <CustomCheckbox
                    label={
                      <>
                        I agree to{" "}
                        <Link
                          style={{
                            color: COLORS.primary,
                            fontWeight: "bold",
                          }}
                          onClick={() => {
                            // navigate(COMMON_ROUTES.TERM_AND_CONDTION);
                            window.open("/pdfs/terms_of_use.pdf", "_blank");
                          }}
                        >
                          Terms & Conditions
                        </Link>
                        ,{" "}
                        <Link
                          style={{
                            color: COLORS.primary,
                            fontWeight: "bold",
                          }}
                          onClick={() => {
                            // navigate(COMMON_ROUTES.PRIVACY_POLICY);
                            window.open("/pdfs/privacy_policy.pdf", "_blank");
                          }}
                        >
                          Privacy Policy
                        </Link>
                        {` and `}
                        <Link
                          style={{
                            color: COLORS.primary,
                            fontWeight: "bold",
                          }}
                          onClick={() => {
                            // navigate(COMMON_ROUTES.DISCLAIMER);

                            window.open("/pdfs/legal_disclaimer.pdf", "_blank");
                          }}
                        >
                          Disclaimer
                        </Link>
                      </>
                    }
                    attrName="termsandconditions"
                    value={TW.ADD_FORM.term_cond}
                    defaultChecked={TW.ADD_FORM.term_cond}
                    variant="large"
                    value_update={(attrName: string, value: boolean) => {
                      dispatch(
                        TWSlice.actions.UPDATE_FORM_DATA([
                          {
                            key: "term_cond",
                            value: value,
                          },
                        ])
                      );
                    }}
                  />
                  <Grid container rowSpacing={2} columnSpacing={0}>
                    <Grid size={{ xs: 12, md: 6 }} order={{ xs: 2, md: 1 }}>
                      <JDSButtons
                        className="secondaryBtns large"
                        text="Bought a new two-wheeler?"
                        variant="text"
                        fullWidth={true}
                        onClick={() => {
                          dispatch(
                            TWSlice.actions.BULK_UPDATE({
                              ...TW,
                              ADD_FORM: {
                                ...TWSlice.getInitialState().ADD_FORM,
                                business_type: "New",
                                mobile: TW.ADD_FORM.mobile,
                                reg_no: TW.ADD_FORM.reg_no,
                              },
                            })
                          );
                          setPageStatus(1);
                          if (TW.ADD_FORM.term_cond) {
                            pushDataLayer(
                              "jioinsure_products_internal",
                              "Two wheeler Insurance",
                              `Bought a new Two wheeler`,
                              `${redirected ? "JFS app" : "Insure website"}`
                            );
                            setOpenTWForm(true);
                          } else {
                            toast.error("Please accept terms and conditions");
                          }
                        }}
                      />
                    </Grid>
                    <Grid size={{ xs: 12, md: 6 }} order={{ xs: 1, md: 2 }}>
                      <JDSButtons
                        onClick={() => {
                          if (TW.ADD_FORM.term_cond) {
                            validateTWRollover();
                          } else {
                            toast.error("Please accept terms and conditions");
                          }
                        }}
                        className="primaryBtns large"
                        text="Get free quotes"
                        variant="text"
                        loader={loader}
                        fullWidth={true}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box className="lightGoldBg px-3">
          <Grid container rowSpacing={3} columnSpacing={2}>
            <Grid size={12}>
              <h2 className="mb-4">
                Two-wheeler insurance plans that suits your needs
              </h2>
            </Grid>
            {boxCardsArray.map((data, index) => (
              <Grid size={{ xs: 12, md: 4 }} display="flex">
                <Box className="cardBox">
                  <Box>
                    <img src={data.img_url} />

                    <h3>{data.cardtitle}</h3>
                  </Box>
                  <h5 style={{ color: COLORS.lightgrey }}>{data.desc}</h5>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Partners */}
        <Box className="partners_section">
          <Grid
            container
            columnSpacing={{ xs: 6, md: 3 }}
            marginBottom={"42px"}
          >
            <Grid size={12}>
              <h2 style={{ color: "#001E2B" }}>Our Partners</h2>
            </Grid>
          </Grid>
          <Grid
            container
            columnSpacing={{ xs: 6, md: 3 }}
            justifyContent={"center"}
          >
            <Grid size={12} className="partners-carousel">
              <Carousel
                responsive={partersLogos}
                autoPlay={true}
                swipeable={true}
                draggable={true}
                showDots={true}
                infinite={true}
                partialVisible={false}
                removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                containerClass={`custom-carousel-track customCarouselTrack`}
                customTransition="transform 0.5s ease-in-out"
              >
                {partnersSlider.map((data, index) => {
                  return (
                    <Box className="partner_box">
                      <img src={data.imgUrl} alt="" />
                    </Box>
                  );
                })}
              </Carousel>
            </Grid>
          </Grid>
        </Box>

        {/* Add-ons */}
        <Box className="addonSection">
          <Grid container columnSpacing={{ xs: 6, md: 3 }}>
            <Grid size={12} textAlign={"center"}>
              <h2>Add-ons in a two-wheeler insurance policy</h2>
              <h5
                style={{
                  color: "#595959",
                  maxWidth: "1016px",
                  margin: "24px auto 0px",
                }}
              >
                There are various add-ons that are offered with comprehensive
                two-wheeler insurance plans. These add-ons are optional extra
                coverage benefits that policyholders can choose by paying an
                extra premium for. The following are some of the most popular
                and frequent add-ons included in two-wheeler insurance plans:
              </h5>
            </Grid>

            <Grid size={12} className="mt-12">
              <Carousel
                responsive={addonCards}
                autoPlay={true}
                swipeable={true}
                draggable={true}
                showDots={true}
                infinite={true}
                partialVisible={false}
                removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                containerClass={`custom-carousel-track customCarouselTrack`}
                customTransition="transform 0.5s ease-in-out"
              >
                {addonCardsArray.map((data, index) => (
                  <AddonCard
                    data={data}
                    setOpenAddonPopup={setOpenAddonPopup}
                    setDataAddonPopup={setDataAddonPopup}
                  />
                ))}
              </Carousel>
            </Grid>
          </Grid>
        </Box>

        {/* Why Jio Advantage */}
        <Box className="why_jio_section">
          <Grid container className="row" columnSpacing={3} alignItems="center">
            <Grid size={12}>
              {/* <h4
              style={{
                color: COLORS.lightgrey,
                fontWeight: 700,
              }}
            >
              The Jio Advantage
            </h4> */}
              <h2>Why Jio Insurance?</h2>
              <h5
                style={{
                  color: "#595959",
                  maxWidth: "824px",
                }}
              >
                At Jio Insurance Broking, compare different two-wheeler
                insurance plans and select the one that fits your requirements.
              </h5>
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <img
                src="../images/jio-advantage.webp"
                width="100%"
                style={{ borderRadius: "24px" }}
                alt="img"
              />
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <ul className="listView">
                {whySectionArray?.map((data, index) => (
                  <li key={index}>
                    <img src={data.iconUrl} alt="icon" />
                    <Box>
                      <h5
                        className="mb-1 mt-0"
                        style={{
                          color: COLORS.darkgrey,
                          fontWeight: "bold",
                        }}
                      >
                        {data.heading}
                      </h5>
                      <p
                        style={{
                          color: COLORS.lightgrey,
                        }}
                      >
                        {data.desc}
                      </p>
                    </Box>
                  </li>
                ))}
              </ul>
            </Grid>
          </Grid>
        </Box>

        {/* Testimonial */}
        <Box className="testimonial_section">
          <Grid container className="row" columnSpacing={3}>
            <Grid size={12}>
              <h2 style={{ maxWidth: "520px", margin: "0px auto" }}>
                Meet some of our happy customers
              </h2>
              <h5 className="mt-2" style={{ color: COLORS.lightgrey }}>
                Words from our satisfied customers
              </h5>
            </Grid>
          </Grid>
          <Grid container className="row" columnSpacing={0}>
            <Grid size={12} className="testimonial-carousel">
              <Carousel
                responsive={responsive}
                autoPlay={true}
                swipeable={true}
                draggable={true}
                showDots={true}
                infinite={true}
                partialVisible={false}
                removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                containerClass={`custom-carousel-track customCarouselTrack`}
                customTransition="transform 0.5s ease-in-out"
              >
                {testimonialsSlider.map((data, index) => (
                  <TestimonialCard data={data} />
                ))}
              </Carousel>
            </Grid>
          </Grid>
        </Box>

        {/* faqs */}
        <Grid
          container
          className="row"
          rowSpacing={3}
          columnSpacing={6}
          justifyContent={"center"}
        >
          <Grid size={12} className="textCenter">
            <h2>Frequently Asked Questions</h2>
          </Grid>
          <Grid size={12}>
            <FAQ data={showAll ? faqs : faqs.slice(0, defaultShow)} />
          </Grid>
          <Grid size={12}>
            <JDSButtons
              onClick={() => setShowAll(!showAll)}
              className="secondaryBtns large"
              text={showAll ? "View less" : "View more"}
              variant="outlined"
              fullWidth={false}
              // loader="true"
            />
          </Grid>
        </Grid>

        {/* <Grid container rowSpacing={3} columnSpacing={{ xs: 6, md: 3 }}>
          <Grid size={{ xs: 12, md: 6 }}>
            <img
              src="../images/two-wheeler-insurance.webp"
              style={{ borderRadius: "24px" }}
              width="100%"
              alt=""
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <h3 className="mb-4 mt-6">What is two-wheeler insurance?</h3>
            <h5 style={{ color: COLORS.lightgrey }}>
              Two-wheeler insurance is a contract between the owner of the
              two-wheeler and the insurance company that covers the owner
              financially against any losses or damages resulting from an
              accident. Two-wheeler third-party insurance is required in India
              in accordance with the Motor Vehicle Act of 1988.
            </h5>
          </Grid>
        </Grid> */}

        {/* top products */}
        {/* {isMobile ? (
        <Grid container className="row" rowSpacing={3} columnSpacing={6}>
          <Grid size={12} className="textCenter">
            <p
              style={{
                textTransform: "uppercase",
                lineHeight: "16px",
                marginBottom: "4px",
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              Top picks
            </p>
            <h3>Trending Two-Wheeler Insurances</h3>
          </Grid>
          <Grid size={12}>
            <Box className="slickSlider">
              <Slider {...trendingProductssettings}>
                <Box sx={{ padding: "0px 12px", marginBottom: "8px" }}>
                  <Box
                    sx={{
                      boxShadow: "0px 0px 4px rgba(0,0,0,0.25)",
                      padding: "16px",
                      borderRadius: "24px",
                      marginTop: "2px",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid
                        size={12}
                        display="flex"
                        gap="8px"
                        alignItems="center"
                      >
                        <img
                          src="./images/insurerlogo/ICICI-Lombard-General-Insurance-Co-Ltd.svg"
                          height="60px"
                          alt="insurer"
                        />
                        <p>ICICI Lombard</p>
                      </Grid>
                      <Grid
                        size={12}
                        sx={{
                          display: "flex",
                          gap: "24px",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            fontWeight: "bold",
                            minWidth: "65px",
                          }}
                        >
                          Add-ons
                        </p>
                        <span
                          style={{
                            width: "100%",
                            height: "1px",
                            backgroundColor: "#FEF7E9",
                          }}
                        />
                        <p
                          style={{
                            padding: "4px 6px",
                            background: "rgba(12, 82, 115, 1)",
                            color: "#fff",
                            borderRadius: "4px",
                            minWidth: "80px",
                            fontSize: "10px",
                          }}
                        >
                          Comprehensive
                        </p>
                      </Grid>
                      <Grid size={7}>
                        <ul
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                            marginBottom: "8px",
                          }}
                        >
                          <li
                            style={{
                              listStyleType: "none",
                              fontSize: "12px",
                              display: "flex",
                              gap: "6px",
                            }}
                          >
                            <img
                              src="../images/tick-icon.svg"
                              style={{
                                height: "14px",
                                width: "14px",
                                marginTop: "1px",
                              }}
                            />
                            24*7 Roadside Assistance
                          </li>
                          <li
                            style={{
                              listStyleType: "none",
                              fontSize: "12px",
                              display: "flex",
                              gap: "6px",
                            }}
                          >
                            <img
                              src="../images/tick-icon.svg"
                              style={{
                                height: "14px",
                                width: "14px",
                                marginTop: "1px",
                              }}
                            />
                            Zero Depriciation
                          </li>
                          <li
                            style={{
                              listStyleType: "none",
                              fontSize: "12px",
                              display: "flex",
                              gap: "6px",
                            }}
                          >
                            <img
                              src="./images/tick-icon.svg"
                              style={{
                                height: "14px",
                                width: "14px",
                                marginTop: "1px",
                              }}
                            />
                            Driver Cover
                          </li>
                        </ul>
                        <JDSButtons
                          text={"View all features"}
                          variant={"text"}
                          className={"tertiaryBtn small"}
                          fullWidth={false}
                          onClick={() => window.scrollTo(0, 0)}
                        />
                      </Grid>
                      <Grid size={5} textAlign="right">
                        <p>
                          <small style={{ color: "#595959" }}>
                            Claims Settled
                          </small>
                        </p>
                        <h6
                          style={{ fontWeight: "bold", color: COLORS.primary }}
                        >
                          96.75%
                        </h6>
                      </Grid>
                      <Grid size={12} textAlign="right">
                        <Box
                          sx={{
                            width: "100%",
                            height: "1px",
                            backgroundColor: "#FEF7E9",
                            marginBottom: "16px",
                          }}
                        />
                        <JDSButtons
                          text={
                            <>
                              ₹ 2,045
                              <KeyboardArrowRightRoundedIcon className="ml-1" />
                            </>
                          }
                          variant={"text"}
                          className={"primaryBtns small"}
                          fullWidth={false}
                          onClick={() => {
                            window.scrollTo(0, 0);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box sx={{ padding: "0px 12px", marginBottom: "8px" }}>
                  <Box
                    sx={{
                      boxShadow: "0px 0px 4px rgba(0,0,0,0.25)",
                      padding: "16px",
                      borderRadius: "24px",
                      marginTop: "2px",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid
                        size={12}
                        display="flex"
                        gap="8px"
                        alignItems="center"
                      >
                        <img
                          src="./images/insurerlogo/HDFC-ERGO-General-Insurance-Co-Ltd.svg"
                          height="60px"
                          alt="insurer"
                        />
                        <p>HDFC ERGO</p>
                      </Grid>
                      <Grid
                        size={12}
                        sx={{
                          display: "flex",
                          gap: "24px",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            fontWeight: "bold",
                            minWidth: "65px",
                          }}
                        >
                          Add-ons
                        </p>
                        <span
                          style={{
                            width: "100%",
                            height: "1px",
                            backgroundColor: "#FEF7E9",
                          }}
                        />
                        <p
                          style={{
                            padding: "4px 6px",
                            background: "rgba(12, 82, 115, 1)",
                            color: "#fff",
                            borderRadius: "4px",
                            minWidth: "80px",
                            fontSize: "10px",
                          }}
                        >
                          Comprehensive
                        </p>
                      </Grid>
                      <Grid size={7}>
                        <ul
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                            marginBottom: "8px",
                          }}
                        >
                          <li
                            style={{
                              listStyleType: "none",
                              fontSize: "12px",
                              display: "flex",
                              gap: "6px",
                            }}
                          >
                            <img
                              src="../images/tick-icon.svg"
                              style={{
                                height: "14px",
                                width: "14px",
                                marginTop: "1px",
                              }}
                            />
                            24*7 Roadside Assistance
                          </li>
                          <li
                            style={{
                              listStyleType: "none",
                              fontSize: "12px",
                              display: "flex",
                              gap: "6px",
                            }}
                          >
                            <img
                              src="../images/tick-icon.svg"
                              style={{
                                height: "14px",
                                width: "14px",
                                marginTop: "1px",
                              }}
                            />
                            Driver Cover
                          </li>
                          <li
                            style={{
                              listStyleType: "none",
                              fontSize: "12px",
                              display: "flex",
                              gap: "6px",
                            }}
                          >
                            <img
                              src="./images/tick-icon.svg"
                              style={{
                                height: "14px",
                                width: "14px",
                                marginTop: "1px",
                              }}
                            />
                            Zero Depriciation
                          </li>
                        </ul>
                        <JDSButtons
                          text={"View all features"}
                          variant={"text"}
                          className={"tertiaryBtn small"}
                          fullWidth={false}
                          onClick={() => true}
                        />
                      </Grid>
                      <Grid size={5} textAlign="right">
                        <p>
                          <small style={{ color: "#595959" }}>
                            Claims Settled
                          </small>
                        </p>
                        <h6
                          style={{ fontWeight: "bold", color: COLORS.primary }}
                        >
                          98%
                        </h6>
                      </Grid>
                      <Grid size={12} textAlign="right">
                        <Box
                          sx={{
                            width: "100%",
                            height: "1px",
                            backgroundColor: "#FEF7E9",
                            marginBottom: "16px",
                          }}
                        />
                        <JDSButtons
                          text={
                            <>
                              ₹ 2,445
                              <KeyboardArrowRightRoundedIcon className="ml-1" />
                            </>
                          }
                          variant={"text"}
                          className={"primaryBtns small"}
                          fullWidth={false}
                          onClick={() => {
                            window.scrollTo(0, 0);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box sx={{ padding: "0px 12px", marginBottom: "8px" }}>
                  <Box
                    sx={{
                      boxShadow: "0px 0px 4px rgba(0,0,0,0.25)",
                      padding: "16px",
                      borderRadius: "24px",
                      marginTop: "2px",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid
                        size={12}
                        display="flex"
                        gap="8px"
                        alignItems="center"
                      >
                        <img
                          src="./images/insurerlogo/digit.svg"
                          height="60px"
                          alt="insurer"
                        />
                        <p>Go Digit</p>
                      </Grid>
                      <Grid
                        size={12}
                        sx={{
                          display: "flex",
                          gap: "24px",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            fontWeight: "bold",
                            minWidth: "65px",
                          }}
                        >
                          Add-ons
                        </p>
                        <span
                          style={{
                            width: "100%",
                            height: "1px",
                            backgroundColor: "#FEF7E9",
                          }}
                        />
                        <p
                          style={{
                            padding: "4px 6px",
                            background: "rgba(12, 82, 115, 1)",
                            color: "#fff",
                            borderRadius: "4px",
                            minWidth: "80px",
                            fontSize: "10px",
                          }}
                        >
                          Comprehensive
                        </p>
                      </Grid>
                      <Grid size={7}>
                        <ul
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                            marginBottom: "8px",
                          }}
                        >
                          <li
                            style={{
                              listStyleType: "none",
                              fontSize: "12px",
                              display: "flex",
                              gap: "6px",
                            }}
                          >
                            <img
                              src="../images/tick-icon.svg"
                              style={{
                                height: "14px",
                                width: "14px",
                                marginTop: "1px",
                              }}
                            />
                            24*7 Roadside Assistance
                          </li>
                          <li
                            style={{
                              listStyleType: "none",
                              fontSize: "12px",
                              display: "flex",
                              gap: "6px",
                            }}
                          >
                            <img
                              src="../images/tick-icon.svg"
                              style={{
                                height: "14px",
                                width: "14px",
                                marginTop: "1px",
                              }}
                            />
                            Driver Cover
                          </li>
                          <li
                            style={{
                              listStyleType: "none",
                              fontSize: "12px",
                              display: "flex",
                              gap: "6px",
                            }}
                          >
                            <img
                              src="./images/tick-icon.svg"
                              style={{
                                height: "14px",
                                width: "14px",
                                marginTop: "1px",
                              }}
                            />
                            Zero Depriciation
                          </li>
                        </ul>
                        <JDSButtons
                          text={"View all features"}
                          variant={"text"}
                          className={"tertiaryBtn small"}
                          fullWidth={false}
                          onClick={() => true}
                        />
                      </Grid>
                      <Grid size={5} textAlign="right">
                        <p>
                          <small style={{ color: "#595959" }}>
                            Claims Settled
                          </small>
                        </p>
                        <h6
                          style={{ fontWeight: "bold", color: COLORS.primary }}
                        >
                          94%
                        </h6>
                      </Grid>
                      <Grid size={12} textAlign="right">
                        <Box
                          sx={{
                            width: "100%",
                            height: "1px",
                            backgroundColor: "#FEF7E9",
                            marginBottom: "16px",
                          }}
                        />
                        <JDSButtons
                          text={
                            <>
                              ₹ 2,322
                              <KeyboardArrowRightRoundedIcon className="ml-1" />
                            </>
                          }
                          variant={"text"}
                          className={"primaryBtns small"}
                          fullWidth={false}
                          onClick={() => {
                            window.scrollTo(0, 0);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Slider>
            </Box>
          </Grid>
          <Grid size={12}>
            <Box maxWidth="472px" margin="0px auto">
              <JDSButtons
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                className="secondaryBtn mb-4 "
                text="See more plans"
                variant="outlined"
                // loader="true"
              />
            </Box>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid container className="row px-3" rowSpacing={3} columnSpacing={3}>
            <Grid size={12} className="textCenter">
              <p
                style={{
                  textTransform: "uppercase",
                  lineHeight: "16px",
                  marginBottom: "4px",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                Top picks
              </p>
              <h3>Trending Two-Wheeler Insurances</h3>
            </Grid>
            <Grid size={4}>
              <Box
                sx={{
                  boxShadow: "0px 0px 4px rgba(0,0,0,0.25)",
                  padding: "16px",
                  borderRadius: "24px",
                  marginTop: "2px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid size={12} display="flex" gap="8px" alignItems="center">
                    <img
                      src="./images/insurerlogo/ICICI-Lombard-General-Insurance-Co-Ltd.svg"
                      height="60px"
                      alt="insurer"
                    />
                    <p>ICICI Lombard</p>
                  </Grid>
                  <Grid
                    size={12}
                    sx={{ display: "flex", gap: "24px", alignItems: "center" }}
                  >
                    <p
                      style={{
                        fontWeight: "bold",
                        minWidth: "65px",
                      }}
                    >
                      Add-ons
                    </p>
                    <span
                      style={{
                        width: "100%",
                        height: "1px",
                        backgroundColor: "#FEF7E9",
                      }}
                    />
                    <p
                      style={{
                        padding: "4px 6px",
                        background: "rgba(12, 82, 115, 1)",
                        color: "#fff",
                        borderRadius: "4px",
                        minWidth: "80px",
                        fontSize: "10px",
                      }}
                    >
                      Comprehensive
                    </p>
                  </Grid>
                  <Grid size={7}>
                    <ul
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        marginBottom: "8px",
                      }}
                    >
                      <li
                        style={{
                          listStyleType: "none",
                          fontSize: "12px",
                          display: "flex",
                          gap: "6px",
                        }}
                      >
                        <img
                          src="../images/tick-icon.svg"
                          style={{
                            height: "14px",
                            width: "14px",
                            marginTop: "1px",
                          }}
                        />
                        24*7 Roadside Assistance
                      </li>
                      <li
                        style={{
                          listStyleType: "none",
                          fontSize: "12px",
                          display: "flex",
                          gap: "6px",
                        }}
                      >
                        <img
                          src="../images/tick-icon.svg"
                          style={{
                            height: "14px",
                            width: "14px",
                            marginTop: "1px",
                          }}
                        />
                        Zero Depriciation
                      </li>
                      <li
                        style={{
                          listStyleType: "none",
                          fontSize: "12px",
                          display: "flex",
                          gap: "6px",
                        }}
                      >
                        <img
                          src="./images/tick-icon.svg"
                          style={{
                            height: "14px",
                            width: "14px",
                            marginTop: "1px",
                          }}
                        />
                        Driver Cover
                      </li>
                    </ul>
                    <JDSButtons
                      text={"View all features"}
                      variant={"text"}
                      className={"tertiaryBtn small"}
                      fullWidth={false}
                      onClick={() => true}
                    />
                  </Grid>
                  <Grid size={5} textAlign="right">
                    <p>
                      <small style={{ color: "#595959" }}>Claims Settled</small>
                    </p>
                    <h6 style={{ fontWeight: "bold", color: COLORS.primary }}>
                      96.75%
                    </h6>
                  </Grid>
                  <Grid size={12} textAlign="right">
                    <Box
                      sx={{
                        width: "100%",
                        height: "1px",
                        backgroundColor: "#FEF7E9",
                        marginBottom: "16px",
                      }}
                    />
                    <JDSButtons
                      text={
                        <>
                          ₹ 2,045
                          <KeyboardArrowRightRoundedIcon className="ml-1" />
                        </>
                      }
                      variant={"text"}
                      className={"primaryBtns small"}
                      fullWidth={false}
                      onClick={() => window.scrollTo(0, 0)}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid size={4}>
              <Box
                sx={{
                  boxShadow: "0px 0px 4px rgba(0,0,0,0.25)",
                  padding: "16px",
                  borderRadius: "24px",
                  marginTop: "2px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid size={12} display="flex" gap="8px" alignItems="center">
                    <img
                      src="./images/insurerlogo/HDFC-ERGO-General-Insurance-Co-Ltd.svg"
                      height="60px"
                      alt="insurer"
                    />
                    <p>HDFC ERGO</p>
                  </Grid>
                  <Grid
                    size={12}
                    sx={{ display: "flex", gap: "24px", alignItems: "center" }}
                  >
                    <p
                      style={{
                        fontWeight: "bold",
                        minWidth: "65px",
                      }}
                    >
                      Add-ons
                    </p>
                    <span
                      style={{
                        width: "100%",
                        height: "1px",
                        backgroundColor: "#FEF7E9",
                      }}
                    />
                    <p
                      style={{
                        padding: "4px 6px",
                        background: "rgba(12, 82, 115, 1)",
                        color: "#fff",
                        borderRadius: "4px",
                        minWidth: "80px",
                        fontSize: "10px",
                      }}
                    >
                      Comprehensive
                    </p>
                  </Grid>
                  <Grid size={7}>
                    <ul
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        marginBottom: "8px",
                      }}
                    >
                      <li
                        style={{
                          listStyleType: "none",
                          fontSize: "12px",
                          display: "flex",
                          gap: "6px",
                        }}
                      >
                        <img
                          src="../images/tick-icon.svg"
                          style={{
                            height: "14px",
                            width: "14px",
                            marginTop: "1px",
                          }}
                        />
                        24*7 Roadside Assistance
                      </li>
                      <li
                        style={{
                          listStyleType: "none",
                          fontSize: "12px",
                          display: "flex",
                          gap: "6px",
                        }}
                      >
                        <img
                          src="../images/tick-icon.svg"
                          style={{
                            height: "14px",
                            width: "14px",
                            marginTop: "1px",
                          }}
                        />
                        Driver Cover
                      </li>
                      <li
                        style={{
                          listStyleType: "none",
                          fontSize: "12px",
                          display: "flex",
                          gap: "6px",
                        }}
                      >
                        <img
                          src="./images/tick-icon.svg"
                          style={{
                            height: "14px",
                            width: "14px",
                            marginTop: "1px",
                          }}
                        />
                        Zero Depriciation
                      </li>
                    </ul>
                    <JDSButtons
                      text={"View all features"}
                      variant={"text"}
                      className={"tertiaryBtn small"}
                      fullWidth={false}
                      onClick={() => true}
                    />
                  </Grid>
                  <Grid size={5} textAlign="right">
                    <p>
                      <small style={{ color: "#595959" }}>Claims Settled</small>
                    </p>
                    <h6 style={{ fontWeight: "bold", color: COLORS.primary }}>
                      98%
                    </h6>
                  </Grid>
                  <Grid size={12} textAlign="right">
                    <Box
                      sx={{
                        width: "100%",
                        height: "1px",
                        backgroundColor: "#FEF7E9",
                        marginBottom: "16px",
                      }}
                    />
                    <JDSButtons
                      text={
                        <>
                          ₹ 2,445
                          <KeyboardArrowRightRoundedIcon className="ml-1" />
                        </>
                      }
                      variant={"text"}
                      className={"primaryBtns small"}
                      fullWidth={false}
                      onClick={() => window.scrollTo(0, 0)}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid size={4}>
              <Box
                sx={{
                  boxShadow: "0px 0px 4px rgba(0,0,0,0.25)",
                  padding: "16px",
                  borderRadius: "24px",
                  marginTop: "2px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid size={12} display="flex" gap="8px" alignItems="center">
                    <img
                      src="./images/insurerlogo/digit.svg"
                      height="60px"
                      alt="insurer"
                    />
                    <p>Go Digit</p>
                  </Grid>
                  <Grid
                    size={12}
                    sx={{ display: "flex", gap: "24px", alignItems: "center" }}
                  >
                    <p
                      style={{
                        fontWeight: "bold",
                        minWidth: "65px",
                      }}
                    >
                      Add-ons
                    </p>
                    <span
                      style={{
                        width: "100%",
                        height: "1px",
                        backgroundColor: "#FEF7E9",
                      }}
                    />
                    <p
                      style={{
                        padding: "4px 6px",
                        background: "rgba(12, 82, 115, 1)",
                        color: "#fff",
                        borderRadius: "4px",
                        minWidth: "80px",
                        fontSize: "10px",
                      }}
                    >
                      Comprehensive
                    </p>
                  </Grid>
                  <Grid size={7}>
                    <ul
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        marginBottom: "8px",
                      }}
                    >
                      <li
                        style={{
                          listStyleType: "none",
                          fontSize: "12px",
                          display: "flex",
                          gap: "6px",
                        }}
                      >
                        <img
                          src="../images/tick-icon.svg"
                          style={{
                            height: "14px",
                            width: "14px",
                            marginTop: "1px",
                          }}
                        />
                        24*7 Roadside Assistance
                      </li>
                      <li
                        style={{
                          listStyleType: "none",
                          fontSize: "12px",
                          display: "flex",
                          gap: "6px",
                        }}
                      >
                        <img
                          src="../images/tick-icon.svg"
                          style={{
                            height: "14px",
                            width: "14px",
                            marginTop: "1px",
                          }}
                        />
                        Driver Cover
                      </li>
                      <li
                        style={{
                          listStyleType: "none",
                          fontSize: "12px",
                          display: "flex",
                          gap: "6px",
                        }}
                      >
                        <img
                          src="./images/tick-icon.svg"
                          style={{
                            height: "14px",
                            width: "14px",
                            marginTop: "1px",
                          }}
                        />
                        Zero Depriciation
                      </li>
                    </ul>
                    <JDSButtons
                      text={"View all features"}
                      variant={"text"}
                      className={"tertiaryBtn small"}
                      fullWidth={false}
                      onClick={() => true}
                    />
                  </Grid>
                  <Grid size={5} textAlign="right">
                    <p>
                      <small style={{ color: "#595959" }}>Claims Settled</small>
                    </p>
                    <h6 style={{ fontWeight: "bold", color: COLORS.primary }}>
                      94%
                    </h6>
                  </Grid>
                  <Grid size={12} textAlign="right">
                    <Box
                      sx={{
                        width: "100%",
                        height: "1px",
                        backgroundColor: "#FEF7E9",
                        marginBottom: "16px",
                      }}
                    />
                    <JDSButtons
                      text={
                        <>
                          ₹ 2,322
                          <KeyboardArrowRightRoundedIcon className="ml-1" />
                        </>
                      }
                      variant={"text"}
                      className={"primaryBtns small"}
                      fullWidth={false}
                      onClick={() => window.scrollTo(0, 0)}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid size={12}>
              <Box
                maxWidth="472px"
                padding="0px 24px 0px 12px"
                margin="0px auto"
              >
                <JDSButtons
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  className="secondaryBtn mb-4 "
                  text="See more plans"
                  variant="outlined"
                  // loader="true"
                />
              </Box>
            </Grid>
          </Grid>
        </>
      )} */}

        {/* <Grid container className="row" rowSpacing={3} columnSpacing={6}>
        <Grid size={{ xs: 12, md: 6 }} display="flex" flexDirection="column" gap="24px">
          <Box>
            <p
              style={{
                textTransform: "uppercase",
                lineHeight: "16px",
                marginBottom: "4px",
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              Knowledge Hub
            </p>
            <h3>Types of Two-Wheeler Insurance we offer</h3>
          </Box>
          <p style={{ color: COLORS.lightgrey, lineHeight: "20px" }}>
            Three basic types of Two-Wheeler insurance policies are typically provided
            by insurance companies in India. Both Third Party and Comprehensive
            Two-Wheeler Insurance are available. For more details, read on:
          </p>
          <ol
            style={{
              paddingLeft: "24px",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <li style={{ fontWeight: "900" }}>
              <h6 style={{ fontWeight: "900" }}>Own Damage Two-Wheeler Insurance</h6>
              <p
                style={{
                  color: COLORS.lightgrey,
                  lineHeight: "20px",
                  marginTop: "8px",
                  marginLeft: "-24px",
                }}
              >
                Own Damage (OD) helps you stay covered against damage caused to
                our vehicle due to accidents like fire, theft, etc. In case of
                an accident, an own damage cover compensates you for expense to
                repair or replace parts of your Two-Wheeler damaged in the accident
              </p>
            </li>
            <li style={{ fontWeight: "900" }}>
              <h6 style={{ fontWeight: "900" }}>
                Third Party Two wheeler Insurance
              </h6>
              <p
                style={{
                  color: COLORS.lightgrey,
                  lineHeight: "20px",
                  marginTop: "8px",
                  marginLeft: "-24px",
                }}
              >
                As the name implies, third-party two wheeler insurance protects
                the rider from all legal responsibilities that result from
                harming a third person. Two wheeler insurance for third parties
                protects you against any legal obligations you may have incurred
                as a result of unintentional damage you may have caused to
                another person's home or vehicle. Additionally, it protects you
                from being held responsible for a third party's unintentional
                injury or death.
              </p>
              <p
                style={{
                  color: COLORS.lightgrey,
                  lineHeight: "20px",
                  marginTop: "8px",
                  marginLeft: "-24px",
                }}
              >
                Anyone who owns a two-wheeled vehicle, including a motorcycle or
                scooter, is required under the Indian Motor Vehicle Act of 1988
                to carry third-party Two-Wheeler insurance in order to travel on public
                highways in the nation. Those who break the rule will be subject
                to severe punishment
              </p>
            </li>
            <li style={{ fontWeight: "900" }}>
              <h6 style={{ fontWeight: "900" }}>
                Comprehensive Two-Wheeler insurance
              </h6>
              <p
                style={{
                  color: COLORS.lightgrey,
                  lineHeight: "20px",
                  marginTop: "8px",
                  marginLeft: "-24px",
                }}
              >
                Comprehensive Two-Wheeler insurance that covers the rider's legal
                obligations to third parties as well as any damage to the
                personal vehicle . It protects you against the losses suffered
                in the event of your Two-Wheeler is damaged due to accidents, fires,
                natural catastrophes, man-made disasters or stolen.
              </p>
            </li>
          </ol>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <h3>How to buy Insurance with us?</h3>
          <ul
            style={{
              display: "flex",
              gap: "24px",
              flexDirection: "column",
              margin: "24px 0px",
            }}
          >
            {howtoBuyData.map((item, index) => (
              <li
                key={index} // Use index as the key, but ideally, each item should have a unique identifier
                style={{
                  position: "relative",
                  paddingLeft: "56px",
                }}
              >
                {index === howtoBuyData.length - 1 ? null : (
                  <span
                    style={{
                      backgroundColor: "#B5B5B5",
                      position: "absolute",
                      left: "20px",
                      top: "40px",
                      height: "100%",
                      width: "1px",
                    }}
                  ></span>
                )}
                <Box
                  sx={{
                    backgroundColor: COLORS.lightMariGold20,
                    height: "40px",
                    width: "40px",
                    borderRadius: "40px",
                    position: "absolute",
                    top: "0px",
                    left: "0px",
                    padding: "6px",
                  }}
                >
                  <img src={item.image} width="100%" alt="icon" />
                </Box>
                <h5>{item.title}</h5>
                <p
                  style={{
                    color: COLORS.lightgrey,
                    marginTop: "8px",
                  }}
                >
                  {item.description}
                </p>
              </li>
            ))}
          </ul>
        </Grid>
      </Grid> */}

        {/* Blog articles */}
        {/* {redirected ? null : (
          <Box className="blogs_section">
            <Grid
              container
              className="row"
              columnSpacing={3}
              marginBottom={"32px"}
            >
              <Grid size={12}>
                <h4
              style={{
                color: "#595959",
              }}
            >
              Resource Hub
            </h4>
                <h2>Blogs</h2>
                <h5
                  style={{
                    color: "#595959",
                    maxWidth: "824px",
                  }}
                >
                  Select a two-wheeler plan that covers all unforeseen
                  situations like theft, natural disasters and protects against
                  third party liabilities. Read on to find out on what else to
                  keep in mind before finalising your insurance policy.
                </h5>
              </Grid>
              <Grid size={12}>
                <ul>
                  {blogCardsArray?.map((data, index: any) => (
                    <BlogCard data={data} />
                  ))}
                </ul>
              </Grid>
              <Grid size={12} display={"flex"} justifyContent={"center"}>
                <Box maxWidth={"384px"} width={"100%"}>
                  <JDSButtons
                    text="Read more blogs"
                    variant={"text"}
                    className={"secondaryBtns large"}
                    onClick={() => navigate(COMMON_ROUTES.BLOGS)}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        )} */}

        {/* compare and select sec */}
        {/* <Grid
          container
          rowSpacing={3}
          columnSpacing={{ xs: 6, md: 3 }}
          justifyContent={"center"}
        >
          <Grid size={12} className="textCenter">
            <h2>Compare and select best two-wheeler insurance</h2>
          </Grid>
          <Grid size={12}>
            <Box className={styles.tableWrapper}>
              <table
                className={styles.prefferedTable}
                cellSpacing={0}
                cellPadding={0}
              >
                <tr>
                  <th>Benefits</th>
                  <th>Comprehensive cover</th>
                  <th>Third party liability only cover</th>
                </tr>

                <tr>
                  <td>Damage due to natural calamity - Earthquakes, etc</td>
                  <td>
                    <Box className={styles.success} />
                  </td>
                  <td>
                    <Box className={styles.failed} />
                  </td>
                </tr>
                <tr>
                  <td>
                    Damage due to events like- Fire, theft, vandalism, etc
                  </td>
                  <td>
                    <Box className={styles.success} />
                  </td>
                  <td>
                    <Box className={styles.failed} />
                  </td>
                </tr>
                <tr>
                  <td>Add on choices - Zero depreciation, NCB protect, etc</td>
                  <td>
                    <Box className={styles.success} />
                  </td>
                  <td>
                    <Box className={styles.failed} />
                  </td>
                </tr>
                <tr>
                  <td>Customisation of car value</td>
                  <td>
                    <Box className={styles.success} />
                  </td>
                  <td>
                    <Box className={styles.success} />
                  </td>
                </tr>
                <tr>
                  <td>Personal accident cover of ₹15 lakhs*</td>
                  <td>
                    <Box className={styles.success} />
                  </td>
                  <td>
                    <Box className={styles.success} />
                  </td>
                </tr>
                <tr>
                  <td>No hefty fines levied if valid policy found</td>
                  <td>
                    <Box className={styles.success} />
                  </td>
                  <td>
                    <Box className={styles.success} />
                  </td>
                </tr>
                <tr>
                  <td>Injury to a third person</td>
                  <td>
                    <Box className={styles.success} />
                  </td>
                  <td>
                    <Box className={styles.success} />
                  </td>
                </tr>
                <tr>
                  <td>Damage to third party vehicle/property</td>
                  <td>
                    <Box className={styles.success} />
                  </td>
                  <td>
                    <Box className={styles.success} />
                  </td>
                </tr>
              </table>
            </Box>
            <Box maxWidth="384px" marginLeft="auto">
              <JDSButtons
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                className="primaryBtns large mt-8"
                text="Buy now"
                variant="outlined"
                fullWidth={true}
              />
            </Box>
          </Grid>
        </Grid> */}

        {/* <Box className="lightGoldBg">
          <Grid container rowSpacing={3} columnSpacing={6}>
            <Grid size={12} textAlign="center">
              <h2>Factors affecting your two-wheeler insurance premium</h2>
            </Grid>
            <Grid size={12} className="why_jio_section">
              <ul>
                {factorsAffectingArray?.map((data, index) => (
                  <li key={index}>
                    <h4
                      className="mb-2"
                      style={{
                        color: COLORS.darkgrey,
                        fontWeight: "900",
                        lineHeight: "28px",
                        textAlign: "left",
                      }}
                    >
                      {data.heading}
                    </h4>
                    <p
                      style={{
                        color: COLORS.lightgrey,
                        fontSize: "14px",
                        lineHeight: "20px",
                      }}
                    >
                      {data.desc}
                    </p>
                  </li>
                ))}
              </ul>
            </Grid>
          </Grid>
        </Box> */}

        {/* <Box className="lightGoldBg">
          <Grid container className="row" rowSpacing={3} columnSpacing={6}>
            <Grid size={12} textAlign="center">
              <h2>How to Save on two-wheeler insurance premium?</h2>
              <p className="mt-4">
                You can reduce your two-wheeler insurance price in a number of
                ways without sacrificing the coverage of your policy. Look at
                them below:
              </p>
            </Grid>
            <Grid size={12} className="why_jio_section">
              <ul>
                {savePremiumArray?.map((data, index) => (
                  <li key={index}>
                    <h4
                      className="mb-2"
                      style={{
                        color: COLORS.darkgrey,
                        fontWeight: "900",
                        lineHeight: "28px",
                        textAlign: "left",
                      }}
                    >
                      {data.heading}
                    </h4>
                    <p
                      style={{
                        color: COLORS.lightgrey,
                        fontSize: "14px",
                        lineHeight: "20px",
                      }}
                    >
                      {data.desc}
                    </p>
                  </li>
                ))}
              </ul>
            </Grid>
          </Grid>
        </Box> */}

        {isMobile ? <LandingMFooter /> : <LandingFooter />}
      </Box>
    </>
  );
}

export default LandingTWProductPage;
