import { Box, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid2";
import { useState } from "react";
import { toast } from "react-toastify";
import { COMMON_SERVICES } from "../../../../Services/Common/CommonService";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { COLORS } from "../../../../SupportingFiles/colors";
import {
  isEmpty,
  validateMobileNumber,
} from "../../../../SupportingFiles/HelpingFunction";
import { TFieldData } from "../../../../types/Common/TFieldData";
import JDSButtons from "../../../InputFields/JDSButtons/JDSButtons";
import TextInputField from "../../../InputFields/TextInputField/TextInputField";
import "./../../../../SCSS/ModalPopup.scss";

const ExitIntentPopup = ({
  open,
  setOpen,
  type,
}: {
  open: boolean;
  setOpen: Function;
  type: any;
}) => {
  const [linkLoad, setLinkLoad] = useState<boolean>(false);
  const { Car } = useAppSelector((state) => state);

  const [shareURLData, setShareURLData] = useState<{
    mobile: TFieldData;
  }>({
    mobile: { value: "", warning: false },
  });

  const handleDetails = (attrName: any, value: any) => {
    if (attrName === "mobile") {
      setShareURLData((prev) => ({
        ...prev,
        mobile: { value: value, warning: !validateMobileNumber(value) },
      }));
    }
  };
  const EVENT_TRACKER = () => {
    let Mdata = { ...shareURLData };
    if (!isEmpty(Mdata.mobile.value)) {
      Mdata = {
        ...Mdata,
        mobile: {
          ...Mdata.mobile,
          warning: !validateMobileNumber(Mdata.mobile.value),
        },
      };
    } else {
      Mdata = {
        ...Mdata,
        mobile: {
          ...Mdata.mobile,
          warning: false,
        },
      };
    }

    setShareURLData(Mdata);
    // Determine if there's an error
    const hasError = Mdata.mobile.warning;

    if (isEmpty(Mdata.mobile.value)) {
      toast.error("Please fill mandatory fields.");
      return;
    }
    if (hasError) {
      return;
    }
    const onSuccess = (res: any) => {
      setShareURLData({
        mobile: { value: "", warning: false },
      });
      toast.success("Submitted successfully");
      setOpen(false);
    };

    const onError = (err: any) => {
      console.log(err);
      setOpen(false);
    };
    if (type === "Health") {
      const data = {
        mobile: Mdata.mobile.value,
        insurance_type: `Health Insurance`,
      };
      COMMON_SERVICES.EXIT_INTENT(onSuccess, onError, data);
    } else if (type === "Car") {
      const data = {
        mobile: Mdata.mobile.value,
        insurance_type: `Car Insurance`,
      };
      COMMON_SERVICES.EXIT_INTENT(onSuccess, onError, data);
    } else if (type === "TW") {
      const data = {
        mobile: Mdata.mobile.value,
        insurance_type: `Tw Insurance`,
      };
      COMMON_SERVICES.EXIT_INTENT(onSuccess, onError, data);
    } else {
      const data = {
        mobile: Mdata.mobile.value,
        insurance_type: `Term Insurance`,
      };
      COMMON_SERVICES.EXIT_INTENT(onSuccess, onError, data);
    }
  };
  return (
    <Modal open={open}>
      <Box
        className="modalWrapper"
        display="flex"
        justifyContent="center"
        alignItems="center">
        <Box className="modalContent smWidth">
          <Grid className="topbar" container alignItems="center">
            <Grid size={12} textAlign={"right"}>
              <Button
                disableRipple
                className="closePopup"
                onClick={() => {
                  setShareURLData({
                    mobile: { value: "", warning: false },
                  });
                  setOpen(false);
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            className="row"
            columnSpacing={2}
            alignItems="flex-start">
            <Grid size={12}>
              <Box>
                <h3
                  style={{
                    fontWeight: "900",
                    textAlign: "left",
                    marginBottom: "16px",
                  }}>
                  Happy to help
                </h3>
                <p className="mb-4">
                  Could not find what you are looking for? If you need
                  assistance with your insurance needs, we’re here for you.
                </p>
                <Box className="mobileNumber">
                  <Box
                    position="absolute"
                    left="12px"
                    bottom="13px"
                    zIndex="2"
                    fontSize="18px"
                    fontWeight="bold"
                    sx={{ color: COLORS.lightgrey }}>
                    +91
                  </Box>
                  <TextInputField
                    class_name="inputField"
                    title={"Enter your Mobile number"}
                    value={shareURLData.mobile.value}
                    placeholder="XXXXXXXXXX"
                    attrName={"mobile"}
                    value_update={handleDetails}
                    error_message={
                      !isEmpty(shareURLData.mobile.value)
                        ? "Enter Valid Mobile Number"
                        : "Enter Mobile Number"
                    }
                    warn_status={shareURLData.mobile.warning}
                    validation_type="NUMBER"
                    max_length={10}
                  />
                </Box>

                {/* <Box className="mobileNumber">
                    
                      <RKTextField
                class_name="inputField"
                title={"Enter your Mobile number"}
                value={shareURLData.mobile.value}
                attrName={"mobile"}
                value_update={handleDetails}
                error_message={
                  !isEmpty(shareURLData.mobile.value)
                    ? "Enter Valid Mobile No."
                    : "Enter Mobile No."
                }
                warn_status={shareURLData.mobile.warning}
                validation_type="NUMBER"
                max_length={10}
              />
                  </Box> */}
                <Grid size={12} className="mt-8" textAlign={"right"}>
                  <JDSButtons
                    text={"Submit"}
                    variant={"text"}
                    className={"primaryBtns large"}
                    fullWidth={false}
                    onClick={() => {
                      EVENT_TRACKER();
                    }}
                  />
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default ExitIntentPopup;
