import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Grid from "@mui/material/Grid2"; // Grid version 2
import { useEffect, useState } from "react";
import BackLeft from "../../../../Component/InputFields/BackLeft";
import BorderCheckBox from "../../../../Component/InputFields/BorderCheckBox/BorderCheckBox";
import CustomCheckbox from "../../../../Component/InputFields/CustomCheckbox/CustomCheckbox";
import DatePickerInput from "../../../../Component/InputFields/DatePicker/DatePicker";
import JDSButtons from "../../../../Component/InputFields/JDSButtons/JDSButtons";
import SearchSelectDropdown from "../../../../Component/InputFields/SearchSelectDropdown/SearchSelectDropdown";
import "../../../../SCSS/ProductForms.scss";
import { useAppSelector } from "../../../../Store/hooks";
import { COLORS } from "../../../../SupportingFiles/colors";
import { THealthAddForm } from "../../../../types/Health/THealthSlice";
import "./../../../../SCSS/ModalPopup.scss";

function MHealthForm({
  open,
  updateMasterState,
  formData,
  validateForm,
  setOpen,
  loader,
}: {
  open: any;
  setOpen: Function;
  updateMasterState: Function;
  formData: THealthAddForm;
  validateForm: Function;
  loader: boolean;
}) {
  const steps = ["Family detail", "Family age's"];
  const [page_status, setPageStatus] = useState<number>(0);
  useEffect(() => {
    setPageStatus(page_status);
  }, [page_status]);

  const { DROPDOWN_DATA } = useAppSelector((state) => state.Health);
  return (
    <>
      <Modal open={open}>
        <Box className="modalFormsMobile">
          <Box className="modalContent">
            <Box className="topbar">
              <Grid container className="row" alignItems="center">
                {page_status === 0 ? (
                  <Grid
                    size={12}
                    display={"flex"}
                    gap={"12px"}
                    alignItems={"center"}
                  >
                    <BackLeft
                      onClick={() => {
                        setOpen(false);
                        // dispatch(
                        //   HealthSlice.actions.SET_HEALTH_SLICE(
                        //     HealthSlice.getInitialState()
                        //   )
                        // );
                      }}
                    />
                    <img
                      src="./images/jio_insurance_logo.svg"
                      alt="jio-insurance-logo"
                      style={{ height: "12px" }}
                    />
                  </Grid>
                ) : (
                  <Grid
                    size={12}
                    display={"flex"}
                    gap={"12px"}
                    alignItems={"center"}
                  >
                    <BackLeft onClick={() => setPageStatus(0)} />
                    <img
                      src="./images/jio_insurance_logo.svg"
                      alt="jio-insurance-logo"
                      style={{ height: "12px" }}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>

            <Box className="scrollable-area sa-md">
              {/* first step */}
              <Grid
                container
                className="row"
                columnSpacing={3}
                marginBottom={"24px"}
                display=""
              >
                <Grid size={12} className="mform_steps pt-1 pl-0 pr-0 mb-8">
                  <Stepper activeStep={page_status} alternativeLabel>
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Grid>
                {page_status === 0 && (
                  <>
                    <Grid size={12} textAlign="center" marginBottom={"24px"}>
                      <h4 style={{ fontWeight: "900" }}>
                        Select the family members you want to insure
                      </h4>
                    </Grid>
                    <Grid size={6} marginBottom={"16px"}>
                      <BorderCheckBox
                        title="One Adult"
                        attrName="spouse_status"
                        value_update={() =>
                          updateMasterState("spouse_status", false)
                        }
                        value={!formData?.spouse_status}
                      />
                    </Grid>
                    <Grid size={6} marginBottom={"16px"}>
                      <BorderCheckBox
                        title="Two Adults"
                        attrName="spouse_status"
                        value_update={() =>
                          updateMasterState("spouse_status", true)
                        }
                        value={formData?.spouse_status}
                      />
                    </Grid>
                    <Grid size={12} marginBottom={"16px"}>
                      {" "}
                      <Box
                        sx={{
                          border: "1px solid #E0E0E0",
                          borderRadius: "12px",
                          padding: "6px 14px",
                          position: "relative",
                        }}
                      >
                        {formData?.son_status ? (
                          <Box
                            position="absolute"
                            right="8px"
                            top="11px"
                            display="flex"
                            alignItems="center"
                            gap="8px"
                            zIndex="9"
                          >
                            <Box
                              onClick={() =>
                                updateMasterState("son_subtract", "")
                              }
                              height="20px"
                              width="20px"
                              color={COLORS.darkgold}
                              textAlign="center"
                              sx={{ cursor: "pointer" }}
                            >
                              <RemoveRoundedIcon
                                sx={{ width: "21px", height: "21px" }}
                              />
                            </Box>
                            <Box
                              height="20px"
                              width="20px"
                              color={COLORS.darkgold}
                              borderRadius="40px"
                              textAlign="center"
                              sx={{ backgroundColor: "#FEF7E9" }}
                            >
                              {formData.son_count.value}
                            </Box>
                            <Box
                              onClick={() => updateMasterState("son_add", "")}
                              height="20px"
                              width="20px"
                              color={COLORS.darkgold}
                              textAlign="center"
                              sx={{ cursor: "pointer" }}
                            >
                              <AddRoundedIcon
                                sx={{ width: "21px", height: "21px" }}
                              />
                            </Box>
                          </Box>
                        ) : null}{" "}
                        <CustomCheckbox
                          label="Son"
                          attrName="son_status"
                          value_update={updateMasterState}
                          value={formData?.son_status}
                          defaultChecked={false}
                          disabled={false}
                        />{" "}
                      </Box>
                    </Grid>
                    <Grid size={12} marginBottom={"16px"}>
                      <Box
                        sx={{
                          border: "1px solid #E0E0E0",
                          borderRadius: "12px",
                          padding: "6px 14px",
                          position: "relative",
                        }}
                      >
                        {formData?.daughter_status ? (
                          <Box
                            position="absolute"
                            right="8px"
                            top="11px"
                            display="flex"
                            alignItems="center"
                            gap="8px"
                            zIndex="9"
                          >
                            <Box
                              onClick={() =>
                                updateMasterState("daughter_subtract", "")
                              }
                              height="20px"
                              width="20px"
                              color={COLORS.darkgold}
                              textAlign="center"
                              sx={{ cursor: "pointer" }}
                            >
                              <RemoveRoundedIcon
                                sx={{ width: "21px", height: "21px" }}
                              />
                            </Box>
                            <Box
                              height="20px"
                              width="20px"
                              color={COLORS.darkgold}
                              borderRadius="40px"
                              textAlign="center"
                              sx={{ backgroundColor: "#FEF7E9" }}
                            >
                              {formData.daughter_count.value}
                            </Box>
                            <Box
                              onClick={() =>
                                updateMasterState("daughter_add", "")
                              }
                              height="20px"
                              width="20px"
                              color={COLORS.darkgold}
                              textAlign="center"
                              sx={{ cursor: "pointer" }}
                            >
                              <AddRoundedIcon
                                sx={{ width: "21px", height: "21px" }}
                              />
                            </Box>
                          </Box>
                        ) : null}
                        <CustomCheckbox
                          label="Daughter"
                          attrName="daughter_status"
                          value_update={updateMasterState}
                          value={formData?.daughter_status}
                          defaultChecked={false}
                          disabled={false}
                        />
                      </Box>
                      <Box className="footerbar">
                        <JDSButtons
                          loader={loader}
                          variant={"text"}
                          className={"primaryBtns large"}
                          text="Continue"
                          onClick={() => {
                            setPageStatus(1);
                          }}
                        />
                      </Box>
                    </Grid>
                  </>
                )}
              </Grid>
              {/* second step */}
              {page_status === 1 && (
                <>
                  <Grid size={12} textAlign="center" marginBottom={"24px"}>
                    <h4 style={{ fontWeight: "900" }}>
                      Select age of your family members
                    </h4>
                  </Grid>
                  <Grid
                    container
                    className="row"
                    columnSpacing={3}
                    marginBottom={"24px"}
                    display=""
                  >
                    <Grid size={12} marginBottom={"24px"}>
                      <DatePickerInput
                        title="1st Adult Date of birth (DOB)"
                        attrName={"self_dob"}
                        value={formData?.self_dob?.value}
                        onChange={updateMasterState}
                        warn_status={formData?.self_dob?.warning}
                        min_date={110}
                        max_date={18}
                        date_validation_type="YEARS"
                        default_date={new Date("1990-01-01")}
                        error_message={"Select 1st adult DOB"}
                      />
                    </Grid>
                    {formData.spouse_status ? (
                      <>
                        <Grid size={12} marginBottom={"24px"}>
                          <DatePickerInput
                            title="2nd Adult Date of birth (DOB)"
                            attrName={"spouse_dob"}
                            onChange={updateMasterState}
                            value={formData?.spouse_dob?.value}
                            warn_status={formData?.spouse_dob?.warning}
                            min_date={110}
                            max_date={18}
                            date_validation_type="YEARS"
                            default_date={new Date("1990-01-01")}
                            error_message="Select 2nd adult DOB"
                          />
                        </Grid>
                        <Grid size={12} marginBottom={"24px"}>
                          <SearchSelectDropdown
                            title="Relationship with 1st Adult"
                            value={formData?.spouse_relationship?.value}
                            attrName={"spouse_relationship"}
                            value_update={updateMasterState}
                            options={DROPDOWN_DATA.ADULT2_RELATIONSHIP}
                            warn_status={formData?.spouse_relationship?.warning}
                            error_message={"Select relationship with 1st adult"}
                          />
                        </Grid>
                      </>
                    ) : null}
                    {parseInt(formData.son_count.value) +
                      parseInt(formData.daughter_count.value) >=
                    1 ? (
                      <Grid size={12} marginBottom={"24px"}>
                        <DatePickerInput
                          title="Child 1 Date of birth (DOB)"
                          attrName={"child_one_dob"}
                          value={formData?.child_one_dob?.value}
                          onChange={updateMasterState}
                          warn_status={formData?.child_one_dob?.warning}
                          min_date={25}
                          max_date={0}
                          date_validation_type="YEARS"
                          error_message="Select 1st child DOB"
                          default_date={new Date("2010-01-01")}
                        />
                      </Grid>
                    ) : null}
                    {parseInt(formData.son_count.value) +
                      parseInt(formData.daughter_count.value) >=
                    2 ? (
                      <Grid size={12} marginBottom={"24px"}>
                        <DatePickerInput
                          title="Child 2 Date of birth (DOB)"
                          attrName={"child_two_dob"}
                          value={formData?.child_two_dob?.value}
                          onChange={updateMasterState}
                          warn_status={formData?.child_two_dob?.warning}
                          min_date={25}
                          max_date={0}
                          date_validation_type="YEARS"
                          error_message="Select 2nd child DOB"
                          default_date={new Date("2010-01-01")}
                        />
                      </Grid>
                    ) : null}
                    {parseInt(formData.son_count.value) +
                      parseInt(formData.daughter_count.value) >=
                    3 ? (
                      <Grid size={12} marginBottom={"24px"}>
                        <DatePickerInput
                          title="Child 3 Date of birth (DOB)"
                          attrName={"child_three_dob"}
                          value={formData?.child_three_dob?.value}
                          onChange={updateMasterState}
                          warn_status={formData?.child_three_dob?.warning}
                          min_date={25}
                          max_date={0}
                          date_validation_type="YEARS"
                          error_message="Select 3rd child DOB"
                          default_date={new Date("2010-01-01")}
                        />
                      </Grid>
                    ) : null}
                    {parseInt(formData.son_count.value) +
                      parseInt(formData.daughter_count.value) >=
                    4 ? (
                      <Grid size={12} marginBottom={"24px"}>
                        <DatePickerInput
                          title="Child 4 Date of birth (DOB)"
                          attrName={"child_four_dob"}
                          value={formData?.child_four_dob?.value}
                          onChange={updateMasterState}
                          warn_status={formData?.child_four_dob?.warning}
                          min_date={25}
                          max_date={0}
                          date_validation_type="YEARS"
                          error_message="Select 4th child DOB"
                          default_date={new Date("2010-01-01")}
                        />
                      </Grid>
                    ) : null}
                    <Box className="footerbar">
                      <JDSButtons
                        loader={loader}
                        className="primaryBtns large"
                        text="Submit"
                        variant="contained"
                        onClick={validateForm}
                      />
                    </Box>
                  </Grid>
                </>
              )}
            </Box>
            {page_status === 1 && (
              <Box className="footerbar">
                <JDSButtons
                  className="primaryBtns large"
                  text="Submit"
                  variant="contained"
                  onClick={validateForm}
                  loader={loader}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default MHealthForm;
