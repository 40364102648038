import {
  EVButton,
  EVDateInput,
  EVSelectDropdown,
  EVTextField,
} from "@evervent_pvt_ltd/ui-kit";
import { Box, Button, Modal } from "@mui/material";
import Grid from "@mui/material/Grid2";
import NonTermFooter from "../../../../Component/Amaze/NonTerm/NonTermFooter/NonTermFooter";
import CustomCheckbox from "../../../../Component/InputFields/CustomCheckbox/CustomCheckbox";
import { useAppSelector } from "../../../../Store/hooks";
import { isEmpty } from "../../../../SupportingFiles/HelpingFunction";
import { TNonTermProposerData } from "../../../../types/TNonTermSlice";
import "./../../../../SCSS/Amaze/CommonSCSS/ProposalForms.scss";
const ICICNonTermProposal = ({
  proposerFields,
  fieldsUpdateState,
  validateForm,
  loader,
  isProposerSameInsured,
  setIsProposerSameInsured,
  isChecked,
  setChecked,
  openIPRUPDFModel,
  setOpenIPRUPDFModel,
  GET_REDIRECTION_URL,
}: {
  proposerFields: TNonTermProposerData;
  fieldsUpdateState: Function;
  validateForm: Function;
  loader: boolean;
  isProposerSameInsured: boolean;
  setIsProposerSameInsured: Function;
  isChecked: boolean;
  setChecked: Function;
  openIPRUPDFModel: boolean;
  setOpenIPRUPDFModel: Function;
  GET_REDIRECTION_URL: Function;
}) => {
  const {
    DROPDOWN_DATA,
    ADD_FORM,
    QUOTE_LOADER,
    UPDATE_RESPONSE,
    RIDERS_BENEFITS,
  } = useAppSelector((state) => state.NonTerm);
  const handleCheckboxChange = (attrName: string, isChecked: boolean) => {
    setChecked(isChecked);
    console.log(isChecked, "isChecked");
  };

  const handleClick = () => {
    setIsProposerSameInsured((prevState: boolean) => !prevState);
  };

  return (
    <>
      <Modal open={openIPRUPDFModel} onClose={() => setOpenIPRUPDFModel(false)}>
        <Box className="modalWrapper">
          <Box
            sx={{
              transform: "translate(0,0)",
              transition: "trapnsform 0.3s",
              minHeight: "100vh",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box className="modalContent ">
              <Grid className="topbar" container alignItems="center">
                <Grid size={12} textAlign={"right"}>
                  <Button
                    disableRipple
                    className="closePopup"
                    onClick={() => {
                      setOpenIPRUPDFModel(false);
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} alignContent="flex-start">
                <Grid size={12} textAlign={"center"}>
                  <iframe
                    src={`https://docs.google.com/viewer?url=${encodeURIComponent(
                      `${RIDERS_BENEFITS?.riders_benefits_response?.policyPdf}`
                    )}&embedded=true`}
                    style={{
                      minWidth: "500px",
                      width: "100%",
                      height: "700px",
                    }}
                    title="PDF Viewer"
                  ></iframe>
                </Grid>
                {/* <Grid
                  size={6}
                  textAlign={"center"}
                  marginTop={"12px"}
                  display={"flex"}
                  gap={"16px"}
                  justifyContent={"center"}>
                  <CustomButton
                    text_name={"Download"}
                    class_name="regularPrimaryBtn"
                    onClickFunction={() => {
                      HANDLE_PDF_DOWNLOAD();
                    }}
                  />
                </Grid> */}
                <Grid
                  size={12}
                  textAlign={"center"}
                  marginTop={"12px"}
                  display={"flex"}
                  gap={"16px"}
                  justifyContent={"center"}
                >
                  <EVButton
                    text={"Continue"}
                    className="primaryBtns large"
                    onClick={() => {
                      GET_REDIRECTION_URL();
                    }}
                    loader={loader}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Modal>
      {isProposerSameInsured ? (
        <Box>
          <h5 className="sectionTitle">Proposer Details</h5>
          <Grid container spacing={3}>
            <Grid size={12}>
              <h6>
                <CustomCheckbox
                  label={"Proposer is also an insured member."}
                  attrName="validPuc"
                  value_update={handleCheckboxChange}
                  value={isChecked}
                  defaultChecked={false}
                  disabled={false}
                  variant="large"
                />
              </h6>
            </Grid>
            <Grid size={6}>
              <EVTextField
                id="full_name"
                title={"Full Name"}
                validation_type="NAME"
                value={proposerFields.full_name.value}
                attrName={["full_name"]}
                value_update={fieldsUpdateState}
                warn_status={proposerFields?.full_name?.warning}
                error_message={
                  isEmpty(proposerFields.full_name.value)
                    ? "Enter Full Name"
                    : "Enter Valid Full Name"
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="my-4">
            <Grid size={4}>
              <EVDateInput
                id="dob"
                title={"DOB"}
                value={
                  UPDATE_RESPONSE?.dob
                    ? UPDATE_RESPONSE?.dob
                    : ADD_FORM.age.value
                }
                attrName={["dob"]}
                disabled={true}
                value_update={fieldsUpdateState}
                error_message="Enter DOB"
                warn_status={ADD_FORM.age.warning}
              />
            </Grid>
            <Grid size={4}>
              <EVSelectDropdown
                id="gender"
                title="Gender"
                value={ADD_FORM.gender.value}
                attrName={["gender"]}
                value_update={fieldsUpdateState}
                data={[
                  { label: "Male", value: "M" },
                  { label: "Female", value: "F" },
                  { label: "Other", value: "O" },
                ]}
                disabled={true}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid size={4}>
              <EVTextField
                id="mobile"
                disabled
                title={"Mobile"}
                value={ADD_FORM.mobile.value}
                attrName={["mobile"]}
                value_update={fieldsUpdateState}
                warn_status={ADD_FORM.mobile.warning}
                max_length={10}
                validation_type="NUMBER"
                error_message={
                  !ADD_FORM.mobile.value ? "Enter mobile number" : ""
                }
              />
            </Grid>
            <Grid size={6}>
              <EVTextField
                id="email"
                title={"Email"}
                value={proposerFields.email.value}
                attrName={["email"]}
                value_update={fieldsUpdateState}
                warn_status={proposerFields?.email?.warning}
                error_message={
                  isEmpty(proposerFields.email.value)
                    ? "Enter Email"
                    : "Enter Valid Email"
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="my-4">
            <Grid size={4}>
              <EVTextField
                id="pincode"
                title={"Pincode"}
                value={ADD_FORM.pincode.value}
                attrName={["pincode"]}
                value_update={fieldsUpdateState}
                warn_status={ADD_FORM.pincode.warning}
                validation_type="NUMBER"
                max_length={6}
                disabled={true}
                error_message={!ADD_FORM.pincode.warning ? "Enter Pincode" : ""}
              />
            </Grid>
            <Grid size={5}>
              <EVTextField
                id="emergency_number"
                title={"Emergency Phone"}
                value={proposerFields.emergency_number.value}
                attrName={["emergency_number"]}
                value_update={fieldsUpdateState}
                warn_status={proposerFields?.emergency_number?.warning}
                max_length={10}
                validation_type="NUMBER"
                error_message={
                  isEmpty(proposerFields.emergency_number.value)
                    ? "Enter Emergency Phone Number "
                    : "Enter Valid Emergency Phone Number"
                }
              />
            </Grid>
            <Grid size={4}>
              <EVSelectDropdown
                id="occupation"
                title="Occupation"
                value={proposerFields?.occupation?.value}
                attrName={["occupation"]}
                value_update={fieldsUpdateState}
                data={DROPDOWN_DATA.OCCUPATION_DATA}
                warn_status={proposerFields?.occupation?.warning}
                error_message="Select Proposer Occupation"
              />
            </Grid>
            <Grid size={4}>
              <EVSelectDropdown
                id="qualification"
                title="Qualification"
                value={proposerFields?.qualification?.value}
                attrName={["qualification"]}
                value_update={fieldsUpdateState}
                data={DROPDOWN_DATA.QUALIFICATION_DATA}
                warn_status={proposerFields?.qualification?.warning}
                error_message="Select Proposer Occupation"
              />
            </Grid>
          </Grid>
          <NonTermFooter
            loader={QUOTE_LOADER}
            forward={isChecked ? validateForm : handleClick}
          />
        </Box>
      ) : (
        <Box>
          <h5 className="sectionTitle">Insured Details</h5>
          <Grid container spacing={3}>
            <Grid size={6}>
              <EVTextField
                id="insured_name"
                title={"Full Name"}
                validation_type="NAME"
                value={proposerFields.insured_name.value}
                attrName={["insured_name"]}
                value_update={fieldsUpdateState}
                warn_status={proposerFields?.insured_name?.warning}
                error_message={
                  isEmpty(proposerFields.insured_name.value)
                    ? "Enter Full Name"
                    : "Enter Valid Full Name"
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="my-4">
            <Grid size={4}>
              <EVDateInput
                id="dob"
                title={"DOB"}
                value={proposerFields.insured_dob.value}
                attrName={["insured_dob"]}
                value_update={fieldsUpdateState}
                error_message="Enter DOB"
                warn_status={proposerFields.insured_dob.warning}
                min_date={65}
                max_date={18}
              />
            </Grid>
            <Grid size={4}>
              <EVSelectDropdown
                id="gender"
                title="Gender"
                value={proposerFields.insured_gender.value}
                attrName={["insured_gender"]}
                value_update={fieldsUpdateState}
                data={[
                  { label: "Male", value: "M" },
                  { label: "Female", value: "F" },
                ]}
                warn_status={proposerFields?.insured_gender?.warning}
                error_message="Select Gender"
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid size={4}>
              <EVTextField
                id="mobile"
                title={"Mobile"}
                value={proposerFields?.insured_mobile?.value}
                attrName={["insured_mobile"]}
                value_update={fieldsUpdateState}
                warn_status={proposerFields.insured_mobile.warning}
                max_length={10}
                validation_type="NUMBER"
              />
            </Grid>
            <Grid size={6}>
              <EVTextField
                id="email"
                title={"Email"}
                value={proposerFields.insured_email.value}
                attrName={["insured_email"]}
                value_update={fieldsUpdateState}
                warn_status={proposerFields?.insured_email?.warning}
                error_message={
                  isEmpty(proposerFields.insured_email.value)
                    ? "Enter Email"
                    : "Enter Valid Email"
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="my-4">
            <Grid size={4}>
              <EVTextField
                id="pincode"
                title={"Pincode"}
                value={proposerFields?.insured_pincode?.value}
                attrName={["insured_pincode"]}
                value_update={fieldsUpdateState}
                warn_status={proposerFields?.insured_pincode?.warning}
                validation_type="NUMBER"
                max_length={6}
                error_message={
                  !proposerFields?.insured_pincode?.warning
                    ? "Enter Pincode"
                    : ""
                }
              />
            </Grid>
            <Grid size={4}>
              <EVSelectDropdown
                id="occupation"
                title="Occupation"
                value={proposerFields?.insured_occupation?.value}
                attrName={["insured_occupation"]}
                value_update={fieldsUpdateState}
                data={DROPDOWN_DATA.OCCUPATION_DATA}
                warn_status={proposerFields?.insured_occupation?.warning}
                error_message="Select Proposer Occupation"
              />
            </Grid>
            <Grid size={4}>
              <EVSelectDropdown
                id="qualification"
                title="Qualification"
                value={proposerFields?.insured_qualification?.value}
                attrName={["insured_qualification"]}
                value_update={fieldsUpdateState}
                data={DROPDOWN_DATA.QUALIFICATION_DATA}
                warn_status={proposerFields?.qualification?.warning}
                error_message="Select Proposer Occupation"
              />
            </Grid>
          </Grid>
          <NonTermFooter
            loader={QUOTE_LOADER}
            forward={validateForm}
            backward={handleClick}
          />
        </Box>
      )}
    </>
  );
};

export default ICICNonTermProposal;
