import { PostAPI } from "../../Axios/AxiosInstanceToken";

import { CommonURLs } from "../../URLCollection/Common/CommonURLs";

const EVENT_TRACKER = (onSuccess: Function, onError: Function, data: any) => {
  PostAPI.call(
    CommonURLs.EVENT_TRACKER,
    data,
    (res: any) => onSuccess(res.data),
    onError
  );
};
const CLICK_TO_CALL = (onSuccess: Function, onError: Function, data: any) => {
  PostAPI.call(
    CommonURLs.CLICK_TO_CALL,
    data,
    (res: any) => onSuccess(res.data),
    onError
  );
};
const SCHEDULE = (onSuccess: Function, onError: Function, data: any) => {
  PostAPI.call(
    CommonURLs.SCHEDULE,
    data,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const EXIT_INTENT = (onSuccess: Function, onError: Function, data: any) => {
  PostAPI.call(
    CommonURLs.EXIT_INTENT,
    data,
    (res: any) => onSuccess(res.data),
    onError
  );
};

export const COMMON_SERVICES = {
  EVENT_TRACKER,
  EXIT_INTENT,
  CLICK_TO_CALL,
  SCHEDULE,
};
