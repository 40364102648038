import { EVButton } from "@evervent_pvt_ltd/ui-kit";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useState } from "react";
import NonTermPolicyDetailsContainer from "../../../../Container/Non-term/Quote/NonTermPolicyDetailsContainer";
import { COLORS } from "../../../../SupportingFiles/colors";
import { formatToCurrency } from "../../../../SupportingFiles/HelpingFunction";
import { TNonTermProduct } from "../../../../types/NonTerm/TNonTermCardProduct";

const MNonTermQuoteCard = ({
  quote_data,
  index,
  BUY_ACTION,
}: {
  quote_data: TNonTermProduct;
  index: number;
  BUY_ACTION: Function;
}) => {
  const [openPolicyDetailsPopup, setOpenPolicyDetailsPopup] = useState(false);
  const [isChecked, setChecked] = useState<boolean>(false);
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const exclusions = [
    "In case of death due to suicide within 12 months from the date of inception of the Policy, the Nominee of the Policyholder shall be entitled to Fund Value, as available on the date of death. Any charges recovered subsequent to the date of death shall be paid-back to Nominee along with death benefit.",
  ];

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#ffffff",
          boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
          padding: "16px 8px",
          borderRadius: "12px",
          position: "relative",
          zIndex: "9",
          marginBottom: "24px",
          "&:last-child": {
            marginBottom: "0px",
          },
        }}
      >
        <Grid
          container
          className="row"
          columnSpacing={2}
          marginBottom={"16px"}
          alignItems={"center"}
        >
          <Grid size={12}>
            <img
              src={quote_data?.quotationDetail?.companyDetails.logo}
              width="90px"
              height="50px"
            />
          </Grid>

          <Grid size={12}>
            <p style={{ fontSize: "14px", lineHeight: "16px" }}>
              {quote_data?.quotationDetail?.companyDetails.short_desc}
            </p>
            <p style={{ color: COLORS.lightgrey, lineHeight: "16px" }}>
              <small>
                {quote_data?.quotationDetail?.productDetails?.product_name}
              </small>
            </p>
          </Grid>
        </Grid>
        <Grid container className="row" columnSpacing={2} marginBottom={"16px"}>
          <Grid size={12}>
            <hr
              style={{
                border: "none",
                borderTop: "1px solid rgba(229, 241, 247, 1)",
              }}
            />
          </Grid>
        </Grid>

        <Grid container className="row" columnSpacing={2} marginBottom={"16px"}>
          <Grid size={6}>
            <p>
              <small style={{ color: "#595959" }}>Maturity Amount</small>
            </p>
            <h6 style={{ fontWeight: "bold", lineHeight: "24px" }}>
              {formatToCurrency(
                Number(
                  quote_data?.quotationDetail?.premiumDetails?.maturity_benefit
                )
              )}
            </h6>
          </Grid>
          <Grid size={6} textAlign={"right"}>
            <p>
              <small style={{ color: "#595959" }}>Risk Cover</small>
            </p>
            <h6 style={{ fontWeight: "bold", lineHeight: "24px" }}>
              {formatToCurrency(
                Number(
                  quote_data?.quotationDetail?.premiumDetails?.risk_cover_cal
                )
              )}
            </h6>
          </Grid>
        </Grid>
        <Grid container className="row" columnSpacing={2} marginBottom={"16px"}>
          <Grid size="auto">
            <p>
              <small style={{ color: "#595959" }}>Policy Term</small>
            </p>
            <h6 style={{ fontWeight: "bold", lineHeight: "24px" }}>
              {quote_data?.quotationDetail?.premiumDetails?.term} Years
            </h6>
          </Grid>
          <Grid size="auto" textAlign={"center"}>
            <p>
              <small style={{ color: "#595959" }}>Pay upto</small>
            </p>
            <h6 style={{ fontWeight: "bold", lineHeight: "24px" }}>
              {quote_data?.quotationDetail?.premiumDetails?.pay_term} Years
            </h6>
          </Grid>
          <Grid size={5} textAlign={"right"}>
            <p>
              <small style={{ color: "#595959" }}>Max. Maturity Age</small>
            </p>
            <h6 style={{ fontWeight: "bold", lineHeight: "24px" }}>
              {
                quote_data?.quotationDetail?.productDetails
                  ?.InvestmentFormValidations?.max_p_cess_age
              }{" "}
              Years
            </h6>
          </Grid>
        </Grid>
        <Grid container className="row" columnSpacing={2} marginBottom={"16px"}>
          <Grid size={12}>
            <hr
              style={{
                border: "none",
                borderTop: "1px solid rgba(229, 241, 247, 1)",
              }}
            />
          </Grid>
        </Grid>
        <Grid container className="row" columnSpacing={2} alignItems="center">
          <Grid size={6}>
            <EVButton
              id="editDetails"
              size="medium"
              className="tertiaryBtn"
              text={
                <>
                  Plan Details
                  <KeyboardArrowRightRoundedIcon
                    style={{ width: "20px", height: "20px" }}
                  />
                </>
              }
              fullWidth={false}
              onClick={() => setOpenPolicyDetailsPopup(true)}
            />
          </Grid>
          <Grid size={6} textAlign={"right"}>
            <EVButton
              id="buy_now"
              size="medium"
              className="primaryBtn"
              text="Buy Now"
              fullWidth={false}
              onClick={() => BUY_ACTION(quote_data.quotationDetail)}
            />
          </Grid>
        </Grid>
      </Box>

      {/* set view policy Details popup */}
      <NonTermPolicyDetailsContainer
        open={openPolicyDetailsPopup}
        setOpen={setOpenPolicyDetailsPopup}
        data={quote_data}
        BUY_ACTION={BUY_ACTION}
      />
    </>
  );
};

export default MNonTermQuoteCard;
