import { Box, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid2";
import { useEffect } from "react";
import "../../../../../SCSS/ModalPopup.scss";
import { useAppSelector } from "../../../../../Store/hooks";
import { COLORS } from "../../../../../SupportingFiles/colors";
import {
  calculateAge,
  formatToCurrency,
  isEmpty,
} from "../../../../../SupportingFiles/HelpingFunction";
import { TNonTermProduct } from "../../../../../types/NonTerm/TNonTermCardProduct";
import JDSButtons from "../../../../InputFields/JDSButtons/JDSButtons";
import { EProductId } from "../../../../../Enum/ENonTermComapnyCode";

const MNonTermPolicyDetails = ({
  open,
  setOpen,
  data,
  BUY_ACTION,
  openEbi,
  setOpenEbi,
  POLICY_DETAILS,
}: {
  open: boolean;
  setOpen: Function;
  data: TNonTermProduct;
  BUY_ACTION: Function;
  openEbi: boolean;
  setOpenEbi: Function;
  POLICY_DETAILS: {
    exclusions: string[];
    unique_features: string[];
    tax_benefits: string[];
  };
}) => {
  useEffect(() => {
    // Apply overflow hidden when the modal is open
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = ""; // Reset overflow when modal is closed
    }
  }, [open]);
  const { QUOTE_FIELDS_DATA, ADD_FORM } = useAppSelector(
    (state) => state.NonTerm
  );

  const download = (value: string) => {
    window.open(value, "_blank");
  };
  return (
    <Modal open={open}>
      <Box className="modalWrapperMobile">
        <Box className="modalContent">
          <Box className="topbar">
            <Grid container className="row" alignItems="center">
              <Grid size={12} textAlign={"right"}>
                <Button
                  disableRipple
                  className="closePopup"
                  onClick={() => {
                    setOpen(false);
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="scrollable-area">
            <Box
              padding="16px 24px"
              margin="0px -12px"
              sx={{ backgroundColor: COLORS.lightMariGold20 }}
            >
              <Box width="100%" display="flex" alignItems="center" gap="8px">
                <img
                  src={data?.quotationDetail?.companyDetails.logo}
                  height="50px"
                />
                <p style={{ fontWeight: "bold" }}>
                  {data?.quotationDetail?.companyDetails.name}
                </p>
              </Box>
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                flexWrap={"wrap"}
                rowGap="8px"
                marginTop="16px"
              >
                {/* <Box>
                  <JDSButtons
                    text={
                      <>
                        <ShareIcon
                          style={{ fontSize: "16px", marginRight: "14px" }}
                        />
                        Share
                      </>
                    }
                    variant={"text"}
                    className={"secondaryBtn small"}
                  />
                </Box> */}
                <Box width="50%" textAlign="left">
                  <p>
                    <small style={{ color: "#595959" }}>Policy Terms</small>
                  </p>
                  <h6 style={{ fontWeight: "bold" }}>
                    {QUOTE_FIELDS_DATA.policyTerm} Years
                  </h6>
                </Box>
                <Box width="50%" textAlign="right">
                  <p>
                    <small style={{ color: "#595959" }}>Pay Upto</small>
                  </p>
                  <h6 style={{ fontWeight: "bold" }}>
                    {QUOTE_FIELDS_DATA.payUpto} Years
                  </h6>
                </Box>
                <Box width="50%" textAlign="left">
                  <p>
                    <small style={{ color: "#595959" }}>
                      Investment Amount
                    </small>
                  </p>
                  <h6 style={{ fontWeight: "bold" }}>
                    {formatToCurrency(ADD_FORM.investAmount.value)}
                  </h6>
                </Box>
                <Box width="50%" textAlign="right">
                  <p>
                    <small style={{ color: "#595959" }}>Maturity Amount</small>
                  </p>
                  <h6 style={{ fontWeight: "bold" }}>
                    {formatToCurrency(
                      Number(
                        data?.quotationDetail?.premiumDetails?.maturity_benefit
                      )
                    )}
                  </h6>
                </Box>
                <Box width="50%" textAlign="left">
                  <p>
                    <small style={{ color: "#595959" }}>Risk Cover</small>
                  </p>
                  <h6 style={{ fontWeight: "bold" }}>
                    {formatToCurrency(
                      Number(
                        data?.quotationDetail?.premiumDetails?.risk_cover_cal
                      )
                    )}
                  </h6>
                </Box>
                <Box width="50%" textAlign="right">
                  <p>
                    <small style={{ color: "#595959" }}>Member</small>
                  </p>
                  <h6 style={{ fontWeight: "bold" }}>
                    {ADD_FORM.gender.value === "M" ? "Male" : "Female"},{" "}
                    {`${calculateAge(ADD_FORM.age.value)} Years`}
                  </h6>
                </Box>
              </Box>
            </Box>

            <Grid container className="row mt-6" columnSpacing={3}>
              <Grid size={12} marginBottom={"16px"}>
                <h6
                  style={{
                    fontWeight: "900",
                    marginBottom: "6px",
                  }}
                >
                  Eligibility
                </h6>

                <Box sx={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
                  <Box
                    sx={{
                      border: "1px solid #E0E0E0",
                      borderRadius: "16px",
                      padding: "8px 24px",
                      textAlign: "center",
                    }}
                  >
                    <img
                      alt=""
                      src="../images/success_icon.svg"
                      height="21px"
                    />
                    <p style={{ fontWeight: "bold", marginTop: "12px" }}>
                      {data?.quotationDetail?.companyDetails?.claim_ratio}%
                    </p>
                    <p style={{ color: COLORS.lightgrey }}>
                      <small>
                        Claim Settlement <br />
                        Ratio
                      </small>
                    </p>
                  </Box>
                  <Box
                    sx={{
                      border: "1px solid #E0E0E0",
                      borderRadius: "16px",
                      padding: "8px 24px",
                      textAlign: "center",
                    }}
                  >
                    <img
                      alt=""
                      src="../images/success_icon.svg"
                      height="21px"
                    />
                    <p style={{ fontWeight: "bold", marginTop: "12px" }}>
                      {QUOTE_FIELDS_DATA.investmentMode === "12"
                        ? "Monthly"
                        : QUOTE_FIELDS_DATA.investmentMode === "4"
                        ? "Quaterly"
                        : QUOTE_FIELDS_DATA.investmentMode === "2"
                        ? "Half-yearly"
                        : QUOTE_FIELDS_DATA.investmentMode === "1"
                        ? "Yearly"
                        : QUOTE_FIELDS_DATA.investmentMode === "5"
                        ? "Single Pay"
                        : null}
                    </p>
                    <p style={{ color: COLORS.lightgrey, lineHeight: "16px" }}>
                      <small>
                        Premium <br />
                        Frequency
                      </small>
                    </p>
                  </Box>
                  <Box
                    sx={{
                      border: "1px solid #E0E0E0",
                      borderRadius: "16px",
                      padding: "8px 24px",
                      textAlign: "center",
                    }}
                  >
                    <img
                      alt=""
                      src="../images/success_icon.svg"
                      height="21px"
                    />
                    <p style={{ fontWeight: "bold", marginTop: "12px" }}>
                      {
                        data?.quotationDetail?.productDetails
                          ?.InvestmentFormValidations?.max_p_cess_age
                      }{" "}
                      Years
                    </p>
                    <p style={{ color: COLORS.lightgrey, lineHeight: "16px" }}>
                      <small>
                        Max. Maturity <br />
                        Age
                      </small>
                    </p>
                  </Box>
                </Box>
                <hr
                  style={{
                    borderLeft: "none",
                    borderRight: "none",
                    borderTop: "none",
                    borderBottom: "1px solid #E0E0E0",
                    marginTop: "16px",
                    marginBottom: "16px",
                  }}
                />
                <h6 style={{ fontWeight: "900", marginBottom: "16px" }}>
                  Key Features
                </h6>
                <ul>
                  {POLICY_DETAILS?.unique_features?.map(
                    (item: string, index: any) => (
                      <li
                        style={{
                          paddingBottom: "16px",
                        }}
                      >
                        <p
                          style={{
                            display: "flex",
                            gap: "8px",
                            color: COLORS.lightgrey,
                          }}
                        >
                          <img
                            src="../images/success_icon.svg"
                            height="18px"
                            style={{ position: "relative", top: "2px" }}
                          />{" "}
                          {item}
                        </p>
                      </li>
                    )
                  )}
                </ul>
                <hr
                  style={{
                    borderLeft: "none",
                    borderRight: "none",
                    borderTop: "none",
                    borderBottom: "1px solid #E0E0E0",
                    marginTop: "16px",
                    marginBottom: "16px",
                  }}
                />
                <h6 style={{ fontWeight: "900", marginBottom: "16px" }}>
                  Tax Benefit
                </h6>
                <ul>
                  {POLICY_DETAILS?.tax_benefits?.map(
                    (item: string, index: any) => (
                      <li
                        style={{
                          paddingBottom: "16px",
                        }}
                      >
                        <p
                          style={{
                            display: "flex",
                            gap: "8px",
                            color: COLORS.lightgrey,
                          }}
                        >
                          <img
                            src="../images/success_icon.svg"
                            height="18px"
                            style={{ position: "relative", top: "2px" }}
                          />{" "}
                          {item}
                        </p>
                      </li>
                    )
                  )}
                </ul>
                <hr
                  style={{
                    borderLeft: "none",
                    borderRight: "none",
                    borderTop: "none",
                    borderBottom: "1px solid #E0E0E0",
                    marginTop: "16px",
                    marginBottom: "16px",
                  }}
                />
                <h6 style={{ fontWeight: "900", marginBottom: "16px" }}>
                  Exclusion
                </h6>
                <ul>
                  {POLICY_DETAILS?.exclusions?.map(
                    (item: string, index: any) => (
                      <li
                        style={{
                          paddingBottom: "16px",
                        }}
                      >
                        <p
                          style={{
                            display: "flex",
                            gap: "8px",
                            color: COLORS.lightgrey,
                          }}
                        >
                          <img
                            src="../images/success_icon.svg"
                            height="18px"
                            style={{ position: "relative", top: "2px" }}
                          />{" "}
                          {item}
                        </p>
                      </li>
                    )
                  )}
                </ul>

                <hr
                  style={{
                    borderLeft: "none",
                    borderRight: "none",
                    borderTop: "none",
                    borderBottom: "1px solid #E0E0E0",
                    marginTop: "16px",
                    marginBottom: "16px",
                  }}
                />
                <h6 style={{ fontWeight: "900", marginBottom: "16px" }}>
                  Plan Brochures/Document
                </h6>
                <ul>
                  <li
                    style={{
                      paddingBottom: "16px",
                    }}
                  >
                    <p
                      style={{
                        display: "flex",
                        gap: "8px",
                        color: COLORS.lightgrey,
                      }}
                      onClick={() =>
                        download(
                          `${data?.quotationDetail?.productDetails?.brochure}`
                        )
                      }
                    >
                      Brochure{" "}
                      <img src="../images/download-icon.svg" width="24px" />
                    </p>
                  </li>
                  {isEmpty(
                    data?.quotationDetail?.premiumDetails?.EBI_PDF_url
                  ) ? null : (
                    <li
                      style={{
                        paddingBottom: "16px",
                      }}
                    >
                      <p
                        style={{
                          display: "flex",
                          gap: "8px",
                          color: COLORS.lightgrey,
                        }}
                        onClick={() => {
                          download(
                            `${data?.quotationDetail?.premiumDetails?.EBI_PDF_url}`
                          );
                        }}
                      >
                        <img
                          src="./images/cross-icon.svg"
                          height="18px"
                          style={{ position: "relative", top: "2px" }}
                        />{" "}
                        Benefit Illustration{" "}
                        <img src="../images/download-icon.svg" width="24px" />
                      </p>
                    </li>
                  )}
                </ul>
              </Grid>
            </Grid>
          </Box>

          <Box
            className="footerbar center"
            sx={{ justifyContent: "center !important" }}
          >
            <JDSButtons
              text={"Continue"}
              variant={"text"}
              className={"primaryBtn large"}
              onClick={() => {
                // if (
                //   data?.quotationDetail?.productDetails?.id ===
                //     EProductId.ICICI_Pru_Gold_Deferred_Income ||
                //   data?.quotationDetail?.productDetails?.id ===
                //     EProductId.ICICI_Pru_Gold_Immediate_Income_with_Booster ||
                //   data?.quotationDetail?.productDetails?.id ===
                //     EProductId.ICICI_Pru_Gift_Pro_Level_Income ||
                //   data?.quotationDetail?.productDetails?.id ===
                //     EProductId.ICICI_Pru_Gold_Immediate_Income ||
                //   (data?.quotationDetail?.productDetails?.id ===
                //     EProductId.ICICI_Pru_Gift_Pro_Increasing_Income &&
                //     data?.quotationDetail?.premiumDetails?.EBI_PDF_url)
                // ) {
                //   setOpenEbi(true);
                // } else {
                BUY_ACTION(data?.quotationDetail);
                // }
              }}
              fullWidth={true}
            />
            {/* <JDSButtons
              className="primaryBtn "
              text={
                <>
                  <DownloadIcon />
                </>
              }
              variant="text"
              onClick={() => {
                download(data?.quotationDetail?.premiumDetails?.EBI_PDF_url);
              }}
              fullWidth={false}
            /> */}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default MNonTermPolicyDetails;
