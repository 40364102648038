import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Route, Routes } from "react-router-dom";
import MNonTermNavbar from "../../../../Component/Amaze/NonTerm/MNonTermNavbar/MNonTermNavbar";
import NonTermNavbar from "../../../../Component/Amaze/NonTerm/NonTermNavbar/NonTermNavbar";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { NON_TERM_ROUTES } from "../../../Path/NonTermRoute";
import TATANonTermBasicDetailsContainer from "../../../../Container/Non-term/Proposal/TATA/NonTermBasicDetailsContainer";
import NonTermLeftSidebarProposerDetails from "../../../../Component/Amaze/NonTerm/NonTermLeftSidebar/NonTermLeftSidebarProposerDetails";

function TATARoutes() {
  const isMobile = useIsMobile();

  const TATA_Routes = () => {
    return (
      <Routes>
        <Route
          path={NON_TERM_ROUTES.BASIC_DETAILS}
          element={<TATANonTermBasicDetailsContainer />}
        />
      </Routes>
    );
  };

  return (
    <>
      {isMobile ? (
        <Box className="proposalWrapperMobile">
          <MNonTermNavbar />
          <Grid container spacing={0} className="d-non">
            <Grid size={12} className="proposalContentSection">
              {TATA_Routes()}
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box className="proposalWrapper">
          <NonTermNavbar />
          <Grid container spacing={3} className="pb-0">
            <NonTermLeftSidebarProposerDetails />
            <Grid size="auto" className="proposalContentSection">
              {TATA_Routes()}
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}

export default TATARoutes;
