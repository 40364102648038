import {
  EVButton,
  EVSelectDropdown,
  EVTextField,
} from "@evervent_pvt_ltd/ui-kit";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useEffect, useState } from "react";
import QuoteNavbar from "../../../../Component/Desktop/Common/QuoteNavbar/QuoteNavbar";
import NonTermQuoteCard from "../../../../Component/Desktop/NonTerm/NonTermQuoteCard/NonTermQuoteCard";
import Loader from "../../../../Component/Global/Loader/Loader";
import { useAppSelector } from "../../../../Store/hooks";
import { COLORS } from "../../../../SupportingFiles/colors";
import {
  calculateAge,
  formatNumberToLakhOrCrores,
  formatToCurrency,
} from "../../../../SupportingFiles/HelpingFunction";
import InvestmentReturnCalulator from "./InvestmentReturnCalculator/InvestmentReturnCalulator";
import NonTermModifyDetails from "./NonTermModifyDetails/NonTermModifyDetails";

function NonTermQuote({
  updateMasterState,
  quoteListData,
  applyInvestmentAmount,
  BUY_ACTION,
  payUptoOption,
  minInvestmentAmount,
  maxInvestmentAmount,
  validateInvestmentAmount,
  setQuoteLoader,
  GET_PRODUCT_LIST,
}: {
  quoteListData: any[];
  updateMasterState: Function;
  applyInvestmentAmount: Function;
  BUY_ACTION: any;
  payUptoOption: any;
  minInvestmentAmount: number;
  maxInvestmentAmount: number;
  validateInvestmentAmount: boolean;
  setQuoteLoader: Function;
  GET_PRODUCT_LIST: Function;
}) {
  const {
    ADD_FORM,
    DROPDOWN_DATA,
    QUOTE_LOADER,
    QUOTE_FIELDS_DATA,
    UPDATE_RESPONSE,
    ADD_FORM_RESPONSE,
  } = useAppSelector((state) => state.NonTerm);
  const [openModifyDetails, setOpenModifyDetails] = useState<boolean>(false);
  const [openInvstReturnCalc, setOpenInvstReturnCalc] =
    useState<boolean>(false);

  useEffect(() => {
    if (QUOTE_LOADER) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [QUOTE_LOADER]);

  return (
    <Box className="quoteWrapper" gap={"24px"}>
      <NonTermModifyDetails
        open={openModifyDetails}
        setOpen={setOpenModifyDetails}
        setQuoteLoader={setQuoteLoader}
        GET_PRODUCT_LIST={GET_PRODUCT_LIST}
      />
      <InvestmentReturnCalulator
        open={openInvstReturnCalc}
        setOpen={setOpenInvstReturnCalc}
      />
      <QuoteNavbar quoteListData={quoteListData} />
      {QUOTE_LOADER ? (
        <Box
          sx={{
            backgroundColor: "rgba(20,20,20, 0.9)",
            height: "100%",
            width: "100%",
            position: "fixed",
            top: "0px",
            zIndex: "991",
            flexDirection: "column",
            gap: "16px",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader size="large" />
            <h5
              style={{
                color: COLORS.white,
                textAlign: "center",
                marginTop: "4px",
              }}
            >
              Fetching best <br /> Insurance Policies for you...
            </h5>
          </Box>
        </Box>
      ) : null}

      <Grid container spacing={3} className="row" alignItems="flex-start">
        <Grid size={12}>
          <Box
            sx={{
              backgroundColor: "#ffffff",
              borderRadius: "24px",
              padding: "24px 12px",
            }}
          >
            <Grid
              container
              columnSpacing={3}
              rowSpacing={0}
              alignItems="center"
              marginBottom={"16px"}
              className="row"
            >
              {/* member Info */}
              <Grid size="auto">
                <Box
                  position="relative"
                  paddingRight="120px"
                  marginBottom="8px"
                >
                  <Box
                    sx={{
                      position: "absolute",
                      right: "0",
                      top: "-4px",
                      zIndex: "9",
                    }}
                  >
                    <EVButton
                      id="editDetails"
                      size="large"
                      className="tertiaryBtn"
                      text="Edit Details"
                      fullWidth={false}
                      onClick={() => {
                        setOpenModifyDetails(true);
                      }}
                    />
                  </Box>
                  <h6
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    Member covered
                  </h6>
                  <p style={{ color: "#595959", fontSize: "14px" }}>
                    {UPDATE_RESPONSE.annual_income === ""
                      ? formatToCurrency(ADD_FORM.annualIncome.value)
                      : formatToCurrency(UPDATE_RESPONSE.annual_income)}
                    ,{" "}
                    {UPDATE_RESPONSE?.gender === ""
                      ? ADD_FORM.gender.value === "M"
                        ? "Male"
                        : "Female"
                      : UPDATE_RESPONSE?.gender === "M"
                      ? "Male"
                      : "Female"}
                    ,{" "}
                    {UPDATE_RESPONSE.age === 0
                      ? `${calculateAge(ADD_FORM.age.value)} yrs`
                      : `${UPDATE_RESPONSE.age} yrs`}
                  </p>
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              size={12}
              columnSpacing={3}
              rowSpacing={0}
              alignItems="center"
              className="row"
            >
              <Grid size={12}>
                <ul style={{ display: "flex", gap: "24px", flexWrap: "wrap" }}>
                  <li
                    style={{
                      flex: 1,
                      position: "relative",
                      minWidth: "170px",
                    }}
                  >
                    <EVTextField
                      id="investAmount"
                      title="Want to invest"
                      value={ADD_FORM.investAmount.value}
                      value_update={updateMasterState}
                      attrName={"investAmount"}
                      warn_status={false}
                      error_message="Enter amount to invest"
                      max_length={7}
                      validation_type="NUMBER"
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        top: "0px",
                        right: "0px",
                      }}
                    >
                      <EVButton
                        id="apply"
                        size="small"
                        className="secondaryBtn"
                        text="Apply"
                        fullWidth={false}
                        onClick={() => applyInvestmentAmount()}
                      />
                    </Box>
                    {validateInvestmentAmount ? (
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          color: "#EA4559",
                          fontSize: "11px",
                          marginTop: "3px",
                        }}
                      >
                        Enter {minInvestmentAmount} -{" "}
                        {formatNumberToLakhOrCrores(maxInvestmentAmount)}
                      </span>
                    ) : null}
                  </li>
                  <li style={{ flex: 1 }}>
                    <EVSelectDropdown
                      id="investmentMode"
                      title="Investment Mode"
                      value={ADD_FORM.investmentMode.value}
                      value_update={updateMasterState}
                      attrName={"investmentMode"}
                      data={DROPDOWN_DATA.investmentMode}
                      warn_status={false}
                      error_message="Select Investment Mode"
                    />
                  </li>
                  <li style={{ flex: 1 }}>
                    <EVSelectDropdown
                      id="policyTerm"
                      title="Policy Term"
                      value={QUOTE_FIELDS_DATA.policyTerm}
                      value_update={updateMasterState}
                      attrName={"policyTerm"}
                      data={DROPDOWN_DATA.POLICY_TERM}
                      warn_status={false}
                      error_message="Select Policy Term"
                    />
                  </li>
                  {ADD_FORM.investmentMode.value === "5" ? null : (
                    <li style={{ flex: 1 }}>
                      <EVSelectDropdown
                        id="payUpto"
                        title="Pay upto"
                        value={QUOTE_FIELDS_DATA.payUpto}
                        value_update={updateMasterState}
                        attrName={"payUpto"}
                        data={payUptoOption}
                        warn_status={false}
                        error_message="Select Pay Upto duration"
                      />
                    </li>
                  )}
                  <li style={{ flex: 1 }}>
                    <EVSelectDropdown
                      id="incomePeriod"
                      title="Income Period"
                      value={QUOTE_FIELDS_DATA.incomePeriod}
                      value_update={updateMasterState}
                      attrName={"incomePeriod"}
                      data={DROPDOWN_DATA.INCOME_PERIOD}
                      warn_status={false}
                      error_message="Select Income Period"
                    />
                  </li>
                  <li style={{ flex: 1 }}>
                    <EVSelectDropdown
                      id="returnType"
                      title="Return Type"
                      value={QUOTE_FIELDS_DATA.returnType}
                      value_update={updateMasterState}
                      attrName={"returnType"}
                      data={DROPDOWN_DATA.RETURN_TYPE}
                      warn_status={false}
                      error_message="Select Return Type"
                    />
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {/* Addon Sidebar */}
        <Grid size="auto" sx={{ maxWidth: "308px" }}>
          <Box
            sx={{
              backgroundColor: "#fff",
              borderRadius: "24px",
              padding: "24px",
            }}
          >
            <h6
              style={{
                fontWeight: "900",
                marginBottom: "16px",
                marginTop: "16px",
              }}
            >
              Calculator
            </h6>
            <Box
              sx={{
                background: "rgba(245, 245, 245, 1)",
                borderRadius: "16px",
                padding: "12px",
              }}
            >
              <Link
                sx={{
                  display: "inline-flex",
                  gap: "12px",
                  alignItems: "center",
                }}
                onClick={() => setOpenInvstReturnCalc(true)}
              >
                <img src="./images/calculator_img.svg" alt="" height={"32px"} />
                <h6
                  style={{
                    fontWeight: 700,
                    lineHeight: "24px",
                    color: "rgba(20, 20, 20, 1)",
                  }}
                >
                  Investment Return{" "}
                  <span style={{ display: "block", color: COLORS.darkgold }}>
                    Calculate
                  </span>
                </h6>
              </Link>
            </Box>
          </Box>
        </Grid>

        <Grid size="grow">
          <Box marginBottom={"24px"}>
            <Grid container size={12} columnSpacing={2}>
              <Grid size="grow">
                <h5 style={{ fontWeight: "bold" }}>
                  {quoteListData?.length > 0
                    ? (quoteListData?.length === 1 ? "0" : "") +
                      `${quoteListData?.length} Plans Found`
                    : "No Plan Found"}
                </h5>
              </Grid>
              <Grid size="grow" textAlign={"right"}>
                <p>
                  <small style={{ color: "#595959" }}>
                    Order ID: {ADD_FORM_RESPONSE?.quote_no}
                  </small>
                </p>
              </Grid>
            </Grid>
          </Box>
          {!QUOTE_LOADER ? (
            quoteListData && quoteListData.length > 0 ? (
              quoteListData.map((data: any, index: number) => (
                <NonTermQuoteCard
                  quote_data={data}
                  index={index}
                  BUY_ACTION={BUY_ACTION}
                />
              ))
            ) : (
              <Box className="no-quote-box" sx={{ textAlign: "center" }}>
                <img src="../images/no_quotes_icon.svg" alt="" />
                <h5 className="my-3">No Matches: Keep Exploring!</h5>
                <p>Sorry, no results this time. Let's try something else.</p>
              </Box>
            )
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
}

export default NonTermQuote;
