import { EVButton } from "@evervent_pvt_ltd/ui-kit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../Store/hooks";
import "./NonTermFooter.scss";

const NonTermFooter = ({
  forward,
  value,
  disableButton,
  backward,
  attrName,
  value_update,
  textName = "Continue",
  loader = false,
}: {
  forward?: Function;
  value?: any;
  backward?: Function;
  attrName?: any;
  value_update?: any;
  disableButton?: boolean;
  textName?: string;
  loader?: boolean;
}) => {
  const { QUOTE_LOADER } = useAppSelector((state) => state.NonTerm);
  const navigate = useNavigate();
  const handleClick = () => {
    if (!QUOTE_LOADER) {
      navigate(-1);
    }
  };
  return (
    <Box className="proposalFooter">
      <Grid container spacing={2}>
        <Grid size={8} offset={3} className="footerInner" alignItems="center">
          <Link
            className={`backStep ${QUOTE_LOADER ? "disabled" : ""}`}
            onClick={() => {
              if (typeof backward === "function") {
                backward(); // Call backward function if it exists
              } else {
                handleClick(); // Fallback to handleClick if backward is undefined or not a function
              }
            }}
            href={QUOTE_LOADER ? undefined : "#"}
            style={QUOTE_LOADER ? { pointerEvents: "none" } : {}}
          >
            <ArrowBackIcon /> {value === 0 ? " Back to Quotes" : "Back"}
          </Link>

          {/* <Button
              className="greenMdBtn"
              onClick={() => {
                value_update(attrName, value + 1);
              }}
            >
              {value === 7 ? " Make Payment" : "Continue"}
            </Button> */}
          <Box className="ctaBtn">
            <EVButton
              id="textName"
              text={textName}
              className="primaryBtns"
              size="large"
              onClick={() => {
                forward && forward();
                value_update && value_update(attrName, value + 1);
              }}
              loader={loader}
              disabled={disableButton}
            />
          </Box>
          {/* This button will display only on preview page */}
          {/* <Buttons class_name='regularPrimaryBtn ml-4' text_name='Test Link'/> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default NonTermFooter;
