import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import { Box, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Grid from "@mui/material/Grid2";
import { useEffect, useState } from "react";
import BackLeft from "../../../../Component/InputFields/BackLeft";
import BorderCheckBox from "../../../../Component/InputFields/BorderCheckBox/BorderCheckBox";
import CustomCheckbox from "../../../../Component/InputFields/CustomCheckbox/CustomCheckbox";
import DatePickerInput from "../../../../Component/InputFields/DatePicker/DatePicker";
import JDSButtons from "../../../../Component/InputFields/JDSButtons/JDSButtons";
import SearchSelectDropdown from "../../../../Component/InputFields/SearchSelectDropdown/SearchSelectDropdown";
import "../../../../SCSS/ProductForms.scss";
import { useAppSelector } from "../../../../Store/hooks";
import { COLORS } from "../../../../SupportingFiles/colors";
import { THealthAddForm } from "../../../../types/Health/THealthSlice";

function HealthForm({
  open,
  updateMasterState,
  formData,
  validateForm,
  setOpen,
  loader,
  EVENT_TRACKER,
}: {
  open: any;
  setOpen: Function;
  updateMasterState: Function;
  formData: THealthAddForm;
  validateForm: Function;
  EVENT_TRACKER: Function;
  loader: boolean;
}) {
  const steps = ["Family detail", "Family age's"];
  const [page_status, setPageStatus] = useState<number>(0);
  useEffect(() => {
    setPageStatus(page_status);
  }, [page_status]);
  const { DROPDOWN_DATA } = useAppSelector((state) => state.Health);
  return (
    <>
      <Modal open={open}>
        <Box className="modalWrapper">
          <Box className="modalContent xlWidth">
            <Grid className="topbar" container alignItems="center">
              {page_status === 1 ? (
                <Grid size={6}>
                  <BackLeft
                    onClick={() => {
                      setPageStatus(0);
                    }}
                  />
                </Grid>
              ) : null}
              <Grid size={page_status === 1 ? 6 : 12} textAlign={"right"}>
                <Button
                  disableRipple
                  className="closePopup"
                  onClick={() => {
                    setOpen(false);
                  }}
                />
              </Grid>
            </Grid>

            <Box display="flex" alignItems={"center"} flexDirection={"column"}>
              <Box className="form_steps mb-10" width="783px">
                <Stepper activeStep={page_status} alternativeLabel>
                  {steps.map((label: any) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>

              {/* Step Select Members */}
              {page_status === 0 ? (
                <Box width="616px" display="">
                  <Grid container spacing={2} alignItems="flex-start">
                    <Grid size={12} textAlign="center">
                      <h2
                        style={{ fontSize: "27px", lineHeight: "32px" }}
                        className="mb-6"
                      >
                        Select the family members you want to insure
                      </h2>
                    </Grid>
                    <Grid size={6}>
                      <BorderCheckBox
                        title="One Adult"
                        attrName="spouse_status"
                        value_update={() =>
                          updateMasterState("spouse_status", false)
                        }
                        value={!formData?.spouse_status}
                      />
                    </Grid>
                    <Grid size={6}>
                      <BorderCheckBox
                        title="Two Adults"
                        attrName="spouse_status"
                        value_update={() =>
                          updateMasterState("spouse_status", true)
                        }
                        value={formData?.spouse_status}
                      />
                    </Grid>
                    <Grid size={6}>
                      <Box
                        sx={{
                          border: "1px solid #E0E0E0",
                          borderRadius: "12px",
                          padding: "6px 14px",
                          position: "relative",
                        }}
                      >
                        {formData.son_status ? (
                          <Box
                            position="absolute"
                            right="8px"
                            top="11px"
                            display="flex"
                            alignItems="center"
                            gap="8px"
                            zIndex="9"
                          >
                            <Box
                              onClick={() =>
                                updateMasterState("son_subtract", "")
                              }
                              height="20px"
                              width="20px"
                              color={COLORS.darkgold}
                              textAlign="center"
                              sx={{ cursor: "pointer" }}
                            >
                              <RemoveRoundedIcon
                                sx={{ width: "21px", height: "21px" }}
                              />
                            </Box>
                            <Box
                              height="20px"
                              width="20px"
                              color={COLORS.darkgold}
                              borderRadius="40px"
                              textAlign="center"
                              sx={{ backgroundColor: "#FEF7E9" }}
                            >
                              {formData.son_count.value}
                            </Box>
                            <Box
                              onClick={() => updateMasterState("son_add", "")}
                              height="20px"
                              width="20px"
                              color={COLORS.darkgold}
                              textAlign="center"
                              sx={{ cursor: "pointer" }}
                            >
                              <AddRoundedIcon
                                sx={{ width: "21px", height: "21px" }}
                              />
                            </Box>
                          </Box>
                        ) : null}
                        <CustomCheckbox
                          label="Son"
                          attrName="son_status"
                          value_update={updateMasterState}
                          value={formData?.son_status}
                          defaultChecked={false}
                          disabled={false}
                        />
                      </Box>
                    </Grid>
                    <Grid size={6}>
                      <Box
                        sx={{
                          border: "1px solid #E0E0E0",
                          borderRadius: "12px",
                          padding: "6px 14px",
                          position: "relative",
                        }}
                      >
                        {formData?.daughter_status ? (
                          <Box
                            position="absolute"
                            right="8px"
                            top="11px"
                            display="flex"
                            alignItems="center"
                            gap="8px"
                            zIndex="9"
                          >
                            <Box
                              onClick={() =>
                                updateMasterState("daughter_subtract", "")
                              }
                              height="20px"
                              width="20px"
                              color={COLORS.darkgold}
                              textAlign="center"
                              sx={{ cursor: "pointer" }}
                            >
                              <RemoveRoundedIcon
                                sx={{ width: "21px", height: "21px" }}
                              />
                            </Box>
                            <Box
                              height="20px"
                              width="20px"
                              color={COLORS.darkgold}
                              borderRadius="40px"
                              textAlign="center"
                              sx={{ backgroundColor: "#FEF7E9" }}
                            >
                              {formData.daughter_count.value}
                            </Box>
                            <Box
                              onClick={() =>
                                updateMasterState("daughter_add", "")
                              }
                              height="20px"
                              width="20px"
                              color={COLORS.darkgold}
                              textAlign="center"
                              sx={{ cursor: "pointer" }}
                            >
                              <AddRoundedIcon
                                sx={{ width: "21px", height: "21px" }}
                              />
                            </Box>
                          </Box>
                        ) : null}
                        <CustomCheckbox
                          label="Daughter"
                          attrName="daughter_status"
                          value_update={updateMasterState}
                          value={formData?.daughter_status}
                          defaultChecked={false}
                          disabled={false}
                        />
                      </Box>
                    </Grid>

                    <Grid size={12} textAlign="center">
                      <Box
                        maxWidth="312px"
                        marginLeft="auto"
                        marginRight="auto"
                        marginTop="24px"
                      >
                        <JDSButtons
                          loader={loader}
                          className="primaryBtns large"
                          text="Continue"
                          variant="contained"
                          onClick={() => {
                            setPageStatus(1);
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ) : null}
              {/* Step Select Age */}
              {page_status === 1 ? (
                <Box width="616px" display="">
                  <h2
                    className="mb-6"
                    style={{
                      fontSize: "27px",
                      lineHeight: "32px",
                      textAlign: "center",
                    }}
                  >
                    Select age of your family members
                  </h2>
                  <Box
                    alignItems="flex-start"
                    maxHeight="228px"
                    marginLeft="auto"
                    marginRight="auto"
                    paddingBottom="12px"
                    sx={{
                      overflowY: "auto",
                    }}
                  >
                    <Grid
                      container
                      justifyContent="center"
                      spacing={3}
                      className="mb-2"
                    >
                      <Grid size={6}>
                        <DatePickerInput
                          title="1st Adult Date of birth (DOB)"
                          attrName={"self_dob"}
                          value={formData?.self_dob?.value}
                          onChange={updateMasterState}
                          warn_status={formData?.self_dob?.warning}
                          min_date={110}
                          max_date={18}
                          date_validation_type="YEARS"
                          default_date={new Date("1990-01-01")}
                          error_message={"Select 1st adult DOB"}
                        />
                      </Grid>
                    </Grid>
                    <Grid container justifyContent="center" spacing={3}>
                      {formData.spouse_status ? (
                        <>
                          <Grid size={6}>
                            <DatePickerInput
                              title="2nd Adult Date of birth (DOB)"
                              attrName={"spouse_dob"}
                              onChange={updateMasterState}
                              value={formData?.spouse_dob?.value}
                              warn_status={formData?.spouse_dob?.warning}
                              min_date={110}
                              max_date={18}
                              date_validation_type="YEARS"
                              default_date={new Date("1990-01-01")}
                              error_message="Select 2nd adult DOB"
                            />
                          </Grid>
                          <Grid size={6}>
                            <SearchSelectDropdown
                              title="Relationship with 1st Adult"
                              value={formData?.spouse_relationship?.value}
                              attrName={"spouse_relationship"}
                              value_update={updateMasterState}
                              options={DROPDOWN_DATA.ADULT2_RELATIONSHIP}
                              warn_status={
                                formData?.spouse_relationship?.warning
                              }
                              error_message={
                                "Select relationship with 1st adult"
                              }
                            />
                          </Grid>
                        </>
                      ) : null}
                      {parseInt(formData.son_count.value) +
                        parseInt(formData.daughter_count.value) >=
                      1 ? (
                        <Grid size={6}>
                          <DatePickerInput
                            title="Child 1 Date of birth (DOB)"
                            attrName={"child_one_dob"}
                            value={formData?.child_one_dob?.value}
                            onChange={updateMasterState}
                            warn_status={formData?.child_one_dob?.warning}
                            min_date={25}
                            max_date={0}
                            date_validation_type="YEARS"
                            default_date={new Date("2010-01-01")}
                            error_message="Select 1st child DOB"
                          />
                        </Grid>
                      ) : null}
                      {parseInt(formData.son_count.value) +
                        parseInt(formData.daughter_count.value) >=
                      2 ? (
                        <Grid size={6}>
                          <DatePickerInput
                            title="Child 2 Date of birth (DOB)"
                            attrName={"child_two_dob"}
                            value={formData?.child_two_dob?.value}
                            onChange={updateMasterState}
                            warn_status={formData?.child_two_dob?.warning}
                            min_date={25}
                            max_date={0}
                            date_validation_type="YEARS"
                            error_message="Select 2nd child DOB"
                            default_date={new Date("2010-01-01")}
                          />
                        </Grid>
                      ) : null}
                      {parseInt(formData.son_count.value) +
                        parseInt(formData.daughter_count.value) >=
                      3 ? (
                        <Grid size={6}>
                          <DatePickerInput
                            title="Child 3 Date of birth (DOB)"
                            attrName={"child_three_dob"}
                            value={formData?.child_three_dob?.value}
                            onChange={updateMasterState}
                            warn_status={formData?.child_three_dob?.warning}
                            min_date={25}
                            max_date={0}
                            date_validation_type="YEARS"
                            error_message="Select 3rd child DOB"
                            default_date={new Date("2010-01-01")}
                          />
                        </Grid>
                      ) : null}
                      {parseInt(formData.son_count.value) +
                        parseInt(formData.daughter_count.value) ===
                      4 ? (
                        <Grid size={6}>
                          <DatePickerInput
                            title="Child 4 Date of birth (DOB)"
                            attrName={"child_four_dob"}
                            value={formData?.child_four_dob?.value}
                            onChange={updateMasterState}
                            warn_status={formData?.child_four_dob?.warning}
                            min_date={25}
                            max_date={0}
                            date_validation_type="YEARS"
                            error_message="Select 4th child DOB"
                            default_date={new Date("2010-01-01")}
                          />
                        </Grid>
                      ) : null}
                    </Grid>
                  </Box>
                  <Box
                    maxWidth="312px"
                    marginLeft="auto"
                    marginRight="auto"
                    marginTop="24px"
                  >
                    <JDSButtons
                      className="primaryBtns large"
                      text="Submit"
                      variant="contained"
                      onClick={validateForm}
                      loader={loader}
                    />
                  </Box>
                </Box>
              ) : null}
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default HealthForm;
