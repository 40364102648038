import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import {
  blog_data,
  blogsResponsive,
} from "../../../../Component/Desktop/Common/BlogArray/BLogArray";
import Footer from "../../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../../Component/Desktop/Common/NavBar/NavBar";
import MFooter from "../../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../../Component/Mobile/Common/MNavBar/MNavBar";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import "./../BlogsPage.scss";
import HelmetComponent from "../../../../SupportingFiles/HelmetComponent";
import { FRONTEND_DOMAIN } from "../../../../URLCollection/Domain";

const BlogUnderstandingDeductible = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const current_blog_data = {
    id: "understanding-deductibles-and-co-pays",
    blogtitle: "Understanding Deductibles and Co-pays",
    img_url: "/images/blog6.webp",
    main_desc: (
      <>
        <p className="mb-4">
          Are you aware how much of your bill will be paid by the insurance
          company if you are hospitalized or your car meets with an accident?
          When this question is asked, majority are surprised and they say, “Jo
          bhi bill hoga, insurance company pay karegi”. But that may not be
          true, hence it is extremely important to read the fine print of the
          insurance policy and understand in detail what is payable by the
          insurance company and what you must pay from your own pocket. The
          thing that most affects us is what is the final amount the insurer
          pays of the hospital bill and what is the amount that we have pay out
          of our own pockets
        </p>
        <p>
          Insurance companies are in the business of covering the risk, both
          Insurance for life and health and insurance for our assets. At the
          same time, all insurance companies like to share the risk with the
          insured, especially when the risk is high. <br />
          <br />
          For instance, a senior citizen is at a much higher health risk than a
          young person. He is more likely to visit a hospital and avail
          healthcare. And for insurance companies risk sharing means cost
          sharing. This is where it becomes important to understand two key
          terms: <span style={{ fontWeight: 700 }}> Co-payment</span> and
          <span style={{ fontWeight: 700 }}> Deductibles</span>.
        </p>
      </>
    ),
    blog_full_desc: (
      <>
        <h6 className="mb-3">Co-payment (Co-pay) </h6>
        <p className="mb-5">
          Co-payment (Co-pay) implies that every time you make a claim you will
          be bearing some part of the expenses. It is a fixed percentage decided
          before the beginning of the policy period and remains constant
          throughout.{" "}
        </p>
        <p className="mb-5">
          For example, if you claim ₹ 50,000/- under your health insurance with
          10% co-pay, you will pay ₹ 5,000/- and the insurance company will pay
          the balance ₹ 45,000 (Claim amount of 50,000 – Co-pay of 5,000).
        </p>

        <h6 className="mb-3">Deductible </h6>
        <p className="mb-5">
          Deductible is another way of sharing the cost with the insured. It is
          a fixed amount that the insured party has to pay before the benefits
          of the insurance policy kick in. Simply speaking, if your deductible
          is ₹ 1 Lakh, until you spend that much amount the insurance company
          would not pay a single rupee. If you claim ₹ 5 Lakh, you will first
          pay ₹ 1 Lakh and the insurance company will then take care of the
          balance ₹ 4 Lakh. Deductible can be calculated in two ways:
        </p>
        <ul className="mb-5">
          <li>
            Insurance companies may apply a fixed deductible to each, and every
            claim made.{" "}
          </li>
          <li>
            The other way is to calculate the aggregate deductible during the
            policy year.
          </li>
        </ul>
        <p className="mb-5">
          Let us understand this with an example, you make 3 claims during the
          policy year of - ₹ 9,000/-, ₹ 15,000/- and ₹ 20,000/- respectively and
          your insurer applies a deductible of ₹ 10,000/- on every claim. The
          following is how the calculations will look:
        </p>
        <ul className="mb-5">
          <li>
            <span>First claim of ₹ 9,000/-:</span> The insurer will pay nothing.
            As the claim amount is less than the deductible amount of ₹
            10,000/-.
          </li>
          <li>
            <span>Second claim of ₹ 15,000/-:</span> You pay the deductible of ₹
            10,000/- and the insurer will pay the balance ₹ 5,000/-.
          </li>
          <li>
            <span>Third claim of ₹ 20,000/-:</span> You pay the deductible of ₹
            10,000/- and the insurer will pay the balance ₹ 10,000/.
          </li>
        </ul>
        <p className="mb-3">
          In summary, you pay ₹ 29,000/- from your pocket of the total ₹ 44,000
          claimed.
        </p>
        <p>
          Let us now suppose the insurer calculates deductible as an aggregate
          amount of ₹ 20,000/- and yet again, you claim thrice during the year.
          In this scenario –
        </p>
        <ul className="mb-5">
          <li>
            <span>First claim of ₹ 9,000/-:</span>
            You will pay everything.
          </li>
          <li>
            <span>Second claim of ₹ 15,000/-:</span> and the insurer will share
            the rest. You see, you pay your aggregate deductible of ₹ 20,000/-(₹
            9,000/- from first claim and ₹ 11,000/- from the second claim).
          </li>
          <li>
            <span>Third claim of ₹ 20,000/-:</span> You will not pay anything.
            The entire ₹ 20,000/- is covered by the insurer.
          </li>
        </ul>
        <p className="mb-3">
          You end up paying ₹ 20,000/- from your pocket of the total ₹ 44,000/-
          claimed.
        </p>
        <p className="mb-5">
          The deductible can vary from plan to plan but on an average, aggregate
          deductible is found to be more cost-friendly for you. At{" "}
          <span style={{ fontWeight: "900" }}>
            Jio Insurance Broking Limited
          </span>{" "}
          we assist you understand the details of every product and we recommend
          best fit products in line with your needs.
        </p>
      </>
    ),
  };

  const blogsJsonLd = JSON.stringify(
    {
      "@context": "https://schema.org",
      "@type": "Article",
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": `${FRONTEND_DOMAIN}/blog/understanding-deductibles-and-co-pays`,
      },
      headline: current_blog_data?.blogtitle,
      image: `${FRONTEND_DOMAIN}${current_blog_data?.img_url}`,
      author: {
        "@type": "Organization",
        name: "Jio Insurance Broking Ltd.",
        url: `${FRONTEND_DOMAIN}/about-us`,
      },
      datePublished: "2024-10-22",
      dateModified: "2024-10-22",
    },
    null,
    2
  ); // Added 'null, 2' to format it with indentation

  return (
    <Box className="blogsWrapper">
      <HelmetComponent
        title="Everything you need to know about deductibles and co-pays"
        description="Understand deductibles and co-pays in health insurance. Learn how these factors affect your overall costs and coverage for medical expenses & treatments"
        showCanonicalTag={true}
        scripts={[blogsJsonLd]}
      />
      {isMobile ? <MNavBar /> : <NavBar />}
      <Box className="blogsdetail-sec">
        <Grid
          container
          className="row"
          columnSpacing={3}
          justifyContent={"center"}
        >
          <Grid size={12}>
            <h2>{current_blog_data?.blogtitle}</h2>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              marginBottom={"24px"}
            >
              <p
                style={{
                  fontSize: "14px",
                  lineHeight: "normal",
                  textTransform: "uppercase",
                  fontWeight: "700",
                }}
              >
                Sep 30, 2024
              </p>
              <Box display={"flex"} gap={"8px"}>
                <span className="tag">Insurance</span>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          className="row"
          columnSpacing={3}
          justifyContent={"center"}
        >
          <Grid size={6} marginBottom={"24px"}>
            <Box className="img-overlay">
              <Box className="img-bg">
                <img src={current_blog_data?.img_url} alt="" />
              </Box>
            </Box>
          </Grid>
          <Grid size={6} marginBottom={"24px"}>
            {current_blog_data?.main_desc}
          </Grid>
          <Grid size={12} marginBottom={"24px"}>
            {current_blog_data?.blog_full_desc}
          </Grid>
        </Grid>
      </Box>
      <Box className="blogCard-sec mb-pr-0">
        <Grid
          container
          className="row"
          columnSpacing={3}
          justifyContent={"center"}
        >
          <Grid size={12} marginBottom={"24px"} className="mb-pr-0">
            <h4>You May Also Like</h4>
          </Grid>
          <Grid size={12} className="mb-pr-0">
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={false}
              responsive={blogsResponsive}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000}
              arrows={false}
              customTransition="transform 0.7s ease-in-out"
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
              partialVisible={true}
            >
              {blog_data.map((item, index) => (
                <Box>
                  <Box
                    className="blog-card"
                    sx={{
                      boxShadow: "0px 4px 16px rgba(0,0,0,0.08)",
                      borderRadius: "12px",
                      marginRight: "16px",
                    }}
                  >
                    <img src={item?.img_url} alt="" />
                    <Box className="card-info">
                      <h5 className="mb-1">{item.blogtitle}</h5>
                      <p className="mb-3">{item.blog_desc}</p>
                      <Link
                        className="cont-read-link"
                        onClick={() => {
                          navigate(item?.id);
                          window.scrollTo(0, 0);
                        }}
                      >
                        Continue reading
                      </Link>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Carousel>
          </Grid>
        </Grid>
      </Box>
      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default BlogUnderstandingDeductible;
