import AddRoundedIcon from "@mui/icons-material/AddRounded";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import XIcon from "@mui/icons-material/X";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Link,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { COMMON_ROUTES } from "../../../../Router/Path/CommonRoutes";
import { COLORS } from "../../../../SupportingFiles/colors";
import MPolicyCancellation from "../MPolicyCancellation/MPolicyCancellation";
import "./MFooter.scss";
import { useAppSelector } from "../../../../Store/hooks";
import { ELECTRONIC_INSURANCE_DOMAIN } from "../../../../URLCollection/Domain";
import { detectWebViewEnvironment } from "../../../../SupportingFiles/HelpingFunction";

const MFooter = () => {
  const { TW, Car, Health, Term } = useAppSelector((state) => state);
  const [openCancellationPopup, setOpenCancellationPopup] = useState(false);
  const navigate = useNavigate();

  const [expanded, setExpanded] = useState(true);
  const [expanded2, setExpanded2] = useState(false);
  const [expanded3, setExpanded3] = useState(false);
  const [expanded4, setExpanded4] = useState(false);
  const handleAccordionChange = (set: Function) => {
    set((prevExpanded: any) => !prevExpanded);
  };

  const [redirected, setRedirected] = useState(false);
  useEffect(() => {
    if (
      TW.REDIRECTED ||
      Car.REDIRECTED ||
      Health.REDIRECTED ||
      Term.REDIRECTED
    ) {
      setRedirected(true);
    } else {
      setRedirected(false);
    }
  }, [TW.REDIRECTED, Car.REDIRECTED, Health.REDIRECTED, Term.REDIRECTED]);

  const openPdf = () => {
    const pdfUrl = "/pdfs/JIBL_annual_return_2023_24.pdf";
    window.open(pdfUrl, "_blank");
  };

  return (
    <Box className="mfooter">
      <MPolicyCancellation
        open={openCancellationPopup}
        setOpen={setOpenCancellationPopup}
      />

      <Box
        className="topPart"
        sx={{
          padding: "24px 24px 12px",
        }}
      >
        <Grid container columnSpacing={3}>
          <Grid size={12} marginBottom={"16px"}>
            <Accordion
              onChange={() => handleAccordionChange(setExpanded)}
              sx={{ borderBottom: "1px solid #B5B5B5" }}
              defaultExpanded
            >
              <AccordionSummary
                expandIcon={
                  expanded ? <RemoveRoundedIcon /> : <AddRoundedIcon />
                }
              >
                Jio Insurance Broking Ltd.
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  overflow: "auto",
                }}
              >
                <p
                  style={{
                    fontSize: "16px",
                    lineHeight: "24px",
                    marginTop: "12px",
                  }}
                >
                  Jio Insurance Broking Limited
                  <br />
                  IRDAI License No: 347,
                  <br />
                  Direct Broker (Life & General),
                  <br />
                  Valid upto: 11/03/2025 (Renewable)
                  <br />
                </p>
                {!detectWebViewEnvironment() && (
                  <>
                    <Link
                      style={{
                        fontWeight: 600,
                        color: "rgb(89, 89, 89)",
                        marginTop: "20px",
                        display: "inline-block",
                        fontSize: 16,
                      }}
                      onClick={openPdf}
                    >
                      Investor Relations
                    </Link>
                    <p className="mt-1" style={{ color: "rgb(89, 89, 89)" }}>
                      Annual Return FY 23-24
                    </p>
                  </>
                )}
              </AccordionDetails>
            </Accordion>
            <hr />
          </Grid>
          {redirected ? null : (
            <Grid size={12} marginBottom={"16px"}>
              <Accordion
                onChange={() => handleAccordionChange(setExpanded2)}
                defaultExpanded={false}
                sx={{ borderBottom: "1px solid #B5B5B5" }}
              >
                <AccordionSummary
                  expandIcon={
                    expanded2 ? <RemoveRoundedIcon /> : <AddRoundedIcon />
                  }
                >
                  Get insured
                </AccordionSummary>
                <AccordionDetails>
                  <ul>
                    <li>
                      <Link
                        onClick={() => {
                          navigate(COMMON_ROUTES.PRODUCT_CAR);
                        }}
                      >
                        Car Insurance
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          navigate(COMMON_ROUTES.PRODUCT_TW);
                        }}
                      >
                        Two-Wheeler Insurance
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          navigate(COMMON_ROUTES.PRODUCT_HEALTH);
                        }}
                      >
                        Health Insurance
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          navigate(COMMON_ROUTES.PRODUCT_TERM);
                        }}
                      >
                        Term Insurance
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          navigate(COMMON_ROUTES.PRODUCT_NON_TERM);
                        }}
                      >
                        Investment Plans
                      </Link>
                    </li>
                  </ul>
                </AccordionDetails>
              </Accordion>
              <hr />
            </Grid>
          )}
          <Grid size={12} marginBottom={"16px"}>
            <Accordion
              onChange={() => handleAccordionChange(setExpanded3)}
              defaultExpanded={false}
              sx={{ borderBottom: "1px solid #B5B5B5" }}
            >
              <AccordionSummary
                expandIcon={
                  expanded3 ? <RemoveRoundedIcon /> : <AddRoundedIcon />
                }
              >
                Quick links
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  {redirected ? null : (
                    <>
                      <li>
                        <Link onClick={() => navigate(COMMON_ROUTES.HOME)}>
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link onClick={() => navigate(COMMON_ROUTES.ABOUT_US)}>
                          About Us
                        </Link>
                      </li>
                      <li>
                        <Link onClick={() => navigate(COMMON_ROUTES.CONTACT)}>
                          Contact
                        </Link>
                      </li>
                      <li>
                        <Link onClick={() => navigate(COMMON_ROUTES.CAREER)}>
                          Career
                        </Link>
                      </li>
                    </>
                  )}
                  <li>
                    <Link
                      onClick={() => {
                        navigate(COMMON_ROUTES.PRIVACY_POLICY);
                      }}
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => {
                        navigate(COMMON_ROUTES.TERM_AND_CONDTION);
                      }}
                    >
                      Terms of Use
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => {
                        navigate(COMMON_ROUTES.DISCLAIMER);
                      }}
                    >
                      Legal Disclaimer
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => {
                        navigate(COMMON_ROUTES.REFUND_AND_CANCELLATION);
                      }}
                    >
                      Grievance Redressal Policy
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => setOpenCancellationPopup(true)}>
                      Policy Cancellation & Refund
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => {
                        navigate(COMMON_ROUTES.IRDAI);
                      }}
                    >
                      ISNP Service TAT
                    </Link>
                  </li>
                  {/* {redirected ? */}
                  <li>
                    <Link
                      onClick={() => {
                        navigate(COMMON_ROUTES.BLOG_UNLOCK_THE_BENEFITS);
                      }}
                    >
                      E-Insurance Account
                    </Link>
                  </li>
                  {/* : 
                  null} */}
                </ul>
              </AccordionDetails>
            </Accordion>
            <hr />
          </Grid>
          <Grid size={12} marginBottom={"16px"}>
            <Accordion
              onChange={() => handleAccordionChange(setExpanded4)}
              defaultExpanded={false}
            >
              <AccordionSummary
                expandIcon={
                  expanded4 ? <RemoveRoundedIcon /> : <AddRoundedIcon />
                }
              >
                Contact details
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  <li style={{ marginBottom: "16px" }}>
                    <p
                      style={{
                        fontWeight: 700,
                        marginBottom: "2px",
                      }}
                    >
                      Registered Address
                    </p>
                    <p>
                      3rd Floor, Court House, Lokmanya Tilak Marg, Dhobi Talao,
                      Mumbai - 400 002
                    </p>
                  </li>

                  <li style={{ marginBottom: "16px" }}>
                    <p
                      style={{
                        fontWeight: 700,
                        marginBottom: "2px",
                      }}
                    >
                      CIN
                    </p>
                    <p>U67200MH2006PLC165651</p>
                  </li>
                  <li>
                    <p
                      style={{
                        fontWeight: 700,
                        marginBottom: "2px",
                      }}
                    >
                      Support mail:
                    </p>
                    <Link
                      href="mailto:customer.care@jioinsure.in"
                      style={{ color: "#595959" }}
                    >
                      customer.care@jioinsure.in
                    </Link>
                  </li>
                  {redirected ? null : (
                    <li>
                      <p
                        style={{
                          fontWeight: 700,
                          marginBottom: "2px",
                        }}
                      >
                        Connect with us
                      </p>
                      <Box display="flex" gap="8px">
                        <Link
                          className="socialMediaIcon"
                          href="https://x.com/jioinsure1"
                          target="_blank"
                        >
                          <XIcon sx={{ height: "16px", width: "16px" }} />
                        </Link>
                        <Link
                          href="https://www.instagram.com/jioinsure/"
                          className="socialMediaIcon"
                          target="_blank"
                        >
                          <InstagramIcon
                            sx={{ height: "16px", width: "16px" }}
                          />
                        </Link>
                        <Link
                          href="https://www.facebook.com/profile.php?id=61559668442375"
                          className="socialMediaIcon"
                          target="_blank"
                        >
                          <FacebookRoundedIcon
                            sx={{ height: "18px", width: "18px" }}
                          />
                        </Link>
                      </Box>
                    </li>
                  )}
                </ul>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Box>

      <Grid container className="px-3" columnSpacing={3}>
        <Grid size={12} textAlign={"center"}>
          <p
            style={{
              fontSize: "12px",
              lineHeight: "16px",
              textAlign: "center",
              color: COLORS.white,
              padding: "8px",
              background: "rgb(181, 181, 181)",
              borderRadius: "4px",
              margin: "0 12px 12px 12px",
            }}
          >
            Discount is offered by the insurance company as approved by IRDAI
            for the product under file & use guidelines.
          </p>
          <p
            style={{
              fontSize: "12px",
              lineHeight: "16px",
              textAlign: "center",
              color: COLORS.white,
              padding: "8px",
              background: "rgb(181, 181, 181)",
              borderRadius: "4px",
              margin: "0 12px 4px 12px",
            }}
          >
            Insurance is the subject matter of the solicitation. For more
            details on policy terms, conditions, exclusions, limitations, please
            refer/read policy brochure carefully before concluding sale.
            <br />
            {(window.location.pathname.includes("/car-insurance") ||
              window.location.pathname.includes("/two-wheeler-insurance")) && (
              <span
                style={{
                  fontWeight: 700,
                  marginTop: "6px",
                  display: "inline-block",
                }}
              >
                Disclaimer: *Savings result from comparing the highest and
                lowest premiums for own damage coverage (excluding add-ons)
                offered by various insurers for the same vehicle, with identical
                IDV and NCB.
              </span>
            )}
          </p>
        </Grid>
      </Grid>
      {redirected ? null : (
        <Box className="bottomPart">
          <Grid container className="px-3" columnSpacing={3}>
            <Grid size={12}>
              <img
                alt=""
                src="/images/jfslogofinal.svg"
                height="32px"
                style={{ marginBottom: "16px" }}
              />{" "}
              <p>
                Copyright © {new Date().getFullYear()} Jio Insurance Broking
                Ltd.
              </p>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default MFooter;
