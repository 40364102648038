import React, { useEffect, useState } from "react";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import NonTermProposal from "../../../../Page/Desktop/NonTerm/NonTermProposal/HDFC/NonTermpProposal";
import { TNonTermProposerData } from "../../../../types/TNonTermSlice";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import {
  isEmpty,
  validateEmail,
  validateFullName,
  validateMobileNumber,
} from "../../../../SupportingFiles/HelpingFunction";
import { NonTermSlice } from "../../../../Store/Slice_Reducer/NonTerm/NonTermSlice";
import { NON_TERM_SERVICES } from "../../../../Services/NonTerm/NonTermFormService";
import { TDropdown } from "../../../../types/Common/TDropdown";
import { useNavigate } from "react-router-dom";
import { NON_TERM_ROUTES } from "../../../../Router/Path/NonTermRoute";
import MNonTermProposal from "../../../../Page/Mobile/NonTerm/MNonTermProposal/HDFC/MNonTermProposal";
import { toast } from "react-toastify";

const NonTermBasicDetailsContainer = () => {
  const isMobile = useIsMobile();
  const {
    PROPOSER_DATA,
    ADD_FORM,
    ADD_FORM_RESPONSE,
    UPDATE_RESPONSE,
    SELECTED_QUOTE_DATA,
    RIDERS_BENEFITS,
  } = useAppSelector((state) => state.NonTerm);
  const { NonTerm } = useAppSelector((state) => state);
  const [loader, setLoader] = useState<boolean>(false);
  console.log("PROPOSER_DATA", PROPOSER_DATA);
  const dispatch = useAppDispatch();
  const [proposerFields, setProposerFields] =
    useState<TNonTermProposerData>(PROPOSER_DATA);

  const navigate = useNavigate();

  useEffect(() => {
    GET_RIDERS_PREMIUM();
  }, []);

  const fieldsUpdateState = (attr: any, value: any) => {
    console.log("attr", attr, value);
    setProposerFields({
      ...proposerFields,
      [attr[0]]: {
        value: value,
        warning:
          attr[0] === "full_name"
            ? !validateFullName(value)
            : attr[0] === "mobile"
            ? !validateMobileNumber(value)
            : attr[0] === "email"
            ? !validateEmail(value)
            : attr[0] === "emergency_number"
            ? !validateMobileNumber(value)
            : isEmpty(value),
      },
    });
  };
  console.log("attr", proposerFields);
  const UPDATE_PROPOSER_DETAILS = () => {
    let params = {
      quote_no: btoa(ADD_FORM_RESPONSE.quote_no),
      section: "proposer_details",
      details: {
        proposer_name: proposerFields?.full_name?.value,
        proposer_mobile: ADD_FORM.mobile.value,
        proposer_email: proposerFields?.email?.value,
        proposer_gender: ADD_FORM.gender.value,
        proposer_dob: UPDATE_RESPONSE?.dob || ADD_FORM.age.value,
        address_pincode: ADD_FORM.pincode.value,
        premium: ADD_FORM.investAmount.value,
      },
      buy_online_code: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
    };

    const onSuccess = (res: any) => {
      // GET_REDIRECTION_URL();
      setLoader(false);
      const redirectionURL =
        RIDERS_BENEFITS.riders_benefits_response.redirectionUrl;
      if (redirectionURL) {
        window.location.href = redirectionURL;
        // navigate(redirectionURL);
      } else {
        toast.error("Redirection Url Not Found!");
      }
    };
    const onError = () => {
      setLoader(false);
    };
    setLoader(true);
    NON_TERM_SERVICES.UPDATE_PROPOSER_DETAILS(onSuccess, onError, params);
  };

  const GET_RIDERS_PREMIUM = () => {
    const onSuccess = (data: any) => {
      dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      const error = data.response?.premiumDetails?.error;
      const message = data.message;
      const response: any = data.response.premiumDetails;
      if (!error) {
        dispatch(NonTermSlice.actions.SET_RIDERS_BENEFITS_DATA(response));
      } else {
        dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      }
    };

    const onError = (err: any) => {
      dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
    };

    const data: any = {
      product_ids: [SELECTED_QUOTE_DATA?.productDetails.id],
      quote_no: ADD_FORM_RESPONSE.quote_no,
      // pcb: proposerFields.PCB ? "Yes" : "No",
    };
    dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));

    NON_TERM_SERVICES.NON_TERM_UPDATE_RIDERS_STATUS(onSuccess, onError, data);
  };

  const GET_REDIRECTION_URL = () => {
    dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
    const onSuccess = (res: any) => {
      dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      // console.log("res", res);
      setLoader(false);
      const results = res.results;
      console.log("response", results);
      const error = results.error;

      if (!error) {
        const response = results.response;
        const redirectionUrl = response.Redirection_URL;
        window.location.href = redirectionUrl;
      }
    };
    const onError = () => {
      dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      setLoader(false);
    };
    const params = {
      name: proposerFields.full_name.value,
      dob: ADD_FORM.age.value,
      email_id: proposerFields.full_name.value,
      mobile: ADD_FORM.mobile.value,
      product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
      quote_no: ADD_FORM_RESPONSE.quote_no,
    };

    NON_TERM_SERVICES.GET_REDIRECTION_URL_ADITYA_BIRLA(
      onSuccess,
      onError,
      params
    );
  };

  const validateForm = () => {
    let data = { ...proposerFields };

    data = {
      ...data,
      full_name: {
        ...data.full_name,
        warning: !validateFullName(data.full_name.value),
      },
      dob: {
        ...data.dob,
        warning: isEmpty(data.dob.value),
      },
      mobile: {
        ...data.mobile,
        warning: !validateMobileNumber(data.mobile.value),
      },
      email: {
        ...data.email,
        warning: !validateEmail(data.email.value),
      },
    };
    setProposerFields({ ...data });

    if (!data.full_name.warning && !data.email.warning) {
      dispatch(NonTermSlice.actions.UPDATE_PROPOSER_DATA(data));
      UPDATE_PROPOSER_DETAILS();
    }
  };

  return (
    <>
      {isMobile ? (
        <MNonTermProposal
          proposerFields={proposerFields}
          fieldsUpdateState={fieldsUpdateState}
          validateForm={validateForm}
          loader={loader}
        />
      ) : (
        <NonTermProposal
          proposerFields={proposerFields}
          fieldsUpdateState={fieldsUpdateState}
          validateForm={validateForm}
          loader={loader}
        />
      )}
    </>
  );
};

export default NonTermBasicDetailsContainer;
