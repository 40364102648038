import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { COMMON_ROUTES } from "../../../../Router/Path/CommonRoutes";
import { COLORS } from "../../../../SupportingFiles/colors";
import PolicyCancellation from "../PolicyCancellation/PolicyCancellation";
import styles from "./Footer.module.scss";

function Footer() {
  const navigate = useNavigate();
  const [openCancellationPopup, setOpenCancellationPopup] = useState(false);
  return (
    <Box className={styles.footer}>
      <PolicyCancellation
        open={openCancellationPopup}
        setOpen={setOpenCancellationPopup}
      />

      <Box className={styles.topPart}>
        <Grid container columnSpacing={2}>
          <Grid size={3}>
            <img
              src="/images//jio_insurance_logo.svg"
              height="18px"
              alt="logo"
            />
            <Box className="mt-5">
              <p
                style={{
                  fontWeight: 500,
                  color: COLORS.lightgrey,
                  lineHeight: "24px",
                }}
              >
                Jio Insurance Broking Limited
                <br />
                IRDAI License No: 347,
                <br />
                Direct Broker (Life & General),
                <br />
                Valid upto: 11/03/2025 (Renewable)
                <br />
              </p>
            </Box>
            <Box className="mt-5">
              <Link
                style={{
                  fontWeight: 600,
                  fontSize: 24,
                  color: "rgb(89, 89, 89)",
                }}
                href="./pdfs/JIBL_annual_return_2023_24.pdf"
                target="_blank"
              >
                Investor Relations
              </Link>
              <p className="mt-2" style={{ color: "rgb(89, 89, 89)" }}>
                Annual Return FY 23-24
              </p>
            </Box>
          </Grid>
          <Grid size={3}>
            <h5>Get Insured</h5>
            <ul>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.PRODUCT_CAR);
                  }}
                >
                  Car Insurance
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.PRODUCT_TW);
                  }}
                >
                  Two-Wheeler Insurance
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.PRODUCT_HEALTH);
                  }}
                >
                  Health Insurance
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.PRODUCT_TERM);
                  }}
                >
                  Term Insurance
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.PRODUCT_NON_TERM);
                  }}
                >
                  Investment Plans
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid size={3}>
            <h5>Quick Links</h5>
            <ul>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.HOME);
                  }}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.ABOUT_US);
                  }}
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link onClick={() => navigate(COMMON_ROUTES.CONTACT)}>
                  Contact
                </Link>
              </li>
              <li>
                <Link onClick={() => navigate(COMMON_ROUTES.CAREER)}>
                  Career
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.PRIVACY_POLICY);
                  }}
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.TERM_AND_CONDTION);
                  }}
                >
                  Terms of Use
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.DISCLAIMER);
                  }}
                >
                  Legal Disclaimer
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.REFUND_AND_CANCELLATION);
                  }}
                >
                  Grievance Redressal Policy
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    setOpenCancellationPopup(true);
                  }}
                >
                  Policy Cancellation & Refund
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.IRDAI);
                  }}
                >
                  ISNP Service TAT
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.BLOG_UNLOCK_THE_BENEFITS);
                  }}
                >
                  E-Insurance Account
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid size={3}>
            <h5>Contact Details</h5>
            <ul>
              <li style={{ marginBottom: "16px" }}>
                <p
                  style={{
                    fontWeight: 700,
                    marginBottom: "2px",
                    color: COLORS.lightgrey,
                    fontSize: "18px",
                  }}
                >
                  Registered Address
                </p>
                <p
                  style={{
                    fontWeight: 500,
                    color: COLORS.lightgrey,
                  }}
                >
                  3<sup>rd</sup> Floor, Court House, Lokmanya Tilak Marg, Dhobi
                  Talao, Mumbai - 400002
                </p>
              </li>
              <li style={{ marginBottom: "16px" }}>
                <p
                  style={{
                    fontWeight: 700,
                    marginBottom: "2px",
                    color: COLORS.lightgrey,
                    fontSize: "18px",
                  }}
                >
                  CIN
                </p>
                <p style={{ fontWeight: 500, color: COLORS.lightgrey }}>
                  U67200MH2006PLC165651
                </p>
              </li>
              <li style={{ marginBottom: "16px" }}>
                <p
                  style={{
                    fontWeight: 700,
                    marginBottom: "2px",
                    color: COLORS.lightgrey,
                    fontSize: "18px",
                  }}
                >
                  Support mail:
                </p>
                <Link
                  href="mailto:customer.care@jioinsure.in"
                  style={{ fontWeight: 500, color: COLORS.lightgrey }}
                >
                  customer.care@jioinsure.in
                </Link>
              </li>
              <li style={{ marginBottom: "16px" }}>
                <p
                  style={{
                    fontWeight: 700,
                    marginBottom: "8px",
                    color: COLORS.lightgrey,
                    fontSize: "18px",
                  }}
                >
                  Connect with us
                </p>
                <Box display="flex" gap="8px">
                  <Link
                    className={styles.socialMediaIcon}
                    href="https://x.com/jioinsure1"
                    target="_blank"
                  >
                    <XIcon sx={{ height: "16px", width: "16px" }} />
                  </Link>
                  <Link
                    href="https://www.instagram.com/jioinsure/"
                    className={styles.socialMediaIcon}
                    target="_blank"
                  >
                    <InstagramIcon sx={{ height: "16px", width: "16px" }} />
                  </Link>
                  <Link
                    href="https://www.facebook.com/profile.php?id=61559668442375"
                    className={styles.socialMediaIcon}
                    target="_blank"
                  >
                    <FacebookRoundedIcon
                      sx={{ height: "18px", width: "18px" }}
                    />
                  </Link>
                </Box>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Box>
      <Box marginTop={"40px"} marginBottom={"16px"}>
        <Grid container className="row" columnSpacing={3}>
          <Grid size={12} textAlign={"center"}>
            <p
              style={{
                padding: "8px",
                background: " #B5B5B5",
                borderRadius: "4px",
                textAlign: "center",
                fontSize: "16px",
                color: COLORS.white,
                marginBottom: "16px",
              }}
            >
              Discount is offered by the insurance company as approved by IRDAI
              for the product under file & use guidelines.
            </p>
            <p
              style={{
                padding: "8px",
                background: " #B5B5B5",
                borderRadius: "4px",
                textAlign: "center",
                fontSize: "16px",
                color: COLORS.white,
              }}
            >
              Insurance is a subject matter of the solicitation. For more
              details on policy terms, conditions, exclusions, limitations,
              please refer/read policy brochure carefully before concluding
              sale.
              <br />
              {(window.location.pathname.includes("/car-insurance") ||
                window.location.pathname.includes(
                  "/two-wheeler-insurance"
                )) && (
                <span
                  style={{
                    borderTop: "1px solid #ccc",
                    display: "block",
                    marginTop: "12px",
                    paddingTop: "12px",
                  }}
                >
                  Disclaimer: *Savings result from comparing the highest and
                  lowest premiums for own damage coverage (excluding add-ons)
                  offered by various insurers for the same vehicle, with
                  identical IDV and NCB.
                </span>
              )}
            </p>
          </Grid>
        </Grid>
      </Box>
      <hr />
      <Box className={styles.bottomPart}>
        <Grid container className="row" columnSpacing={3}>
          <Grid size={12} textAlign="center">
            <p>
              <img
                src="../images/jfslogofinal.svg"
                height="32px"
                style={{ marginRight: "8px" }}
              />{" "}
              © {new Date().getFullYear()} Jio Insurance Broking Ltd. All rights
              reserved.
            </p>
          </Grid>
        </Grid>
      </Box>
      {/* <Box className={styles.jioBand} /> */}
    </Box>
  );
}
export default Footer;
