export enum COMMON_ROUTES {
  HOME = "/",
  PRIVACY_POLICY = "/privacy-policy",
  TERM_AND_CONDTION = "/terms-and-conditions",
  DISCLAIMER = "/disclaimer",
  REFUND_AND_CANCELLATION = "/grievance-redressal-policy",
  OLD_REFUND_AND_CANCELLATION = "/refund-and-cancellation",
  ABOUT_US = "/about-us",
  CONTACT_US = "/contact-us",
  PRODUCT_HEALTH = "/health-insurance",
  LANDING_PRODUCT_CAR = "/landing-page/car-insurance",
  LANDING_PRODUCT_TW = "/landing-page/two-wheeler-insurance",
  LANDING_PRODUCT_HEALTH = "/landing-page/health-insurance",
  LANDING_PRODUCT_TERM = "/landing-page/term-insurance",
  PRODUCT_CAR = "/car-insurance",
  PRODUCT_TW = "/two-wheeler-insurance",
  PRODUCT_TERM = "/term-insurance",
  // PRODUCT_NON_TERM = "/non-term-insurance",
  PRODUCT_NON_TERM = "/investment-plans",
  BLOGS = "/blogs",
  CONTACT = "/contact-us",
  CAREER = "/career",
  BLOG_FACTORS_TO_BE_CONSD = "/blog/factors-to-be-considered-while-buying-personal-accident-insurance-in-india",
  BLOG_UNLOCK_THE_BENEFITS = "/blog/unlocking-the-benefits-of-electronic-insurance-accounts",
  BLOG_EVERYTHING_YOU_NEED = "/blog/everything-you-need-to-know-about-personal-accident-cover",
  BLOG_ALL_YOU_NEED = "/blog/all-you-need-to-know-about-car-insurance",
  BLOG_WHY_DOES_COMP = "/blog/why-does-a-comprehensive-car-insurance-cost-more-than-a-third-party-liability-car-insurance",
  BLOG_MAKING_UNDERSTANDING = "/blog/making-understanding-of-idv-easy-for-you",
  BLOG_UNDERSTANDING_DEDUCTIBLE = "/blog/understanding-deductibles-and-co-pays",
  BLOG_HEALTH_INSURANCE = "/blog/health-insurance-top-up-plans",
  BLOG_CORPORATE_HEALTH = "/blog/do-you-have-a-corporate-health-insurance-policy-find-out-if-it-is-enough",
  BLOG_ULIP = "/blog/unit-linked-insurance-plan-ulip",
  BLOG_BENEFITS_OF_CAR = "/blog/benefits-of-car-insurance-renewal",
  IRDAI_OLD = "/irdai",
  IRDAI = "/isnp-service-tat",
}
