import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { COLORS } from "../../../SupportingFiles/colors";
import { Box } from "@mui/material";

interface Props {
  attrName?: any;
  title?: string;
  value?: string;
  value_update?: Function;
  options?: Array<any>;
  error_message?: string;
  warn_status?: boolean;
}

const SearchSelectDropdown: React.FC<Props> = ({
  attrName = "",
  title = "",
  value = "",
  options = [],
  error_message = "Select",
  warn_status = false,
  value_update = () => {},
}) => {
  return (
    <div className="inputFields" style={{ position: "relative" }} id={title}>
      <p
        style={{
          color: COLORS.lightgrey,
          textAlign: "left",
          marginBottom: "4px",
          position: "relative",
          zIndex: "1",
        }}
      >
        {title}
      </p>
      <Autocomplete
        disablePortal
        id={`${title}-autocomplete`}
        options={options}
        getOptionLabel={(option) => option.label}
        fullWidth
        value={options.find((option) => option.value === value)} // Find option by value
        sx={{
          ".MuiAutocomplete-inputRoot": { paddingRight: "0!important" },
          ".Mui-focused": {
            color: COLORS.darkgrey,
          },

          ".MuiInputBase-root": {
            background: COLORS.white,
            fontFamily: "JioType",

            "&:before, &:after": {
              display: "none",
            },
            ".MuiAutocomplete-input": {
              padding: "2px 4px!important",
            },
            ".MuiInput-input": {
              fontWeight: "500",
              fontSize: "18px",
              color: COLORS.darkgrey,
              padding: "6px 4px 8.5px 0px",
              cursor: "pointer",
            },
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Select"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <KeyboardArrowDownIcon
                  style={{
                    cursor: "pointer",
                    top: "0px",
                    right: "6px",
                    position: "relative",
                    color: COLORS.lightgrey,
                  }}
                />
              ),
            }}
          />
        )}
        onChange={(event, new_team) => {
          value_update(attrName, new_team && new_team.value);
        }}
      />
      {warn_status === true ? (
        <span
          style={{
            position: "absolute",
            bottom: "-16px",
            right: "0px",
            color: "#EA4559",
            fontSize: "12px",
          }}
        >
          {error_message}
        </span>
      ) : null}
    </div>
  );
};

export default SearchSelectDropdown;
