import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useEffect } from "react";
import { TNonTermProduct } from "../../../../types/NonTerm/TNonTermCardProduct";
import { useNavigate } from "react-router-dom";
import { COMMON_ROUTES } from "../../../../Router/Path/CommonRoutes";

const QuoteNavbar = ({
  quoteListData,
}: {
  quoteListData?: TNonTermProduct[];
}) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        backgroundColor: "#FEF7Ef",
        position: "sticky",
        top: "0px",
        width: "100%",
        zIndex: "91",
        // height: "72px",
        // display: "flex",
        // alignItems: "center",
        // justifyContent: "center",
        boxShadow: "0px 4px 12px #ddd",
      }}
    >
      <Box sx={{ background: "#fff", height: "60px", paddingTop: "14px" }}>
        <Grid container spacing={3}>
          <Grid size={12}>
            <img alt="" src="/images/jfslogofinal.svg" height="32px" />
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={3} alignItems="center" width="100%">
        <Grid size={5}>
          <Link
            onClick={() => {
              if (window.location.pathname === COMMON_ROUTES.HOME) {
                window.location.reload();
              } else {
                navigate(COMMON_ROUTES.HOME);
              }
            }}
            style={{
              display: "inline-block",
              padding: "20px 0px",
              cursor: "pointer",
            }}
          >
            <img
              src="../images/jio_insurance_logo.svg"
              alt="jio-insurance-logo"
              height="16px"
            />
          </Link>
        </Grid>
        {/* <Grid
          size={7}
          textAlign="right"
          sx={{ ".tertiaryBtn svg": { position: "relative", top: "-2px" } }}
        >
          {quoteListData && quoteListData?.length > 0 ? (
            <EVButton
              id="shareDetails"
              className="tertiaryBtn small"
              text={
                <>
                  <ShareIcon
                    style={{ fontSize: "16px", marginRight: "12px" }}
                  />{" "}
                  Share
                </>
              }
              fullWidth={false}
              onClick={() => {}}
            />
          ) : null}
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default QuoteNavbar;
