import { useState, useEffect } from "react";
import { Box, Button, Link } from "@mui/material";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid2";
import "./../../../../SCSS/ModalPopup.scss";
import { COLORS } from "../../../../SupportingFiles/colors";

function AddonPopup({
  data,
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Function;
  data: any;
}) {
  return (
    <Modal open={open}>
      <Box
        className="modalWrapper"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box className="modalContent mdWidth mx-3">
          <Grid className="topbar" container alignItems="center">
            <Grid size={12} textAlign={"right"}>
              <Button
                disableRipple
                className="closePopup"
                onClick={() => {
                  setOpen(false);
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            className="row"
            columnSpacing={1}
            alignItems="flex-start"
          >
            <Grid size={12}>
              <Box>
                <h3 className="title">{data?.addontitle}</h3>
                <p className="mlineHeight">{data?.addon_desc}</p>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
}

export default AddonPopup;
