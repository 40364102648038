import { Box } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import Grid from "@mui/material/Grid2";
import MNonTermNavbar from "../../../../Component/Amaze/NonTerm/MNonTermNavbar/MNonTermNavbar";
import NonTermLeftSidebar from "../../../../Component/Amaze/NonTerm/NonTermLeftSidebar/NonTermLeftSidebar";
import NonTermNavbar from "../../../../Component/Amaze/NonTerm/NonTermNavbar/NonTermNavbar";
import NonTermBasicDetailsContainer from "../../../../Container/Non-term/Proposal/ICICI/NonTermBasicDetailsContainer";
import NonTermRidersContainer from "../../../../Container/Non-term/Proposal/ICICI/NonTermRidersContainer";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { NON_TERM_ROUTES } from "../../../Path/NonTermRoute";
import "./../../../../SCSS/Amaze/CommonSCSS/ProposalForms.scss";
import { useAppSelector } from "../../../../Store/hooks";
import { EProductId } from "../../../../Enum/ENonTermComapnyCode";
import NonTermLeftSidebarProposerDetails from "../../../../Component/Amaze/NonTerm/NonTermLeftSidebar/NonTermLeftSidebarProposerDetails";

const ICICI_ROUTES = () => {
  return (
    <Routes>
      <Route
        path={NON_TERM_ROUTES.RIDERS_BENEFITS}
        element={<NonTermRidersContainer />}
      />
      <Route
        path={NON_TERM_ROUTES.BASIC_DETAILS}
        element={<NonTermBasicDetailsContainer />}
      />
    </Routes>
  );
};
const ICICIRoutes = () => {
  const isMobile = useIsMobile();
  const { SELECTED_QUOTE_DATA } = useAppSelector((state) => state.NonTerm);

  console.log(
    SELECTED_QUOTE_DATA.productDetails.id ===
      (EProductId.ICICI_Pru_Gold_Deferred_Income ||
        EProductId.ICICI_Pru_Gold_Immediate_Income_with_Booster ||
        EProductId.ICICI_Pru_Gift_Pro_Level_Income ||
        EProductId.ICICI_Pru_Gold_Immediate_Income ||
        EProductId.ICICI_Pru_Gift_Pro_Increasing_Income),
    "hello"
  );

  return (
    <>
      {isMobile ? (
        <Box className="proposalWrapper_m">
          <MNonTermNavbar />
          <Grid container spacing={0} className="d-non">
            <Grid size={12} className="proposalContentSection">
              {ICICI_ROUTES()}
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box className="proposalWrapper">
          <NonTermNavbar />
          <Grid container spacing={3} className="pb-0">
            {SELECTED_QUOTE_DATA.productDetails.id ===
              EProductId.ICICI_Pru_Gold_Deferred_Income ||
            SELECTED_QUOTE_DATA.productDetails.id ===
              EProductId.ICICI_Pru_Gold_Immediate_Income_with_Booster ||
            SELECTED_QUOTE_DATA.productDetails.id ===
              EProductId.ICICI_Pru_Gift_Pro_Level_Income ||
            SELECTED_QUOTE_DATA.productDetails.id ===
              EProductId.ICICI_Pru_Gold_Immediate_Income ||
            SELECTED_QUOTE_DATA.productDetails.id ===
              EProductId.ICICI_Pru_Gift_Pro_Increasing_Income ? (
              <NonTermLeftSidebar />
            ) : (
              <NonTermLeftSidebarProposerDetails />
            )}
            <Grid size="grow" className="proposalContentSection">
              {ICICI_ROUTES()}
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default ICICIRoutes;
