import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import {
  blog_data,
  blogsResponsive,
} from "../../../../Component/Desktop/Common/BlogArray/BLogArray";
import Footer from "../../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../../Component/Desktop/Common/NavBar/NavBar";
import MFooter from "../../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../../Component/Mobile/Common/MNavBar/MNavBar";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import "./../BlogsPage.scss";
import HelmetComponent from "../../../../SupportingFiles/HelmetComponent";
import { FRONTEND_DOMAIN } from "../../../../URLCollection/Domain";

const BlogCorporateHealth = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const current_blog_data = {
    id: "do-you-have-a-‘corporate-health’-insurance-policy’-find-out-if-it-is-enough!",
    blogtitle:
      "Do you have a ‘Corporate Health’ insurance policy’ – Find out if it is enough!",
    img_url: "/images/blog8.webp",
    main_desc: (
      <>
        <p className="mb-4">
          Employees worldwide today look for rewards and benefits beyond just
          pay cheques. One of the most popular benefits offered by Employers is
          a Group Health Insurance Policy. While companies are largely known to
          offer Health Insurance coverage to their employees, there are many who
          extend to their families. However, what’s worth thinking for an
          employee is whether the health insurance coverage offered by the
          employer is enough for them and their families, especially when the
          cost of medical expenses is on the rise. Read on to know more
        </p>
      </>
    ),
    blog_full_desc: (
      <>
        <h6 className="mb-3">
          Know about a Corporate Health Insurance Policy or Group Health
          Insurance Policy:
        </h6>
        <p className="mb-5">
          A Corporate Health Insurance (CHI) Policy, also popularly known as a
          Group Health Insurance (GHI) Policy, is a health insurance plan that
          is known to offer coverage typically to a group of employees within an
          organisation. Generally speaking, GHI policies are offered by a
          corporate as a benefit, where the premium is paid by the employer in
          order to provide medical coverages to their employees.
        </p>

        <h6 className="mb-3">
          Features of a Corporate Health Insurance Policy:
        </h6>
        <ul className="mb-5">
          <li>
            A Corporate Health Insurance Plan covers either self (employee) or
            self, spouse, children, dependent parents.
          </li>
          <li>
            In case of a Corporate of a Group Health policy, employers pay the
            premium.
          </li>
          <li>
            Unlike other health insurance plans, one can get cashless
            hospitalisation benefits at the network hospitals of an insurer.
          </li>
          <li>
            Group Health Insurance Policy provides coverage to the fees of
            medical practitioners/ consultants, Doctors, the pre-hospitalization
            and post-hospitalization expenses, alongside the domiciliary and
            day-care expenses.
          </li>
          <li>
            Certain ancillary charges like ambulance charges are also covered
            under these types of policies
          </li>
          <li>
            Some of the policies have free Annual Health check-ups for
            employees.
          </li>
          <li>
            Corporate Health Insurance Plans are known to cover pre-existing
            diseases and maternity expenses.
          </li>
        </ul>

        <h6 className="mb-3">
          Let us understand if Corporate Health Insurance Policy provides enough
          coverage?
        </h6>
        <p className="mb-5">
          While Corporate Health Insurance benefits both employees and
          employers. This does not mean that the coverages of corporate health
          insurance is adequate to secure one’s self and family related health
          expenses. Across multiple medical research reports what is evident is
          that medical inflation is on the rise, and in order to combat
          unforeseen eventualities additional coverage is a must have now more
          than ever. Let us understand why the Corporate Health Policy Coverage
          might not be enough, and why is it only wise that one has a separate
          health insurance policy:{" "}
        </p>

        <h6 className="mb-3">
          Sum Insured of a Corporate Health Insurance Policy is Low:
        </h6>
        <p className="mb-5">
          Multiple comparative studies have confirmed that a Corporate Health
          Insurance Policy have a lower sum insured than an exclusive Individual
          or Family Floater Health Insurance Policy. Corporate Health Insurance
          Plans offer sum insured depending on factors such as location- metro /
          non metro, employee grade. Noticeably the sum insured may not be
          enough in case of hospitalization for a severe ailment or major
          accident.
        </p>

        <h6 className="mb-3">Situation: </h6>
        <p>
          Let’s consider an example of Michael who is employed with a well
          renowned financial institution. He gets a health insurance coverage
          for himself and his family of Rs 3, 00,000. Michael has 4 dependents,
          his spouse, 1 child, and his parents. At the time when the first wave
          of COVID-19 hit India, his family was infected, his wife and parents
          required urgent medical attention and had to be hospitalized. The
          total medical expenses for the three of them reached almost 9 Lakhs,
          which is 3 times of his company coverage. Michael has always been a
          meticulous planner when it came to financial and risk planning. He had
          bought separate Health Insurance Policies for his parents with a sum
          insured of Rs 6 Lakhs each. He was able to manage the expenses for his
          wife's hospitalization through the insurance provided by his company.
        </p>
        <p className="mb-5">
          However, Michael realized the importance of having additional policies
          with adequate insurance cover because, in the absence of his parent's
          policies, he would have had to rely on borrowings from banks and or
          others. He has now decided to buy a Family Floater Health Insurance
          Policy for himself, his wife, and his child.
        </p>

        <h6 className="mb-3">Current Employment status:</h6>
        <p className="mb-5">
          Corporate Health Insurance Policies are known to provide health
          benefits as long as one is employed with the organization. An
          employee's average duration within an organization is 4 to 5 years.
          So, if one moves out from their existing employer, for any specific
          reason, they are no longer entitled to the benefits of the corporate
          health insurance policy. On the contrary, an Individual Health
          Insurance Policy will provide a coverage irrespective of the
          employment status, as long as the premiums are paid on time.{" "}
        </p>

        <h6 className="mb-3">Post-Retirement Status:</h6>
        <p className="mb-5">
          As noted above, one can avail a health insurance coverage from their
          company till the time they are employed within the same organisation.
          Having said that, one will have to buy a separate health insurance
          policy after their retirement. In such scenarios getting a health
          insurance can be a painful exercise beyond a certain age. Before
          issuance of a policy, there will be mandatory medical tests and
          certain pre-existing diseases which will be considered as high-risk
          profiles and an insurer will not offer a policy under such
          circumstances. Where somehow one is able to get an insurance coverage,
          the pre-existing diseases may not be covered, one of the major reasons
          for hospitalisation of senior citizens.
        </p>
        <h6 className="mb-3">Corporate Terms and Conditions May Change:</h6>
        <p className="mb-5">
          The government has not made it compulsory for organizations to offer
          health insurance to their employees, and it is entirely an employers'
          discretion. Hence, the organisation can make changes to the policy
          terms and conditions offered or decide to altogether stop the
          insurance coverage without any legal implications.
        </p>
        <h6 className="mb-3">Coverage for dependent members:</h6>
        <p className="mb-5">
          A Corporate Health Insurance Policy may or may not offer coverage to
          the dependents of an employee in some cases. Hence, it is crucial to
          be fully aware of the policy terms and conditions well in advance. In
          case of a Family Floater Health Insurance Policy, one can opt for
          coverages for their dependent parents, spouse and children or even
          choose from different policies as per their health needs and
          requirements. An exclusive policy for Senior Citizens for example is
          suited for elderly parents is a good option as it offers additional
          benefits to them.
        </p>
        <h6 className="mb-3">Clauses around Co-Pay:</h6>
        <p className="mb-5">
          Most Group Health Insurance Plans or Corporate Health insurance policy
          will have a co-pay clause included. Co-pay is a fixed
          percentage/amount of a claim that a policyholder is required to pay
          from their own pocket. The insurance company decides to pay the co-pay
          amount, which varies for different medical services. For example, if
          one has to visit a specialist for a specific illness, and the co-pay
          amount mentioned in the policy for a consultation is Rs 2,000, In this
          case, an individual will have to pay Rs 2,000 for the consultation
          with the specialist{" "}
        </p>
        <h6 className="mb-3">Limit on Room-rent:</h6>
        <p className="mb-5">
          The room-rent capping on most Group Health Insurance Plans is usually
          very low, and the insured has to pay about 50% to 60% of the room rent
          on their own, which can be very high depending on the days one was
          hospitalized for.{" "}
        </p>
        <h6 className="mb-3">Inferences:</h6>
        <ul className="mb-5">
          <li>
            While It is a perk to have Corporate Health Insurance Coverage,
            having an Individual Health Plan is a must have.
          </li>
          <li>
            In a rare case scenario, if an employer offers, a larger sum insured
            and one does not have any dependent members, then one can consider
            delaying the decision of buying an additional personal policy.{" "}
          </li>
          <li>
            Note that an organization will provide for coverage only until
            employment, and they have the right to stop offering benefits at any
            point in time.
          </li>
          <li>
            Our requirements change with different life stages, recommendation
            will be to review the health insurance cover every 3 years to ensure
            one has adequate coverage.
          </li>
          <li>
            Having two or more policies, provides an option to choose from the
            policies at a point of medical emergency
          </li>
        </ul>
      </>
    ),
  };

  const blogsJsonLd = JSON.stringify(
    {
      "@context": "https://schema.org",
      "@type": "Article",
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": `${FRONTEND_DOMAIN}/blog/do-you-have-a-corporate-health-insurance-policy-find-out-if-it-is-enough`,
      },
      headline: current_blog_data?.blogtitle,
      image: `${FRONTEND_DOMAIN}${current_blog_data?.img_url}`,
      author: {
        "@type": "Organization",
        name: "Jio Insurance Broking Ltd.",
        url: `${FRONTEND_DOMAIN}/about-us`,
      },
      datePublished: "2024-10-22",
      dateModified: "2024-10-22",
    },
    null,
    2
  ); // Added 'null, 2' to format it with indentation

  return (
    <Box className="blogsWrapper">
      <HelmetComponent
        title="Corporate health insurance policy – coverage & benefits"
        description="Corporate health insurance policies that offer comprehensive coverage for employees, affordable premiums, & easy online access to manage your plans effectively."
        showCanonicalTag={true}
        scripts={[blogsJsonLd]}
      />
      {isMobile ? <MNavBar /> : <NavBar />}
      <Box className="blogsdetail-sec">
        <Grid
          container
          className="row"
          columnSpacing={3}
          justifyContent={"center"}
        >
          <Grid size={12}>
            <h2>{current_blog_data?.blogtitle}</h2>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              marginBottom={"24px"}
            >
              <p
                style={{
                  fontSize: "14px",
                  lineHeight: "normal",
                  textTransform: "uppercase",
                  fontWeight: "700",
                }}
              >
                Sep 30, 2024
              </p>
              <Box display={"flex"} gap={"8px"}>
                <span className="tag">Insurance</span>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          className="row"
          columnSpacing={3}
          justifyContent={"center"}
        >
          <Grid size={6} marginBottom={"24px"}>
            <Box className="img-overlay">
              <Box className="img-bg">
                <img src={current_blog_data?.img_url} alt="" />
              </Box>
            </Box>
          </Grid>
          <Grid size={6} marginBottom={"24px"}>
            {current_blog_data?.main_desc}
          </Grid>
          <Grid size={12} marginBottom={"24px"}>
            {current_blog_data?.blog_full_desc}
          </Grid>
        </Grid>
      </Box>
      <Box className="blogCard-sec mb-pr-0">
        <Grid
          container
          className="row"
          columnSpacing={3}
          justifyContent={"center"}
        >
          <Grid size={12} marginBottom={"24px"} className="mb-pr-0">
            <h4>You May Also Like</h4>
          </Grid>
          <Grid size={12} className="mb-pr-0">
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={false}
              responsive={blogsResponsive}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000}
              arrows={false}
              customTransition="transform 0.7s ease-in-out"
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
              partialVisible={true}
            >
              {blog_data.map((item, index) => (
                <Box>
                  <Box
                    className="blog-card"
                    sx={{
                      boxShadow: "0px 4px 16px rgba(0,0,0,0.08)",
                      borderRadius: "12px",
                      marginRight: "16px",
                    }}
                  >
                    <img src={item?.img_url} alt="" />
                    <Box className="card-info">
                      <h5 className="mb-1">{item.blogtitle}</h5>
                      <p className="mb-3">{item.blog_desc}</p>
                      <Link
                        className="cont-read-link"
                        onClick={() => {
                          navigate(item?.id);
                          window.scrollTo(0, 0);
                        }}
                      >
                        Continue reading
                      </Link>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Carousel>
          </Grid>
        </Grid>
      </Box>
      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default BlogCorporateHealth;
