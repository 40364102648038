import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import { store } from "./Store/Store";
import {
  FRONTEND_DOMAIN,
  NODE_DOMAIN,
  PHP_DOMAIN,
} from "./URLCollection/Domain";
import "./index.scss";
import "@evervent_pvt_ltd/ui-kit/dist/index.css";
import reportWebVitals from "./reportWebVitals";
import { initializeGA } from "./utils/analytics";

Sentry.init({
  dsn: "https://9654e66747845bd4f2b8eae178ceac16@o4506864219521024.ingest.us.sentry.io/4506904009703424",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [FRONTEND_DOMAIN, PHP_DOMAIN, NODE_DOMAIN],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const trackingID = "G-CVPD2K66Y3";
// const trackingID = "G-D02CNQL5S9"; // Replace with your actual tracking ID
initializeGA(trackingID);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ToastContainer theme="colored" />
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
