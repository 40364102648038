import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React from "react";
import {
  formatAmountToCurrency,
  isEmpty,
} from "../../../../SupportingFiles/HelpingFunction";
import { COLORS } from "../../../../SupportingFiles/colors";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import "./TermRiderQuestion.scss";

interface Props {
  addon_amount?: string;
  main_class?: string;
  icon_class?: string;
  question_name: string;
  sub_details?: any;
  value_update: Function;
  attrName?: string;
  toggle_status: boolean | undefined;
  value?: boolean;
  show_premium?: boolean;
  addOnData?: any;
  desc?: string;
  policy_term?: string;
  criticalIllnessSelected?: boolean;
  loader?: boolean;
  addonStatus?: any;
}
const TermRiderQuestion: React.FC<Props> = ({
  addon_amount,
  main_class = "",
  icon_class = "",
  question_name,
  sub_details,
  value_update,
  attrName,
  toggle_status,
  value,
  addOnData,
  show_premium = true,
  desc,
  policy_term,
  criticalIllnessSelected,
  addonStatus,
  loader,
}) => {
  return (
    <Box className={main_class}>
      <Grid container spacing={2} alignItems="center">
        <Grid size={8} className="addonQuestionInner">
          <span className={icon_class}></span>
          <div>
            <h6>{question_name}</h6>
            <p>
              {" "}
              <b>{policy_term}</b>{" "}
            </p>
            <p>{desc}</p>
          </div>
        </Grid>
        <Grid size={4} textAlign="right">
          {/* {toggle_status &&
            !isEmpty(addon_amount) &&
            !isNaN(parseInt(`${addon_amount}`)) ? (
            <h5 style={{ color: COLORS.primary, marginBottom: "4px" }}>
              {formatAmountToCurrency(parseInt(`${addon_amount}`))}
            </h5>
          ) : null
          } */}
          {loader && toggle_status ? (
            <Box className="mb-1">
              <img src="/images/loader.svg" height="10px" alt="Brand Logo" />
            </Box>
          ) : (
            <h5 className="premium">
              {toggle_status &&
              !isEmpty(addon_amount) &&
              !isNaN(parseInt(`${addon_amount}`)) ? (
                <h5 style={{ color: COLORS.primary, marginBottom: "4px" }}>
                  {formatAmountToCurrency(parseInt(`${addon_amount}`))}
                </h5>
              ) : null}
            </h5>
          )}
          <ToggleSwitch
            toggle_status={toggle_status}
            value_update={value_update}
            attrName={attrName}
          />
        </Grid>
        <Grid size={12}>{toggle_status ? sub_details : null}</Grid>
        {/* {toggle_status ? sub_details : null} */}
      </Grid>
    </Box>
  );
};

export default TermRiderQuestion;
