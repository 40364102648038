import { Box, Button, Slider } from "@mui/material";
import Modal from "@mui/material/Modal";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Grid from "@mui/material/Grid2";
import Loader from "../../../../Component/Global/Loader/Loader";
import BackLeft from "../../../../Component/InputFields/BackLeft";
import BorderRadioButton from "../../../../Component/InputFields/BorderRadioButton/BorderRadioButton";
import JDSButtons from "../../../../Component/InputFields/JDSButtons/JDSButtons";
import SearchSelectDropdown from "../../../../Component/InputFields/SearchSelectDropdown/SearchSelectDropdown";
import TextInputField from "../../../../Component/InputFields/TextInputField/TextInputField";
import "../../../../SCSS/ProductForms.scss";
import { NonTermSlice } from "../../../../Store/Slice_Reducer/NonTerm/NonTermSlice";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import {
  formatNumberToLakhOrCrores,
  isEmpty,
  validatePincode,
} from "../../../../SupportingFiles/HelpingFunction";
import { COLORS } from "../../../../SupportingFiles/colors";

function NonTermForm({
  open,
  setOpen,
  loader,
  updateMasterState,
  validateNonTermAddForm,
  validateNonTermForm,
  validateInvestmentAmount,
  handleInputChange,
  handleSliderChange,
  inputValue,
  sliderValue,
  page_status,
  setPageStatus,
  minInvestmentAmount,
  maxInvestmentAmount,
}: {
  open: any;
  setOpen: Function;
  loader: boolean;
  updateMasterState: Function;
  validateNonTermAddForm: Function;
  validateNonTermForm: Function;
  validateInvestmentAmount: any;
  handleInputChange: any;
  handleSliderChange: any;
  inputValue: number;
  sliderValue: number;
  page_status: number;
  setPageStatus: Function;
  minInvestmentAmount: number;
  maxInvestmentAmount: number;
}) {
  const dispatch = useAppDispatch();
  const { ADD_FORM, DROPDOWN_DATA } = useAppSelector((state) => state.NonTerm);
  const steps = ["Investment Details", "Financial Details"];
  return (
    <>
      <Modal open={open}>
        <Box className="modalWrapper">
          <Box className="modalContent xlWidth">
            <Grid className="topbar" container alignItems="center">
              {page_status === 1 ? (
                <Grid size={6}>
                  <BackLeft
                    onClick={() => {
                      setPageStatus(0);
                    }}
                  />
                </Grid>
              ) : null}
              <Grid size={page_status === 1 ? 6 : 12} textAlign={"right"}>
                <Button
                  disableRipple
                  className="closePopup"
                  onClick={() => {
                    setOpen(false);
                  }}
                />
              </Grid>
            </Grid>

            <Box display="flex" alignItems={"center"} flexDirection={"column"}>
              <Box className="form_steps mb-10" width="783px">
                <Stepper activeStep={page_status} alternativeLabel>
                  {steps.map((label: any) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>

              {/* Step Select Members */}
              {page_status === 0 ? (
                <Box width="666px" display="">
                  <Grid
                    container
                    className="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent={"center"}
                    marginBottom={"12px"}>
                    <Grid size={12} textAlign="center">
                      <h3 className="mb-6">Investment details</h3>
                    </Grid>
                    <Grid size={6}>
                      <Box
                        sx={{
                          backgroundColor: COLORS.lightbg,
                          padding: "12px",
                          borderRadius: "16px",
                          position: "relative",
                        }}>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "24px",
                            alignItems: "center",
                            marginBottom: "4px",
                          }}
                          className="investmentInputBox">
                          <p
                            style={{
                              color: COLORS.lightgrey,
                              width: "100%",
                            }}>
                            Want to invest (₹)
                          </p>{" "}
                          <input
                            placeholder="0"
                            value={inputValue}
                            style={{
                              borderRadius: "8px",
                              border: "1px solid",
                              borderColor: COLORS.lightgrey,
                              backgroundColor: "transparent",
                              height: "32px",
                              padding: "4px 8px",
                              textAlign: "right",
                              fontSize: "16px",
                              fontWeight: "500",
                              width: "100%",
                            }}
                            maxLength={7}
                            onChange={handleInputChange}
                          />
                        </Box>{" "}
                        {validateInvestmentAmount &&
                        !ADD_FORM.investAmount.warning ? (
                          <span
                            style={{
                              position: "absolute",
                              right: "12px",
                              color: "#EA4559",
                              fontSize: "11px",
                            }}>
                            Enter {minInvestmentAmount} -{" "}
                            {formatNumberToLakhOrCrores(maxInvestmentAmount)}
                          </span>
                        ) : null}
                        {ADD_FORM.investAmount.warning ? (
                          <span
                            style={{
                              position: "absolute",
                              right: "12px",
                              color: "#EA4559",
                              fontSize: "11px",
                            }}>
                            Enter Investment Amount
                          </span>
                        ) : null}
                        <Box marginTop="12px">
                          <Slider
                            aria-label="Default"
                            valueLabelDisplay="auto"
                            value={sliderValue}
                            onChange={handleSliderChange}
                            min={minInvestmentAmount}
                            step={1}
                            max={maxInvestmentAmount}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}>
                          <p
                            style={{
                              color: COLORS.lightgrey,
                              fontSize: "12px",
                            }}>
                            Min: ₹ {minInvestmentAmount}
                          </p>
                          <p
                            style={{
                              color: COLORS.lightgrey,
                              fontSize: "12px",
                            }}>
                            Max:{" "}
                            {formatNumberToLakhOrCrores(maxInvestmentAmount)}
                          </p>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid size={6}>
                      <SearchSelectDropdown
                        title="Investment Mode"
                        value={`${ADD_FORM.investmentMode.value}`}
                        value_update={updateMasterState}
                        attrName={["investmentMode"]}
                        options={DROPDOWN_DATA.investmentMode}
                        warn_status={ADD_FORM.investmentMode.warning}
                        error_message="Select Investment Mode"
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    marginBottom={"12px"}>
                    <Grid size={6}>
                      <SearchSelectDropdown
                        title="Annual Income"
                        value={ADD_FORM.annualIncome.value}
                        value_update={updateMasterState}
                        attrName={["annualIncome"]}
                        options={DROPDOWN_DATA?.ANNUAL_INCOME}
                        warn_status={ADD_FORM.annualIncome.warning}
                        error_message="Select Annual Income"
                      />
                    </Grid>
                    <Grid size={6}>
                      <SearchSelectDropdown
                        title="Lifestage"
                        value={ADD_FORM.lifeStage.value}
                        value_update={updateMasterState}
                        attrName={["lifeStage"]}
                        options={DROPDOWN_DATA.LIFE_STAGE}
                        warn_status={ADD_FORM.lifeStage.warning}
                        error_message="Select Lifestage"
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justifyContent="center">
                    <Grid size={6} textAlign="center">
                      <TextInputField
                        title={"Pincode"}
                        value={ADD_FORM.pincode.value}
                        attrName={""}
                        placeholder="XXXXXX"
                        value_update={(a: any, v: any) => {
                          dispatch(
                            NonTermSlice.actions.NON_TERM_UPDATE_ADD_FORM_DATA({
                              ...ADD_FORM,
                              pincode: {
                                value: v,
                                warning: !validatePincode(v),
                              },
                            })
                          );
                        }}
                        warn_status={ADD_FORM.pincode.warning}
                        max_length={6}
                        validation_type="NUMBER"
                        error_message={
                          isEmpty(ADD_FORM.pincode.value)
                            ? "Enter pincode"
                            : "Enter valid pincode"
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} alignItems="flex-start">
                    <Grid size={12} textAlign="center">
                      <Box
                        maxWidth="312px"
                        marginLeft="auto"
                        marginRight="auto"
                        marginTop="24px">
                        <JDSButtons
                          className="primaryBtns large"
                          text={"Continue"}
                          variant="contained"
                          onClick={() => {
                            validateNonTermAddForm();
                            // setPageStatus(1);
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ) : null}
              {/* Step Select Age */}
              {page_status === 1 ? (
                <Box width="616px" display="">
                  <h3 className="mb-6" style={{ textAlign: "center" }}>
                    Financial details
                  </h3>
                  <Grid
                    container
                    spacing={3}
                    alignItems="flex-start"
                    marginLeft="auto"
                    marginRight="auto">
                    <Grid size={12}>
                      <h6
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          marginBottom: "8px",
                        }}>
                        Financial and Family Goals
                      </h6>
                      <BorderRadioButton
                        options={DROPDOWN_DATA.GOALS}
                        value={ADD_FORM.goals.value}
                        attrName={"goals"}
                        count={"AUTO"}
                        defaultValue={"Retirement"}
                        onChange={updateMasterState}
                      />
                    </Grid>
                    <Grid size={12} marginBottom={"12px"}>
                      <h6
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          marginBottom: "6px",
                        }}>
                        Risk Profile
                      </h6>
                      <BorderRadioButton
                        options={DROPDOWN_DATA.RISK_PROFILE}
                        value={ADD_FORM.riskProfile.value}
                        attrName={"riskProfile"}
                        count={"3"}
                        defaultValue={"Aggressive"}
                        onChange={updateMasterState}
                      />
                    </Grid>
                    <Grid size={12} marginBottom={"10px"}>
                      <h6
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          marginBottom: "8px",
                        }}>
                        Payment Preference
                      </h6>
                      <BorderRadioButton
                        options={DROPDOWN_DATA.PAYMENT_PREFERENCE}
                        value={ADD_FORM.payment_preference.value}
                        attrName={"payment_preference"}
                        count={"3"}
                        defaultValue={"SinglePay"}
                        onChange={updateMasterState}
                      />
                    </Grid>
                  </Grid>
                  <Box
                    maxWidth="312px"
                    marginLeft="auto"
                    marginRight="auto"
                    marginTop="24px">
                    <JDSButtons
                      className="primaryBtns large"
                      text={loader ? <Loader size="small" /> : "Submit"}
                      variant="contained"
                      onClick={() => validateNonTermForm()}
                    />
                  </Box>
                </Box>
              ) : null}
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default NonTermForm;
