import { Box, Link, Pagination, Stack } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { blog_data } from "../../../Component/Desktop/Common/BlogArray/BLogArray";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import "./BlogsPage.scss";
import HelmetComponent from "../../../SupportingFiles/HelmetComponent";
import { FRONTEND_DOMAIN } from "../../../URLCollection/Domain";

const BlogsPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const blogsPerPage = 6;
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blog_data.slice(indexOfFirstBlog, indexOfLastBlog);

  const handlePageChange = (event: any, value: number) => {
    setCurrentPage(value);
  };

  const blogsJsonLd = JSON.stringify(
    {
      "@context": "https://schema.org/",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Home",
          item: `${FRONTEND_DOMAIN}`,
        },
        {
          "@type": "ListItem",
          position: 2,
          name: "Blogs",
          item: `${FRONTEND_DOMAIN}/blogs`,
        },
      ],
    },
    null,
    2
  ); // Added 'null, 2' to format it with indentation

  return (
    <Box className="blogsWrapper">
      <HelmetComponent
        title="Jio Insurance Broking Ltd"
        description="Get your car, bike, health, or Life insurance online. Comprehensive coverage across India. Book now and get the right quotes! JioInsure - Jio General Insurance"
        showCanonicalTag={true}
        scripts={[blogsJsonLd]}
      />
      {isMobile ? <MNavBar /> : <NavBar />}

      <Box className="blogsheader">
        <Box className="headerImg-box">
          <Box className="boxcenter">
            <h1>Our Blogs</h1>
          </Box>
        </Box>
      </Box>

      <Box className="blogCard-sec">
        <Grid
          container
          className="row"
          columnSpacing={3}
          justifyContent={"center"}
        >
          {currentBlogs?.map((data, index) => (
            <Grid size={{ xs: 12, md: 4 }} key={data.id}>
              <Box className="blog-card">
                <img
                  src={data.img_url}
                  alt=""
                  onClick={() => navigate(data.id)}
                  style={{ cursor: "pointer" }}
                />
                <Box className="card-info">
                  <h5 className="mb-2">{data.blogtitle}</h5>
                  <p className="mb-3">{data.blog_desc}</p>
                  <Link
                    className="cont-read-link"
                    onClick={() => navigate(data.id)}
                  >
                    Continue reading
                  </Link>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>

        <Grid
          container
          className="row"
          columnSpacing={3}
          justifyContent={"center"}
        >
          <Grid size={12}>
            <Stack spacing={2}>
              <Pagination
                count={Math.ceil(blog_data.length / blogsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default BlogsPage;
