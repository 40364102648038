import { Box, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid2";
import BorderRadioButton from "../../../../Component/InputFields/BorderRadioButton/BorderRadioButton";
import JDSButtons from "../../../../Component/InputFields/JDSButtons/JDSButtons";
import SelectDropdown from "../../../../Component/InputFields/SelectDropdown/SelectDropdown";
import TextInputField from "../../../../Component/InputFields/TextInputField/TextInputField";
import "../../../../SCSS/ModalPopup.scss";
import { TermSlice } from "../../../../Store/Slice_Reducer/Term/TermSlice";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import {
  isEmpty,
  validatePincode,
} from "../../../../SupportingFiles/HelpingFunction";
import SearchSelectDropdown from "../../../../Component/InputFields/SearchSelectDropdown/SearchSelectDropdown";
import { TTermAddForm } from "../../../../types/Term/TTermAddForm";

const TermForm = ({
  validateForm,
  loading,
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Function;
  validateForm: Function;
  loading?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { ADD_FORM, DROPDOWN_DATA } = useAppSelector((state) => state.Term);

  const handleModalClose = () => {
    let data: TTermAddForm = { ...ADD_FORM };
    data = {
      ...data,
      annualIncome: {
        value: "",
        warning: false,
      },
      pincode: {
        value: "",
        warning: false,
      },
      smoke: "N",
    };
    dispatch(TermSlice.actions.UPDATE_ADD_FORM_DATA({ ...data }));
    // dispatch(TermSlice.actions.BULK_UPDATE(TermSlice.getInitialState()));
    setOpen(false);
  };

  return (
    <Modal open={open}>
      <Box className="modalWrapper">
        <Box
          sx={{
            transform: "translate(0,0)",
            transition: "trapnsform 0.3s",
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box className="modalContent xsWidth">
            <Grid container spacing={2} alignContent="flex-start">
              <Grid size={12} textAlign={"right"} paddingBottom={0}>
                <Button
                  disableRipple
                  className="closePopup"
                  onClick={handleModalClose}
                />
              </Grid>
              <Grid size={12}>
                <h3 style={{ width: "100%" }}>Tell us about yourself</h3>
              </Grid>
              <Grid size={12}>
                <h5 style={{ marginBottom: "8px" }}>
                  Do you smoke or chew tobacco?
                </h5>
                <BorderRadioButton
                  attrName=""
                  onChange={(a: any, v: any) => {
                    dispatch(
                      TermSlice.actions.UPDATE_ADD_FORM_DATA({
                        ...ADD_FORM,
                        smoke: v,
                      })
                    );
                  }}
                  value={ADD_FORM.smoke}
                  count="2"
                  options={[
                    { value: "Y", label: "Yes" },
                    { value: "N", label: "No" },
                  ]}
                />
              </Grid>
              <Grid size={12}>
                <SearchSelectDropdown
                  title="What's your Annual Income?"
                  value={ADD_FORM?.annualIncome?.value}
                  attrName={"annualIncome"}
                  value_update={(a: any, v: any) => {
                    dispatch(
                      TermSlice.actions.UPDATE_ADD_FORM_DATA({
                        ...ADD_FORM,
                        annualIncome: { value: v, warning: false },
                      })
                    );
                  }}
                  options={DROPDOWN_DATA.ANNUAL_INCOME}
                  warn_status={ADD_FORM?.annualIncome?.warning}
                  error_message={"Select Annual Income"}
                />
              </Grid>
              <Grid size={12}>
                <TextInputField
                  title={"Pincode"}
                  placeholder="XXXXXX"
                  value={ADD_FORM.pincode.value}
                  attrName={""}
                  value_update={(a: any, v: any) => {
                    dispatch(
                      TermSlice.actions.UPDATE_ADD_FORM_DATA({
                        ...ADD_FORM,
                        pincode: {
                          value: v,
                          warning: !validatePincode(v),
                        },
                      })
                    );
                  }}
                  warn_status={ADD_FORM.pincode.warning}
                  max_length={6}
                  validation_type="NUMBER"
                  error_message={
                    isEmpty(ADD_FORM.pincode.value)
                      ? "Enter pincode"
                      : "Enter valid pincode"
                  }
                />
              </Grid>
            </Grid>
            <Box
              className="mt-6"
              sx={{
                maxWidth: "336px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <JDSButtons
                className="primaryBtns large"
                text="Confirm"
                variant="text"
                loader={loading}
                onClick={() => validateForm()}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default TermForm;
