import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import DownloadIcon from "@mui/icons-material/Download";
import { Box, Button, Link } from "@mui/material";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid2";
import { useEffect } from "react";
import JDSButtons from "../../../../../Component/InputFields/JDSButtons/JDSButtons";
import { EProductId } from "../../../../../Enum/ENonTermComapnyCode";
import "../../../../../SCSS/ModalPopup.scss";
import { useAppSelector } from "../../../../../Store/hooks";
import {
  calculateAge,
  formatToCurrency,
  isEmpty,
} from "../../../../../SupportingFiles/HelpingFunction";
import { COLORS } from "../../../../../SupportingFiles/colors";
import { TNonTermProduct } from "../../../../../types/NonTerm/TNonTermCardProduct";
import "./../../../Term/TermQuote/TermQuotePolicyDetail/TermQuotePolicyDetail.scss";

function NonTermQuotePolicyDetail({
  open,
  setOpen,
  data,
  BUY_ACTION,
  openEbi,
  setOpenEbi,
  POLICY_DETAILS,
}: {
  open: boolean;
  setOpen: Function;
  data: TNonTermProduct;
  BUY_ACTION: Function;
  openEbi: boolean;
  setOpenEbi: Function;
  POLICY_DETAILS: {
    exclusions: string[];
    unique_features: string[];
    tax_benefits: string[];
  };
}) {
  const { ADD_FORM, QUOTE_FIELDS_DATA } = useAppSelector(
    (state) => state.NonTerm
  );
  useEffect(() => {
    // Apply overflow hidden when the modal is open
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = ""; // Reset overflow when modal is closed
    }
  }, [open]);

  const openInNewTab = (url: any) => {
    window.open(`${url}`, "_blank");
  };

  const download = (value: string) => {
    window.open(value, "_blank");
  };

  return (
    <>
      {open ? (
        <Box
          sx={{
            position: "fixed",
            backgroundColor: COLORS.lightbg,
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            paddingTop: "96px ",
            overflowY: "auto",
            zIndex: "999",
          }}
        >
          <Box
            sx={{
              backgroundColor: COLORS.lightMariGold20,
              height: "72px",
              position: "fixed",
              top: "0px",
              width: "100%",
              zIndex: "99",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "0px 4px 12px #ddd",
            }}
          >
            <Grid
              container
              className="row"
              spacing={3}
              alignItems="center"
              width="100%"
            >
              <Grid size={5} display={"flex"} alignItems={"center"}>
                {/* <Link
                  style={{
                    display: "inline-block",
                    cursor: "pointer",
                    paddingRight: "4px",
                    color: COLORS.black,
                  }}
                  onClick={() => setOpen(false)}
                >
                  <ArrowBackIosNewRoundedIcon />
                </Link> */}
                <img
                  src="../images/jio_insurance_logo.svg"
                  alt="Jio Insurance Brokers"
                  height="16px"
                />
              </Grid>
              <Grid size={7} textAlign="right">
                {/* <Link
                  sx={{
                    color: "#2F2311",
                    fontWeight: "bold",
                    fontSize: "16px",
                    display: "inline-flex",
                    alignItems: "center",
                    gap: "8px",
                    cursor: "pointer",
                    textDecoration: "none",
                    padding: "8px 16px",
                  }}
                >
                  <img src="../images/share-icon.svg" height="24px" />
                  Share
                </Link> */}
              </Grid>
            </Grid>
          </Box>
          <Grid className="row" container spacing={3}>
            <Grid size={12}>
              <h3>Plan features</h3>
            </Grid>
            <Grid size="grow">
              <Box
                sx={{
                  backgroundColor: COLORS.lightMariGold20,
                  borderRadius: "24px 24px 0px 0px",
                  padding: "16px",
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "4px",
                }}
              >
                <Box display="flex" alignItems="center" gap="8px" width="100%">
                  <img
                    src={data?.quotationDetail?.companyDetails?.logo}
                    height="50px"
                  />
                  <Box>
                    <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                      {data?.quotationDetail?.companyDetails.short_desc}
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      {data?.quotationDetail?.productDetails.product_name}
                    </p>
                  </Box>
                </Box>
                <Box>
                  {/* <JDSButtons
                    className="secondaryBtn small"
                    text={
                      <>
                        <ShareIcon
                          style={{ fontSize: "16px", marginRight: "14px" }}
                        />
                        Share
                      </>
                    }
                    variant="text"
                  /> */}
                </Box>
              </Box>

              <Box className="featureTabs mb-6">
                <Box padding="24px 24px 8px 24px">
                  <h6 style={{ fontWeight: "900", marginBottom: "16px" }}>
                    Eligibility
                  </h6>
                  {/* <Box sx={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
                    {data?.quotationDetail?.specialFeatures?.premiumPayingTerm ? (
                      <Box
                        sx={{
                          border: "1px solid #E0E0E0",
                          borderRadius: "16px",
                          padding: "8px 12px",
                          textAlign: "center",
                          flex: 1,
                          minWidth: "140px",
                        }}
                      >
                        <img src="../images/tick-icon.svg" height="21px" />
                        <p style={{ fontWeight: "bold", marginTop: "12px" }}>
                          {data?.quotationDetail?.specialFeatures?.premiumPayingTerm}
                        </p>
                        <p style={{ color: COLORS.lightgrey }}>
                          <small>Premium Paying Term</small>
                        </p>
                      </Box>
                    ) : null}
                    {data?.quotationDetail?.premiumDetails.risk_cover_cal ? (
                      <Box
                        sx={{
                          border: "1px solid #E0E0E0",
                          borderRadius: "16px",
                          padding: "8px 12px",
                          textAlign: "center",
                          flex: 1,
                          minWidth: "140px",
                        }}
                      >
                        <img src="../images/tick-icon.svg" height="21px" />
                        <p style={{ fontWeight: "bold", marginTop: "12px" }}>
                          {formatToCurrency(
                            data?.quotationDetail?.premiumDetails.risk_cover_cal
                          )}
                        </p>
                        <p style={{ color: COLORS.lightgrey }}>
                          <small>Risk Cover</small>
                        </p>
                      </Box>
                    ) : null}
                    {data?.quotationDetail?.specialFeatures?.policyTerm ? (
                      <Box
                        sx={{
                          border: "1px solid #E0E0E0",
                          borderRadius: "16px",
                          padding: "8px 12px",
                          textAlign: "center",
                          flex: 1,
                          minWidth: "140px",
                        }}
                      >
                        <img src="../images/tick-icon.svg" height="21px" />
                        <p style={{ fontWeight: "bold", marginTop: "12px" }}>
                          {data?.quotationDetail?.specialFeatures?.policyTerm}
                        </p>
                        <p
                          style={{
                            color: COLORS.lightgrey,
                            lineHeight: "16px",
                          }}
                        >
                          <small>Policy Term</small>
                        </p>
                      </Box>
                    ) : null}
                    {data?.quotationDetail?.specialFeatures?.payFrequency ? (
                      <Box
                        sx={{
                          border: "1px solid #E0E0E0",
                          borderRadius: "16px",
                          padding: "8px 12px",
                          textAlign: "center",
                          flex: 1,
                          minWidth: "140px",
                        }}
                      >
                        <img src="../images/tick-icon.svg" height="21px" />
                        <p style={{ fontWeight: "bold", marginTop: "12px" }}>
                          {data?.quotationDetail?.specialFeatures?.payFrequency}
                        </p>
                        <p
                          style={{
                            color: COLORS.lightgrey,
                            lineHeight: "16px",
                          }}
                        >
                          <small>Premium Frequency</small>
                        </p>
                      </Box>
                    ) : null}

                    {data?.quotationDetail?.specialFeatures?.maximumMaturityAge ? (
                      <Box
                        sx={{
                          border: "1px solid #E0E0E0",
                          borderRadius: "16px",
                          padding: "8px 12px",
                          textAlign: "center",
                          flex: 1,
                          minWidth: "140px",
                        }}
                      >
                        <img src="../images/tick-icon.svg" height="21px" />
                        <p style={{ fontWeight: "bold", marginTop: "12px" }}>
                          {data?.quotationDetail?.specialFeatures?.maximumMaturityAge}
                        </p>
                        <p
                          style={{
                            color: COLORS.lightgrey,
                            lineHeight: "16px",
                          }}
                        >
                          <small>Max Maturity Age</small>
                        </p>
                      </Box>
                    ) : null}

                    {data?.quotationDetail?.specialFeatures?.risk_cover_cal ? (
                      <Box
                        sx={{
                          border: "1px solid #E0E0E0",
                          borderRadius: "16px",
                          padding: "8px 12px",
                          textAlign: "center",
                          flex: 1,
                          minWidth: "140px",
                        }}
                      >
                        <img src="../images/tick-icon.svg" height="21px" />
                        <p style={{ fontWeight: "bold", marginTop: "12px" }}>
                          1.5 Lac
                        </p>
                        <p
                          style={{
                            color: COLORS.lightgrey,
                            lineHeight: "16px",
                          }}
                        >
                          <small>Risk Cover</small>
                        </p>
                      </Box>
                    ) : null}
                  </Box> */}

                  <Box sx={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
                    <Box
                      sx={{
                        border: "1px solid #E0E0E0",
                        borderRadius: "16px",
                        padding: "8px 24px",
                        textAlign: "center",
                      }}
                    >
                      <img
                        alt=""
                        src="../images/success_icon.svg"
                        height="21px"
                      />
                      <p style={{ fontWeight: "bold", marginTop: "12px" }}>
                        {data?.quotationDetail?.companyDetails?.claim_ratio}%
                      </p>
                      <p
                        style={{ color: COLORS.lightgrey, lineHeight: "16px" }}
                      >
                        <small>
                          Claim Settlement <br />
                          Ratio
                        </small>
                      </p>
                    </Box>
                    <Box
                      sx={{
                        border: "1px solid #E0E0E0",
                        borderRadius: "16px",
                        padding: "8px 24px",
                        textAlign: "center",
                      }}
                    >
                      <img
                        alt=""
                        src="../images/success_icon.svg"
                        height="21px"
                      />
                      <p style={{ fontWeight: "bold", marginTop: "12px" }}>
                        {QUOTE_FIELDS_DATA.investmentMode === "12"
                          ? "Monthly"
                          : QUOTE_FIELDS_DATA.investmentMode === "4"
                          ? "Quarterly"
                          : QUOTE_FIELDS_DATA.investmentMode === "2"
                          ? "Half-yearly"
                          : QUOTE_FIELDS_DATA.investmentMode === "1"
                          ? "Yearly"
                          : QUOTE_FIELDS_DATA.investmentMode === "5"
                          ? "Single Pay"
                          : null}
                      </p>
                      <p
                        style={{ color: COLORS.lightgrey, lineHeight: "16px" }}
                      >
                        <small>
                          Premium <br />
                          Frequency
                        </small>
                      </p>
                    </Box>
                    <Box
                      sx={{
                        border: "1px solid #E0E0E0",
                        borderRadius: "16px",
                        padding: "8px 24px",
                        textAlign: "center",
                      }}
                    >
                      <img
                        alt=""
                        src="../images/success_icon.svg"
                        height="21px"
                      />
                      <p style={{ fontWeight: "bold", marginTop: "12px" }}>
                        {
                          data?.quotationDetail?.productDetails
                            ?.InvestmentFormValidations?.max_p_cess_age
                        }{" "}
                        Years
                      </p>
                      <p
                        style={{ color: COLORS.lightgrey, lineHeight: "16px" }}
                      >
                        <small>
                          Max. Maturity <br />
                          Age
                        </small>
                      </p>
                    </Box>
                  </Box>
                  <hr
                    style={{
                      borderColor: "#E0E0E0",
                      borderLeft: "none",
                      borderRight: "none",
                      borderTop: "none",
                      marginTop: "24px",
                      marginBottom: "24px",
                    }}
                  />
                  <h6 style={{ fontWeight: "900", marginBottom: "16px" }}>
                    Exclusions
                  </h6>
                  <ul>
                    {POLICY_DETAILS?.exclusions?.map(
                      (item: string, index: any) => (
                        <li
                          style={{
                            paddingBottom: "16px",
                          }}
                        >
                          <p
                            style={{
                              display: "flex",
                              gap: "8px",
                              color: COLORS.lightgrey,
                              fontSize: "14px",
                              lineHeight: "20px",
                            }}
                          >
                            <img
                              src="../images/success_icon.svg"
                              height="18px"
                              style={{ position: "relative", top: "2px" }}
                            />{" "}
                            {item}
                          </p>
                        </li>
                      )
                    )}
                  </ul>

                  <hr
                    style={{
                      borderColor: "#E0E0E0",
                      borderLeft: "none",
                      borderRight: "none",
                      borderTop: "none",
                      marginTop: "24px",
                      marginBottom: "24px",
                    }}
                  />
                  <h6 style={{ fontWeight: "900", marginBottom: "16px" }}>
                    Key Features
                  </h6>
                  <ul>
                    {POLICY_DETAILS?.unique_features?.map(
                      (item: string, index: any) => (
                        <li
                          style={{
                            paddingBottom: "16px",
                          }}
                        >
                          <p
                            style={{
                              display: "flex",
                              gap: "8px",
                              color: COLORS.lightgrey,
                              fontSize: "14px",
                              lineHeight: "20px",
                            }}
                          >
                            <img
                              src="../images/success_icon.svg"
                              height="18px"
                              style={{ position: "relative", top: "2px" }}
                            />{" "}
                            {item}
                          </p>
                        </li>
                      )
                    )}
                  </ul>
                  <hr
                    style={{
                      borderColor: "#E0E0E0",
                      borderLeft: "none",
                      borderRight: "none",
                      borderTop: "none",
                      marginTop: "8px",
                      marginBottom: "24px",
                    }}
                  />
                  <h6 style={{ fontWeight: "900", marginBottom: "16px" }}>
                    Tax Benefit
                  </h6>
                  <ul>
                    {POLICY_DETAILS.tax_benefits?.map(
                      (item: string, index: any) => (
                        <li
                          style={{
                            paddingBottom: "16px",
                          }}
                        >
                          <p
                            style={{
                              display: "flex",
                              gap: "8px",
                              color: COLORS.lightgrey,
                              fontSize: "14px",
                              lineHeight: "20px",
                            }}
                          >
                            <img
                              src="../images/success_icon.svg"
                              height="18px"
                              style={{ position: "relative", top: "2px" }}
                            />{" "}
                            {item}
                          </p>
                        </li>
                      )
                    )}
                  </ul>
                </Box>
              </Box>
            </Grid>
            <Grid size="grow" maxWidth="408px">
              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "24px",
                  padding: "16px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <h3>Summary</h3>
                <h6
                  style={{
                    color: COLORS.lightgrey,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  Policy Terms:{" "}
                  <span style={{ color: COLORS.black, fontWeight: "bold" }}>
                    {QUOTE_FIELDS_DATA.policyTerm} Years
                  </span>
                </h6>
                <h6
                  style={{
                    color: COLORS.lightgrey,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  Pay Upto:{" "}
                  <span style={{ color: COLORS.black, fontWeight: "bold" }}>
                    {QUOTE_FIELDS_DATA.payUpto} Years
                  </span>
                </h6>
                <h6
                  style={{
                    color: COLORS.lightgrey,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  Investment Amount:{" "}
                  <span style={{ color: COLORS.black, fontWeight: "bold" }}>
                    {formatToCurrency(ADD_FORM.investAmount.value)}
                  </span>
                </h6>
                <h6
                  style={{
                    color: COLORS.lightgrey,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  Maturity Amount:{" "}
                  <span style={{ color: COLORS.black, fontWeight: "bold" }}>
                    {formatToCurrency(
                      Number(
                        data?.quotationDetail?.premiumDetails?.maturity_benefit
                      )
                    )}
                  </span>
                </h6>
                <h6
                  style={{
                    color: COLORS.lightgrey,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  Risk Cover:{" "}
                  <span style={{ color: COLORS.black, fontWeight: "bold" }}>
                    {formatToCurrency(
                      Number(
                        data?.quotationDetail?.premiumDetails?.risk_cover_cal
                      )
                    )}
                  </span>
                </h6>
                <h6
                  style={{
                    color: COLORS.lightgrey,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  Member:
                  <span style={{ color: COLORS.black, fontWeight: "bold" }}>
                    {ADD_FORM.gender.value === "M" ? "Male" : "Female"},{" "}
                    {`${calculateAge(ADD_FORM.age.value)} Years`}
                  </span>
                </h6>
                <Box
                  sx={{
                    display: "flex",
                    gap: "16px",
                  }}
                >
                  <Box>
                    <JDSButtons
                      className="secondaryBtns large"
                      text="Back"
                      variant="text"
                      onClick={() => {
                        setOpen(false);
                      }}
                    />
                  </Box>
                  <Box width="100%">
                    <JDSButtons
                      className="primaryBtns large"
                      text="Next"
                      variant="text"
                      onClick={() => {
                        // if (
                        //   data?.quotationDetail?.productDetails?.id ===
                        //     EProductId.ICICI_Pru_Gold_Deferred_Income ||
                        //   data?.quotationDetail?.productDetails?.id ===
                        //     EProductId.ICICI_Pru_Gold_Immediate_Income_with_Booster ||
                        //   data?.quotationDetail?.productDetails?.id ===
                        //     EProductId.ICICI_Pru_Gift_Pro_Level_Income ||
                        //   data?.quotationDetail?.productDetails?.id ===
                        //     EProductId.ICICI_Pru_Gold_Immediate_Income ||
                        //   (data?.quotationDetail?.productDetails?.id ===
                        //     EProductId.ICICI_Pru_Gift_Pro_Increasing_Income &&
                        //     data?.quotationDetail?.premiumDetails?.EBI_PDF_url)
                        // ) {
                        //   setOpenEbi(true);
                        // } else {
                        BUY_ACTION(data?.quotationDetail);
                        // }
                      }}
                    />
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "24px",
                  padding: "16px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  marginTop: "32px",
                }}
              >
                <h6 style={{ fontWeight: "bold" }}>Plan Brochures/Document</h6>
                <Box>
                  <Link
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: COLORS.darkgrey,
                      justifyContent: "space-between",
                      borderBottom: "1px solid #E0E0E0",
                      paddingBottom: "12px",
                      marginBottom: "12px",
                    }}
                    onClick={() =>
                      openInNewTab(
                        data?.quotationDetail?.productDetails?.brochure
                      )
                    }
                  >
                    Brochure{" "}
                    <img src="../images/download-icon.svg" width="24px" />
                  </Link>
                  {isEmpty(
                    data?.quotationDetail?.premiumDetails?.EBI_PDF_url
                  ) ? null : (
                    <Link
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: COLORS.darkgrey,
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        paddingBottom: "12px",
                        marginBottom: "12px",
                      }}
                      onClick={() => {
                        download(
                          `${data?.quotationDetail?.premiumDetails?.EBI_PDF_url}`
                        );
                      }}
                    >
                      Benefit Illustration{" "}
                      <img src="../images/download-icon.svg" width="24px" />
                    </Link>
                  )}
                  <Modal open={openEbi} onClose={() => setOpenEbi(false)}>
                    <Box className="modalWrapper">
                      <Box
                        sx={{
                          transform: "translate(0,0)",
                          transition: "trapnsform 0.3s",
                          minHeight: "100vh",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box className="modalContent ">
                          <Grid
                            className="topbar"
                            container
                            alignItems="center"
                          >
                            <Grid size={12} textAlign={"right"}>
                              <Button
                                disableRipple
                                className="closePopup"
                                onClick={() => {
                                  setOpenEbi(false);
                                }}
                              />
                            </Grid>
                          </Grid>

                          <Grid container spacing={2} alignContent="flex-start">
                            <Grid size={12} textAlign={"center"}>
                              <iframe
                                src={`https://docs.google.com/viewer?url=${encodeURIComponent(
                                  `${data.quotationDetail?.premiumDetails.EBI_PDF_url}`
                                )}&embedded=true`}
                                style={{
                                  minWidth: "500px",
                                  width: "100%",
                                  height: "700px",
                                }}
                                title="PDF Viewer"
                              ></iframe>
                            </Grid>
                            <Grid
                              size={12}
                              textAlign={"center"}
                              marginTop={"12px"}
                              display={"flex"}
                              gap={"16px"}
                              justifyContent={"center"}
                            >
                              <JDSButtons
                                className="primaryBtn "
                                text="Continue"
                                variant="text"
                                onClick={() =>
                                  BUY_ACTION(data?.quotationDetail)
                                }
                                fullWidth={false}
                              />
                              <JDSButtons
                                className="primaryBtn "
                                text={
                                  <>
                                    <DownloadIcon />
                                  </>
                                }
                                variant="text"
                                onClick={() => {
                                  download(
                                    `${data?.quotationDetail?.premiumDetails?.EBI_PDF_url}`
                                  );
                                }}
                                fullWidth={false}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </Box>
                  </Modal>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : null}
    </>
  );
}

export default NonTermQuotePolicyDetail;
