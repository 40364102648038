// CustomCheckbox
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import React from "react";
import { COLORS } from "../../../SupportingFiles/colors";

const CustomCheckbox = ({
  label,
  defaultChecked = false,
  value,
  value_update,
  attrName,
  disabled,
  selectedValue,
  variant = "default",
}: {
  label: React.ReactNode;
  defaultChecked?: boolean;
  value?: boolean | string;
  attrName?: any;
  value_update?: Function;
  disabled?: boolean;
  selectedValue?: string;
  variant?: "default" | "large";
}) => {
  const checkedLabelColor = "#E39B2B";
  const uncheckedLabelColor = "#595959";
  return (
    <FormControlLabel
      name={attrName}
      sx={{
        // alignItems: "flex-start",
        width: "auto",
        marginLeft: "0px",
        marginRight: "0px",
        ".MuiCheckbox-root": {
          padding: "5px 8px 7px 0px",
        },
        ".MuiTypography-root": {
          // color: value ? checkedLabelColor : uncheckedLabelColor,
          color: COLORS.lightgrey,
          lineHeight: variant === "default" ? "20px" : "24px",
          fontSize: variant === "default" ? "14px" : "16px",
          fontFamily: "JioType",
        },
        ".MuiButtonBase-root": {
          position: "relative",
          ".MuiSvgIcon-root": {
            display: "none",
          },
          "&:before": {
            content: '""',
            border: "1px solid",
            borderColor: COLORS.lightgrey,
            height: variant === "default" ? "14px" : "24px",
            width: variant === "default" ? "14px" : "24px",
            borderRadius: "4px",
          },
          "&.Mui-checked": {
            "&:before": {
              backgroundColor: COLORS.primary,
              borderColor: COLORS.primary,
            },
            "&:after": {
              position: "absolute",
              top: variant === "default" ? "9px" : "12px",
              left: variant === "default" ? "4px" : "6px",
              content: '""',
              borderBottom: "1px solid",
              borderLeft: "1px solid",
              borderTop: "none",
              borderRight: "none",
              borderColor: COLORS.warmgold,
              transform: "rotate(-45deg)",
              height: variant === "default" ? "4px" : "6px",
              width: variant === "default" ? "7px" : "12px",
            },
          },
        },
      }}
      control={
        <Checkbox
          id={`${attrName}`}
          disabled={disabled}
          defaultChecked={defaultChecked}
          checked={Boolean(value)}
          onChange={(e) => {
            value_update && value_update(attrName, e.target.checked);
          }}
        />
      }
      label={label}
    />
  );
};

export default CustomCheckbox;
