import { Box, Checkbox, FormControlLabel } from "@mui/material";
import Grid from "@mui/material/Grid2";
import MNonTermFooter from "../../../../Component/Amaze/NonTerm/MNonTermFooter/MNonTermFooter";
import { useAppSelector } from "../../../../Store/hooks";
import { isEmpty } from "../../../../SupportingFiles/HelpingFunction";
import { TNonTermProposerData } from "../../../../types/TNonTermSlice";
import { ECompanyCode } from "../../../../Services/Enum/EHome";
import {
  EVDateInput,
  EVSelectDropdown,
  EVTextField,
} from "@evervent_pvt_ltd/ui-kit";
const MNonTermProposal = ({
  proposerFields,
  fieldsUpdateState,
  validateForm,
}: {
  proposerFields: TNonTermProposerData;
  fieldsUpdateState: Function;
  validateForm: Function;
  loader: boolean;
}) => {
  const { DROPDOWN_DATA, ADD_FORM, QUOTE_LOADER, SELECTED_QUOTE_DATA } =
    useAppSelector((state) => state.NonTerm);

  return (
    <Box>
      <h5 className="sectionTitle">Proposer Details</h5>
      <Grid container spacing={2}>
        <Grid size={12}>
          <h6>Prposer / Insured Details</h6>
        </Grid>
        <Grid size={12}>
          <EVTextField
            id="fullName"
            title={"Full Name"}
            validation_type="NAME"
            value={proposerFields.full_name.value}
            attrName={["full_name"]}
            value_update={fieldsUpdateState}
            warn_status={proposerFields.full_name.warning}
            error_message={
              isEmpty(proposerFields.full_name.value)
                ? "Enter Full Name"
                : "Enter Valid Full Name"
            }
          />
        </Grid>
        <Grid size={12}>
          <EVDateInput
            id="dob"
            title={"DOB"}
            value={ADD_FORM.age.value}
            attrName={["dob"]}
            disabled={true}
            value_update={fieldsUpdateState}
            error_message="Enter DOB"
            warn_status={ADD_FORM.age.warning}
          />
        </Grid>
        <Grid size={12}>
          <EVSelectDropdown
            id="gender"
            title="Gender"
            value={proposerFields.gender.value}
            attrName={["gender"]}
            value_update={fieldsUpdateState}
            data={[
              { value: "M", label: "Male" },
              { value: "F", label: "Female" },
              { value: "O", label: "Other" },
            ]}
            warn_status={ADD_FORM.gender.warning}
            disabled={true}
            error_message="Select Gender"
          />
        </Grid>
        <Grid size={12}>
          <EVTextField
            disabled
            id="mobile"
            title={"Mobile"}
            value={ADD_FORM.mobile.value}
            attrName={["mobile"]}
            value_update={fieldsUpdateState}
            warn_status={ADD_FORM.mobile.warning}
            max_length={10}
            validation_type="NUMBER"
            error_message={!ADD_FORM.mobile.value ? "Enter mobile number" : ""}
          />
        </Grid>
        <Grid size={12}>
          <EVTextField
            id="email"
            title={"Email"}
            value={proposerFields.email.value}
            attrName={["email"]}
            value_update={fieldsUpdateState}
            warn_status={proposerFields.email.warning}
            error_message={
              isEmpty(proposerFields.email.value)
                ? "Enter Email"
                : "Enter Valid Email"
            }
          />
        </Grid>
        {SELECTED_QUOTE_DATA?.companyDetails?.company_code ===
          ECompanyCode.TATA_NT && (
          <Grid size={12}>
            <EVTextField
              id="pan"
              title={"Pan"}
              value={proposerFields.pan_no.value}
              attrName={["pan_no"]}
              value_update={fieldsUpdateState}
              warn_status={proposerFields.pan_no.warning}
              error_message={
                isEmpty(proposerFields.pan_no.value)
                  ? "Enter PAN"
                  : "Enter Valid Pan"
              }
              max_length={10}
              validation_type="ALPHANUMERIC"
            />
          </Grid>
        )}
        <Grid size={12}>
          <EVTextField
            id="pincode"
            title={"Pincode"}
            value={ADD_FORM.pincode.value}
            attrName={["pincode"]}
            value_update={fieldsUpdateState}
            warn_status={ADD_FORM.pincode.warning}
            validation_type="NUMBER"
            max_length={12}
            disabled={true}
            error_message={!ADD_FORM.pincode.warning ? "Enter Pincode" : ""}
          />
        </Grid>
        {SELECTED_QUOTE_DATA?.companyDetails?.company_code !==
          ECompanyCode.TATA_NT && (
          <Grid size={12}>
            <EVTextField
              id="emergencynumber"
              title={"Emergency Phone"}
              value={proposerFields.emergency_number.value}
              attrName={["emergency_number"]}
              value_update={fieldsUpdateState}
              warn_status={proposerFields.emergency_number.warning}
              max_length={10}
              validation_type="NUMBER"
              error_message={
                isEmpty(proposerFields.emergency_number.value)
                  ? "Enter Emergency Phone Number "
                  : "Enter Valid Emergency Phone Number"
              }
            />
          </Grid>
        )}
        <Grid size={12}>
          <EVSelectDropdown
            id="occupation"
            title="Occupation"
            value={proposerFields.occupation.value}
            attrName={["occupation"]}
            value_update={fieldsUpdateState}
            data={DROPDOWN_DATA.OCCUPATION_DATA}
            warn_status={proposerFields.occupation.warning}
            error_message="Select Proposer Occupation"
          />
        </Grid>
        <Grid size={12}>
          <EVSelectDropdown
            id="qualification"
            title="Qualification"
            value={proposerFields?.qualification?.value}
            attrName={["qualification"]}
            value_update={fieldsUpdateState}
            data={DROPDOWN_DATA.QUALIFICATION_DATA}
            warn_status={proposerFields.qualification.warning}
            error_message="Select Proposer Occupation"
          />
        </Grid>
        {SELECTED_QUOTE_DATA?.companyDetails?.company_code ===
          ECompanyCode.MAX && (
          <>
            <Grid size={12}>
              <FormControlLabel
                className="proposalCheckBoxWrapper"
                control={
                  <Checkbox
                    value={proposerFields.PCB}
                    defaultChecked={proposerFields.PCB}
                    onClick={() =>
                      fieldsUpdateState(["PCB"], !proposerFields.PCB)
                    }
                  />
                }
                label="Opt for PCB?"
              />
            </Grid>
            <Grid size={12}>
              <FormControlLabel
                className="proposalCheckBoxWrapper"
                control={
                  <Checkbox
                    value={proposerFields.payer_selected}
                    defaultChecked={proposerFields.payer_selected}
                    onClick={() =>
                      fieldsUpdateState(
                        ["payer_selected"],
                        !proposerFields.payer_selected
                      )
                    }
                  />
                }
                label="Are you paying for someone else?"
              />
            </Grid>
          </>
        )}
        {proposerFields.payer_selected ? (
          <Grid size={4}>
            <EVSelectDropdown
              id="relationship"
              title="Relationship"
              value={proposerFields?.payer_relationship?.value}
              attrName={["payer_relationship"]}
              value_update={fieldsUpdateState}
              data={[
                { label: "01", value: "Spouse" },
                { label: "02", value: "Parents" },
              ]}
              warn_status={proposerFields?.payer_relationship?.warning}
              error_message="Select Relationship"
            />
          </Grid>
        ) : null}
      </Grid>
      <MNonTermFooter loader={QUOTE_LOADER} forward={validateForm} />
    </Box>
  );
};

export default MNonTermProposal;
